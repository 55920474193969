import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';

import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class BlogService extends BaseHttpService {
    getDetail(subdomain: string, blogId: number): Observable<any> {
        return this.get(
            `${environment.apiMallUrl}${ENDPOINTS.BLOG.DETAIL}`
                .replace('{subdomain}', subdomain)
                .replace('{blog_id}', blogId + '')
        );
    }

    getPaginatedListing(subdomain: string, criteria: any): Observable<any> {
        let params = new HttpParams();
        if (criteria) {
            params = params.append('page', criteria.page);
            params = params.append('page_entries', criteria.pageEntries);
        }

        const url =
            environment.apiMallUrl +
            ENDPOINTS.BLOG.PAGINATED_LISTING.replace('{subdomain}', subdomain) +
            '?' +
            params.toString();

        return this.get(url);
    }
}
