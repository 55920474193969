import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ProductSearchResultAction from './product-search-result.actions';
import { ProductSearchService } from 'src/app/services/product-search.service';
import { IPaginationResponse } from 'src/app/models/response/pagination-response';
import { ProductSearchResult } from 'src/app/models/product-search-result.model';

@Injectable()
export class ProductSearchResultEffects {
    constructor(private actions$: Actions, private productSearchService: ProductSearchService) {}

    loadDomesticTourProducts = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductSearchResultAction.loadProductSearchResults),
                switchMap((action) => {
                    return this.productSearchService
                        .getProductListAsPagination(action.subDomain, action.displayExpiredProducts, action.query)
                        .pipe(
                            map((resp: any) => {
                                if (resp.status === 'success') {
                                    const paginationResponse: IPaginationResponse<ProductSearchResult[]> = {
                                        page: resp.data.page,
                                        pageEntries: resp.data.page_entries,
                                        total: resp.data.total,
                                        result: (resp.data.result as any[]).map((productData) =>
                                            new ProductSearchResult().deserialize(productData)
                                        ),
                                    };
                                    return ProductSearchResultAction.loadProductSearchResultsSuccess({
                                        productSearchResults: paginationResponse,
                                    });
                                } else if (resp.status === 'error') {
                                    return ProductSearchResultAction.loadProductSearchResultsFailure({
                                        error: resp.error,
                                    });
                                }
                            }),
                            catchError((error) =>
                                of(ProductSearchResultAction.loadProductSearchResultsFailure({ error }))
                            )
                        );
                })
            )
    );
}
