export class CampaignOrderResponse {
    id: number;
    orderTempKey: string;
    orderKey: string;

    voucherId: number;
    isFreeVoucher: boolean;

    isSuccess: boolean;

    public deserialize(data: any): this | null {
        if (!data) {
            return null;
        }

        this.id = +data.id;
        this.orderTempKey = data.order_temp_key;

        this.orderKey = data.order_key;

        if (data.voucher) {
            this.voucherId = data.voucher.id;
            this.isFreeVoucher = data.voucher.is_free;
        }

        return this;
    }
}
