import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { vouchersPromotionURL } from './helpers/routing.helper';

// Pages
import { ErrorComponent } from './modules/public/error/error.component';

const routes: Routes = [
    { path: '404', component: ErrorComponent },
    {
        // Routing /[Campaign-SubCategory]/
        matcher: vouchersPromotionURL,
        loadChildren: () => import('./modules/products/products.module').then((m) => m.ProductsModule),
    },
    {
        path: 'order',
        loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
    },
    {
        path: '',
        loadChildren: () => import('./modules/public/public.module').then((m) => m.PublicModule),
    },
    { path: '**', redirectTo: '404' }, // Wildcard route for a 404 page
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            relativeLinkResolution: 'legacy',
            enableTracing: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
