import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'paymentMethod' })
export class PaymentMethodPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'card':
                return 'บัตรเครดิต/บัตรเดบิต';
            case 'promptpay':
                return 'พร้อมเพย์';
            case 'free':
                return '-';
            default:
                return value;
        }
    }
}
