import { ProvinceService } from '../../services/province.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of, Observable, pipe } from 'rxjs';

import * as ProvinceAction from './province.actions';
import { IProvince, IProvinceRegions, IProvinceRegion } from 'src/app/models/province.model';
import { IProvinceRecommend } from 'src/app/models/province-recommend.model';
import { RegionService } from 'src/app/services/region.service';

@Injectable()
export class ProvinceEffects {
    constructor(
        private actions$: Actions,
        private provinceService: ProvinceService,
        private regionService: RegionService,
        private router: Router
    ) {}

    loadActiveProvinces = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProvinceAction.loadActiveProvinces),
                concatMap((action) => {
                    return this.provinceService.getAllActive(action.subDomain).pipe(
                        map((response) => {
                            if (response.status !== 'success') {
                                return ProvinceAction.loadActiveProvincesFailure(null);
                            }
                            const data = response.data;
                            const provinceRegions: IProvinceRegions = {
                                regions: data.regions.map((region) => {
                                    const r: IProvinceRegion = {
                                        id: region.id,
                                        name: region.name,
                                        provinces: region.provinces.map((province) => {
                                            const p: IProvince = {
                                                id: province.id,
                                                nameEn: province.name_en,
                                                nameTh: province.name_th,
                                                slug: province.slug,
                                            };
                                            return p;
                                        }),
                                    };
                                    return r;
                                }),
                            };

                            return ProvinceAction.loadActiveProvincesSuccess({ data: provinceRegions });
                        }),
                        catchError((error) => {
                            return of(ProvinceAction.loadActiveProvincesFailure(error));
                        })
                    );
                })
            )
    );

    loadProvincesRecommend = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProvinceAction.loadProvincesRecommend),
                switchMap((action) => {
                    return this.regionService.getProvincesRecommend(action.subDomain).pipe(
                        map((resp) => {
                            const provincesRecommend: IProvinceRecommend[] = resp.data.map((data) => {
                                const provinceReccomend: IProvinceRecommend = {
                                    provinceId: data.province.id,
                                    provinceName: data.province.name_th,
                                    imageUrl: data.image_url,
                                    ordinal: data.ordinal,
                                    slug: data.province.slug,
                                };

                                return provinceReccomend;
                            });

                            return ProvinceAction.loadProvincesRecommendSuccess({ provincesRecommend });
                        }),
                        catchError((error) => of(ProvinceAction.loadProvincesRecommendFailure(error)))
                    );
                })
            )
    );

    loadAllProvince = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProvinceAction.loadAllProvince),
                switchMap((action) => {
                    return this.provinceService.getAll().pipe(
                        map((resp) => {
                            const allProvince: IProvince[] = resp.data.map((data) => {
                                return {
                                    id: data.id,
                                    nameEn: data.name_en,
                                    nameTh: data.name_th,
                                    slug: data.slug,
                                };
                            });

                            return ProvinceAction.loadAllProvinceSuccess({ provinces: allProvince });
                        }),
                        catchError((error) => of(ProvinceAction.loadAllProvinceFailure(error)))
                    );
                })
            )
    );
}
