export interface IProductBox {
    id: number;
    productPoolId: number;
    name: string;
    hilightDescription: string;
    bannerUrl: string;
    bannerThumbnailUrl: string;
    travelPeriodStartAtFirst: Date;
    travelPeriodStartAtLast: Date;
    price: number;
    ordinal: number;
    ownerChannel: string;
    categorySubProductId: number;

    isRecommended: boolean;
}

export class ProductBox implements IProductBox {
    public id: number;
    public productPoolId: number;
    public name: string;
    public hilightDescription: string;
    public bannerUrl: string;
    public bannerThumbnailUrl: string;
    public travelPeriodStartAtFirst: Date;
    public travelPeriodStartAtLast: Date;
    public price: number;
    public ordinal: number;
    public ownerChannel: string;
    public categorySubProductId: number;

    public isRecommended: boolean;

    public deserialize(data: any): this {
        this.id = data.id;
        this.productPoolId = data.product_pool_id;
        this.name = data.name;
        this.bannerUrl = data.banner_url;
        this.bannerThumbnailUrl = data.banner_thumbnail_url;
        this.hilightDescription = data.hilight_description;
        this.ordinal = data.id;
        this.price = data.price;
        this.travelPeriodStartAtFirst = data.travel_period_start_at_first;
        this.travelPeriodStartAtLast = data.travel_period_start_at_last;
        this.ownerChannel = data.owner_channel;
        this.categorySubProductId = data.category_sub_products_id;

        this.isRecommended = data.is_recommended;
        return this;
    }
}
