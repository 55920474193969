import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './banner.reducer';
import { BannerEffects } from './banner.effects';

export const FEATURE_NAME = 'banner';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([BannerEffects])],
    providers: [BannerEffects],
})
export class BannerStoreModule {}
