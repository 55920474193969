import { UrlSegment, UrlMatchResult } from '@angular/router';
import { VOUCHER_MENUS } from '../constants/vouchers-menu.constant';

/**
 *
 * @param UrlSegment
 * @returns UrlMatchResult | null
 * Find url sengment [0] to match VOUCHER_MENU_LINK
 */
export function vouchersPromotionURL(url: UrlSegment[]): UrlMatchResult | null {
    if (url.length === 0) {
        return null;
    }

    if (url.length > 0 && VOUCHER_MENUS.map((menu) => menu.slug).includes(url[0].path)) {
        return {
            consumed: [new UrlSegment(url[0].path, url[0].parameters)],
        };
    }

    return null;
}

/**
 *
 * @param UrlSegment
 * @returns UrlMatchResult | null
 * Find url sengment [0] to match 'europe-tour/:keyword-tour'
 */
export function searchResultEuropeURL(url: UrlSegment[]): UrlMatchResult | null {
    const regex = /([0-9.\-A-Za-z]+)-tour/g;
    if (url.length === 2 && url[0].path === 'europe-tour' && url[1].path.match(regex)) {
        return { consumed: url };
    }

    return null;
}

/**
 *
 * @param UrlSegment
 * @returns UrlMatchResult | null
 * Find url sengment [0] to match 'thailand-tour/:keyword-tour'
 */
export function searchResultThailandURL(url: UrlSegment[]): UrlMatchResult | null {
    const regexDigit = /\d/g;
    if (url.length === 2 && url[0].path === 'thailand-tour' && url[1].path.match(regexDigit)) {
        return null;
    }

    const regex = /(^[0-9.\-A-Za-z]+)-tour/g;
    if (url.length === 2 && url[0].path === 'thailand-tour' && url[1].path.match(regex)) {
        return { consumed: url };
    }

    return null;
}
