import { Component, Input, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';

import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import { ICountry } from 'src/app/models/country.model';
import { IProvince } from 'src/app/models/province.model';

import { Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { selectAllCountry } from 'src/app/stores/country-store/country.selectors';
import { selectAllProvince } from 'src/app/stores/province-store/province.selectors';
import { ICustomContent } from 'src/app/models/custom-content.model';
import { selectAgencyInfo } from 'src/app/stores/agency-store/agency.selectors';
import { VOUCHER_MENUS } from 'src/app/constants/vouchers-menu.constant';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();

    @Input() customBreadcrumbs: IBreadcrumb[] = null;
    @Input() presetLastBreadcrumbText: string;

    public breadcrumbs: IBreadcrumb[];
    private countries: ICountry[];
    private provinces: IProvince[];
    private customContents: ICustomContent[];

    constructor(private store: Store<State>, private activatedRoute: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.activatedRoute.params.subscribe(() => {
            this.init();
        });
    }

    ngOnChanges() {
        this.init();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    init(): void {
        if (this.customBreadcrumbs) {
            this.breadcrumbs = this.customBreadcrumbs;
        } else {
            this.breadcrumbs = [];
            combineLatest([
                this.store.select(selectAllProvince),
                this.store.select(selectAllCountry),
                this.store.select(selectAgencyInfo),
            ]).subscribe(([provinces, countries, agencyInfo]) => {
                if (provinces.length > 0 && countries.length > 0) {
                    this.countries = countries;
                    this.provinces = provinces;
                    this.customContents = agencyInfo.customContents;

                    this.loadUrlSegments();
                }
            });
        }
    }

    private loadUrlSegments(): void {
        const url = [...this.activatedRoute.snapshot.parent.url, ...this.activatedRoute.snapshot.url];
        let trackLink = '';
        for (let i = 0; i < url.length; i++) {
            trackLink = trackLink + '/' + url[i].path;
            this.breadcrumbs.push({
                text: this.convertUrlToBreadcrumbWord(url[i].path),
                link: trackLink,
                active: i === url.length - 1 ? false : true,
            });
        }
        if (this.presetLastBreadcrumbText) {
            this.breadcrumbs[this.breadcrumbs.length - 1].text = this.presetLastBreadcrumbText;
        }
    }

    private convertUrlToBreadcrumbWord(urlPath: string): string {
        const urlIsCustomContentName = !!this.customContents.find(
            (customContent) => customContent.menuNameTh === urlPath
        );
        if (urlIsCustomContentName) {
            return urlPath;
        }

        for (const voucherMenu of VOUCHER_MENUS) {
            if (urlPath === voucherMenu.slug) {
                return voucherMenu.text;
            }
        }

        const lastIndexOf = urlPath.lastIndexOf('-');
        const text = [urlPath.substring(0, lastIndexOf), urlPath.substring(lastIndexOf + 1, urlPath.length)];

        if (!text) {
            return '';
        }

        if (text[0] === 'outbound') {
            return 'ทัวร์ต่างประเทศ';
        } else if (text[1] === 'package') {
            return 'แพ็กเกจทัวร์';
        } else if (text[0] === 'about' && text[1] === 'us') {
            return 'เกี่ยวกับเรา';
        } else if (text[1] === 'gallery') {
            return 'ผลงานที่ผ่านมา';
        } else if (text[1] === 'blog') {
            return 'บทความ';
        }
        return this.convertParametersToText(text, urlPath);
    }

    private convertParametersToText(text: string[], urlPath: string) {
        let result = '';
        const urlSegments = [...this.activatedRoute.snapshot.parent.url, ...this.activatedRoute.snapshot.url];
        const detailPaths: string[] = ['gallery', 'blog'];

        // Is a detail path
        if (detailPaths.includes(urlSegments[0].path) && urlSegments.length === 2) {
            result = urlPath.split('-').slice(1).join('-');
            result = result.replace(/-/g, ' ');
        }

        let positionFound;
        positionFound = this.provinces.map((province) => province.slug).indexOf(text[0]);
        if (positionFound >= 0) {
            result = 'ทัวร์' + this.provinces[positionFound].nameTh;
        }

        positionFound = this.countries.map((country) => country.slug).indexOf(text[0]);
        if (positionFound >= 0) {
            result = 'ทัวร์' + this.countries[positionFound].nameTh;
        }

        return result;
    }
}
