export class DateHelper {
    public static getYMDString(date: Date): string {
        if (date) {
            const dateWithCorrectTimeZone = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
            return dateWithCorrectTimeZone.toISOString().substring(0, 10);
        }
        return null;
    }

    public static getCurrentDateAtMidnight(useThaiTimeZone: boolean = true): Date {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        if (useThaiTimeZone) {
            date.setHours(7);
        }
        return date;
    }

    /**
     * - return null if dateString === null
     * - this is a way around the fact that new Date(null) gives 1970-01-01T00:00:00.000Z
     */
    public static convertStringToDate(dateString: string): Date {
        if (!dateString) {
            return null;
        }

        const date = dateString.split(' ');
        if (date.length === 1) {
            return new Date(dateString);
        }

        if (date.length === 2) {
            return new Date(date[0] + 'T' + date[1]);
        }

        return null;
    }
}
