import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { State } from 'src/app/stores/reducers';
import { selectThemeConfig } from 'src/app/stores/template-store/template.selectors';

@Component({
    selector: 'app-gift-a-wow',
    templateUrl: './gift-a-wow.component.html',
})
export class GiftAWowComponent implements OnInit, OnDestroy {
    themeLoaded = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectThemeConfig),
                filter((ret) => ret != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.themeLoaded = true;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
