import { Directive, ElementRef, Input } from '@angular/core';
import { fromEvent, race } from 'rxjs';
import { first, tap, map } from 'rxjs/operators';

@Directive({
    selector: '[appImageLoader]',
})
export class ImageLoaderDirective {
    private defaultImgUrl: string = '';

    private get imageElement(): HTMLImageElement {
        return this.el.nativeElement;
    }

    @Input()
    set defaultSrc(url: string) {
        this.defaultImgUrl = url;
    }

    constructor(private el: ElementRef<HTMLImageElement>) {
        const load$ = fromEvent(this.imageElement, 'load').pipe(map(() => 'success'));

        const error$ = fromEvent(this.imageElement, 'error').pipe(map(() => 'error'));

        race(load$, error$)
            .pipe(
                first(),
                tap((status: string) => {
                    if (status === 'success') return;
                    this.imageElement.src = this.defaultImgUrl;
                })
            )
            .subscribe();
    }
}
