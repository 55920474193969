import { Action, createReducer, on } from '@ngrx/store';
import * as MonthActions from './month.actions';

import { initialState } from '../month-store/month.state';
import { MonthState } from './month.state';

export const monthReducer = createReducer(
    initialState,

    on(MonthActions.loadActiveMonthRange, (state) => state),
    on(MonthActions.loadActiveMonthRangeSuccess, (state, action) => ({
        ...state,
        activeMonthRange: action.activeMonthRange,
    })),
    on(MonthActions.loadActiveMonthRangeFailure, (state, action) => ({
        ...state,
        error: action.error,
    }))
);

export function reducer(state: MonthState | undefined, action: Action) {
    return monthReducer(state, action);
}
