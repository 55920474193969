import { Component, OnInit, Inject, PLATFORM_ID, Injector } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// Components
import { ErrorComponent } from './modules/public/error/error.component';

// Stores
import { Store } from '@ngrx/store';
import { State } from './stores/reducers';
import { selectAllProvince } from './stores/province-store/province.selectors';
import { selectAllCountry } from 'src/app/stores/country-store/country.selectors';
import { setAgencyInfo, setSeoConfig } from './stores/agency-store/agency.actions';
import { setTemplateConfig } from './stores/template-store/template.actions';
import { loadAllCountry } from './stores/country-store/country.actions';
import { loadAllProvince } from './stores/province-store/province.actions';

// Services
import { AgencyService } from './services/agency.service';
import { MetadataService } from './services/metadata.service';
import { TemplateService } from './services/template.service';

// Helpers
import { MetaTagsHelper } from './helpers/metatags.helper';
import { UrlHelper } from './helpers/url.helper';

// Interfaces and Models
import { IDomainFilters } from './models/interfaces/domain-filters.interface';
import { AgencyInfo } from './models/agency.model';
import { SeoConfigs } from './models/seo-configs.model';
import { SeoGoogleAnalytics } from './models/seo-google-analytics.model';
import { TemplateConfig } from './models/template.model';
import { isPlatformServer } from '@angular/common';

declare let $: any;
declare let gtag: Function;
declare global {
    interface Window {
        dataLayer: any[];
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    error: ErrorComponent;
    isAgencyLoadedFailure: boolean = false;

    private seoConfig: SeoConfigs = null;
    private gtagScriptLoaded: boolean = false;
    private isServer = false;

    constructor(
        // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) protected platformId: Object,

        private store: Store<State>,
        private injector: Injector,
        private router: Router,
        private agencyService: AgencyService,
        private templateService: TemplateService,
        private metadataService: MetadataService
    ) {
        this.isServer = isPlatformServer(this.platformId);
    }

    ngOnInit(): void {
        if (!this.isServer) {
            this.render();
            this.subscribeToRouteChange();

            // // Load All Country & Province (Use in breadcrumb service)
            this.store.dispatch(loadAllCountry());
            this.store.dispatch(loadAllProvince());
        } else {
            this.serverSideRender();
        }
    }

    private serverSideRender(): void {
        // SSR
        const domainFilters: IDomainFilters = this.getDomainFilters();
        this.agencyService.getSeoConfigs(domainFilters, true).subscribe((config: SeoConfigs | null) => {
            if (!config) {
                return;
            }
            // Set property for use in function this.setMetaTags()
            this.seoConfig = config;
            this.getPageType();
        });
    }

    /**
     * Request SEO Config, Template Config and Agency Config from API and keep it on Store.
     */
    private render(): void {
        const domainFilters: IDomainFilters = this.getDomainFilters();

        // Get SEO
        this.agencyService.getSeoConfigs(domainFilters, true).subscribe((config: SeoConfigs | null) => {
            if (!config) {
                return;
            }

            this.setStoreSeoConfig(config);
            this.initGoogleAnalytics(config.googleAnalytics);

            // Set property for use in function this.setMetaTags()
            this.seoConfig = config;
        });
        // Get Agency Info
        this.agencyService.getInfo(domainFilters, true).subscribe((agencyInfo: AgencyInfo | null) => {
            if (!agencyInfo) {
                return;
            }

            this.setStoreAgencyInfo(agencyInfo);
            this.injectGoogleTagManager(agencyInfo.googleTagManagerId);
            this.initLuckyOrange(agencyInfo.domain);

            // Get Template Config
            this.templateService.getTemplateConfig(agencyInfo.subdomain, true).subscribe((config: TemplateConfig) => {
                this.setStoreTemplateConfig(config);
            });
        });
    }

    private subscribeToRouteChange(): void {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.getPageType();
            }
        });
    }

    private getPageType(): void {
        if (
            !this.router.url.endsWith('-tour') ||
            this.router.url === '/outbound-tour' ||
            this.router.url === '/thailand-tour'
        ) {
            this.setMetaTags(this.router.url);
        } else {
            const urlSegments = this.router.url.substring(1).split('-tour');
            let positionFound: number;

            this.store.select(selectAllProvince).subscribe((provinces) => {
                const proviceSegment = urlSegments[1].toString().replace('/', '');
                positionFound = provinces.map((province) => province.slug).indexOf(proviceSegment);
                if (positionFound >= 0) {
                    this.setMetaTags('/thailand-tour');
                }
            });

            this.store.select(selectAllCountry).subscribe((countries) => {
                positionFound = countries
                    .filter((country) => country.slug !== 'thailand')
                    .map((country) => country.slug)
                    .indexOf(urlSegments[0].toString());
                if (positionFound >= 0) {
                    this.setMetaTags('/outbound-tour');
                }
            });
        }
    }

    /** Setup Store */
    private setStoreSeoConfig(config: SeoConfigs | null): void {
        this.store.dispatch(setSeoConfig({ data: config }));
    }
    private setStoreTemplateConfig(templateConfig: TemplateConfig): void {
        this.store.dispatch(setTemplateConfig({ config: templateConfig }));
    }
    private setStoreAgencyInfo(agencyInfo: AgencyInfo): void {
        this.store.dispatch(setAgencyInfo({ data: agencyInfo }));
    }

    /** Utilities */
    private getDomainFilters(): IDomainFilters {
        let host: string;
        if (this.isServer) {
            host = this.injector.get('host');
        } else {
            host = window.location.hostname;
        }

        return UrlHelper.getDomainFilters(host);
    }

    private initGoogleAnalytics(googleAnalytics: SeoGoogleAnalytics): void {
        if (!googleAnalytics) {
            return;
        }

        if (googleAnalytics.analyticsId) {
            this.setupGTag(googleAnalytics.analyticsId);
        }
        if (googleAnalytics.analyticsPropertyId) {
            this.setupGTag(googleAnalytics.analyticsPropertyId);
        }
    }

    private setupGTag(tagId: string): void {
        this.injectTagManagerToDOM(tagId);

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (!this.gtagScriptLoaded) {
                    gtag('config', tagId, {
                        page_path: event.urlAfterRedirects,
                    });
                }
            }
        });
    }

    private injectTagManagerToDOM(tagId: string): void {
        const gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');

        if (!this.gtagScriptLoaded) {
            gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${tagId}`);
            this.gtagScriptLoaded = true;
        }

        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${tagId}\');`;

        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);
    }

    private setMetaTags(url: string): void {
        if (!this.seoConfig) {
            return;
        }

        const metaTags = MetaTagsHelper.getTitleAndDescription(url, this.seoConfig);
        this.metadataService.updateMetadata({
            displayName: this.seoConfig.displayName,
            title: metaTags ? metaTags.title : '',
            description: metaTags ? metaTags.metaDescription : '',
        });
    }

    private initLuckyOrange(domain: string): void {
        let tagId: string;
        switch (domain) {
            case 'starlighttravelth.com':
                tagId = '0598ef15';
                break;
            case 'thineetour.com':
                tagId = '574cca3c';
                break;
            case 'blisstraveller.com':
                tagId = '88482c35';
                break;
            case 'lktravelthai.com':
                tagId = 'e267734a';
                break;
            case 'freezetravelth.com':
                tagId = '2f998009';
                break;
            case 'suksanook.com':
                tagId = 'e6c3e963';
                break;
            case 'madamontour.com':
                tagId = '7ea91cb0';
                break;
            case 'dealwow.com':
                tagId = '14b32490';
                break;
            default:
                return;
                break;
        }

        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('async', 'true');
        scriptTag.setAttribute('defer', 'true');
        scriptTag.setAttribute('src', `https://tools.luckyorange.com/core/lo.js?site-id=${tagId}`);

        document.documentElement.firstChild.appendChild(scriptTag);
    }

    private injectGoogleTagManager(tagId: string): void {
        if (!tagId) return;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const script = document.createElement('script');
        script.setAttribute('async', 'true');
        script.setAttribute('src', `https://www.googletagmanager.com/gtm.js?id=${tagId}`);

        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${tagId}`);
        iframe.setAttribute('height', '0');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('style', 'display:none;visibility:hidden');
        noscript.appendChild(iframe);

        document.head.insertBefore(script, document.head.firstChild);
        document.body.appendChild(noscript);
    }
}
