import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './stores/reducers';

import { AppComponent } from './app.component';
import { ThemeModule } from './themes/theme.module';
import { ThemeComponent } from './themes/theme.component';
import { ThemeDirective } from './themes/theme.directive';

// State
import { AgencyStoreModule } from './stores/agency-store/agency-store.module';
import { BannerStoreModule } from './stores/banner-store/banner-store.module';
import { CountryStoreModule } from './stores/country-store/country-store.module';
import { ProvinceStoreModule } from './stores/province-store/province-store.module';
import { MonthStoreModule } from './stores/month-store/month-store.module';
import { LeadStoreModule } from './stores/lead-store/lead-store.module';
import { ProductStoreModule } from './stores/product-store/product-store.module';
import { TemplateStoreModule } from './stores/template-store/template-store.module';
import { ProductSearchResultStoreModule } from './stores/product-search-result-store/product-search-result-store.module';
import { PortfolioStoreModule } from './stores/portfolio-store/portfolio-store.module';
import { CampaignStoreModule } from './stores/campaign-store/campaign-store.module';

// Ngx-bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
    declarations: [AppComponent, ThemeComponent, ThemeDirective],
    imports: [
        BrowserModule.withServerTransition({ appId: 'TourwowMall' }),
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        TransferHttpCacheModule,
        FormsModule,
        ReactiveFormsModule,

        //
        TemplateStoreModule,
        AgencyStoreModule,
        BannerStoreModule,
        CountryStoreModule,
        ProvinceStoreModule,
        MonthStoreModule,
        LeadStoreModule,
        ProductStoreModule,
        ProductSearchResultStoreModule,
        PortfolioStoreModule,
        CampaignStoreModule,

        EffectsModule.forRoot(),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],

        //
        ModalModule.forRoot(),
        CarouselModule,
        //
        ThemeModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
