import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BlogModel } from 'src/app/models/blog.model';
import * as fromAgency from './agency-store.module';
import * as state from './agency.reducer';

export const selectUserState = createFeatureSelector<state.AgencyState>(fromAgency.FEATURE_NAME);

export const selectAgencyInfo = createSelector(selectUserState, (state: state.AgencyState) => state.agencyInfo);

export const selectAgencyInfoLoadFailure = createSelector(
    selectUserState,
    (state: state.AgencyState) => state.errorMessage
);

export const selectAboutUsInfo = createSelector(selectUserState, (state: state.AgencyState) => state.aboutUsInfo);

export const selectCustomContentDetail = createSelector(
    selectUserState,
    (state: state.AgencyState) => state.customContentDetail
);

export const selectAgencySeoConfigs = createSelector(selectUserState, (state: state.AgencyState) => state.seoConfigs);

export const selectBlogListing = createSelector(selectUserState, (state: state.AgencyState) =>
    state.blogListing ? new BlogModel().deserialise(state.blogListing) : null
);

export const selectBlogDetail = createSelector(selectUserState, (state: state.AgencyState) =>
    state.blogDetail ? new BlogModel().deserialiseDetail(state.blogDetail) : null
);

export const selectBlogDetailError = createSelector(
    selectUserState,
    (state: state.AgencyState) => state.blogDetailError
);
