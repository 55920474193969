import { selectAgencyInfo } from '../../../../stores/agency-store/agency.selectors';
import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TemplateConfig } from 'src/app/models/template.model';
import { Store, select } from '@ngrx/store';
import { selectThemeConfig } from 'src/app/stores/template-store/template.selectors';
import { takeUntil } from 'rxjs/operators';
import { State } from 'src/app/stores/reducers';
import { AgencyInfo } from 'src/app/models/agency.model';

@Component({
    selector: 'app-footer-dealwow',
    templateUrl: './footer-dealwow.component.html',
    styleUrls: ['./footer-dealwow.component.scss'],
})
export class FooterDealwowComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public showPortfolio = false;
    @Input() public showBlog = false;

    private unsubscribe$: Subject<void> = new Subject<void>();
    public themeConfig$: Observable<TemplateConfig>;
    public agencyInfo$: Observable<AgencyInfo>;
    public showLinePopup: boolean;
    public showPhonePopup: boolean;

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.loadThemeConfig();
        this.loadAgencyInfo();
    }

    ngAfterViewInit(): void {
        this.isShowActionBoxBelowFooter();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private loadThemeConfig(): void {
        // Load Template
        this.themeConfig$ = this.store.pipe(select(selectThemeConfig), takeUntil(this.unsubscribe$));
    }

    private loadAgencyInfo(): void {
        this.agencyInfo$ = this.store.pipe(select(selectAgencyInfo), takeUntil(this.unsubscribe$));
    }

    public lineGetModal(): void {
        let agencyInfo: AgencyInfo;
        this.agencyInfo$.subscribe((resp) => (agencyInfo = resp));
        if (agencyInfo.lineId || agencyInfo.lineQrUrl) {
            this.showPhonePopup = false;
            this.showLinePopup = !this.showLinePopup;
        }
    }

    public phoneGetModal(): void {
        let agencyInfo: AgencyInfo;
        this.agencyInfo$.subscribe((resp) => (agencyInfo = resp));
        if (agencyInfo.officeTelephoneNumber || agencyInfo.officeTelephoneNumber) {
            this.showLinePopup = false;
            this.showPhonePopup = !this.showPhonePopup;
        }
    }

    public isShowActionBoxBelowFooter(): boolean {
        if (document.getElementById('mobile-action-box')) {
            return true;
        } else {
            return false;
        }
    }
}
