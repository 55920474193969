import { createAction, props } from '@ngrx/store';
import { Portfolio, PortfolioDescription } from 'src/app/models/portfolio.model';

export const loadPortfolioDetail = createAction(
    '[Portfolio] Get portfolio detail.',
    props<{ subDomain: string; portfolioId: number }>()
);
export const loadPortfolioDetailSuccess = createAction(
    '[Portfolio] Get portfolio detail success.',
    props<{ data: Portfolio }>()
);
export const loadPortfolioDetailFailure = createAction(
    '[Portfolio] Get portfolio detail failure.',
    props<{ errorMessage: string }>()
);

export const loadPortfolioPaginatedListing = createAction(
    '[Portfolio] Get portfolio paginated listing.',
    props<{ subDomain: string; criteria?: { page: number; pageEntries: number } }>()
);
export const loadPortfolioPaginatedListingSuccess = createAction(
    '[Portfolio] Get portfolio paginated listing success.',
    props<{ data: PortfolioDescription[] }>()
);
export const loadPortfolioPaginatedListingFailure = createAction(
    '[Portfolio] Get portfolio paginated listing failure.',
    props<{ errorMessage: string }>()
);
