import { createAction, props } from '@ngrx/store';
import { IResultForCreateLead, IRequestBodyForCreateLead } from 'src/app/services/lead.service';

export const leadCreation = createAction(
    '[Lead] Lead creation.',
    props<{ subDomain: string; body: IRequestBodyForCreateLead }>()
);
export const leadCreationSuccess = createAction(
    '[Lead] Lead creation success.',
    props<{ result: IResultForCreateLead }>()
);
export const leadCreationFailure = createAction(
    '[Lead] Lead creation failure.',
    props<{ result: IResultForCreateLead }>()
);
export const resetLeadCreationResult = createAction('[Lead] Reset lead creation result.');
