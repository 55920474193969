<div class="social-links">
    <button (click)="popupLineShare()" class="btn mr-2 px-2 py-1 mb-1 line-link">
        <img src="assets/images/icon-line-white.svg" width="20" height="19" alt="icon-line-white" />
        <span class="ml-1 d-none d-md-inline">แชร์</span>
    </button>
    <button (click)="popupFacebookShare()" class="btn mr-2 px-2 py-1 p-0 mb-1 facebook-link">
        <img src="assets/images/icon-facebook.jpg" width="19" height="19" alt="icon-facebook" />
        <span class="ml-1 d-none d-md-inline">แชร์</span>
    </button>
    <button (click)="copyLink()" class="btn mr-2 px-2 py-1 p-0 mb-1 copy-link">
        <img src="assets/images/icon-copylink.svg" width="20" height="19" alt="icon-copylink" />
        <span class="ml-1 d-none d-md-inline">Copy</span>
    </button>
</div>

<ng-template #CopyLinkSuccessModal>
    <div class="copy-link-success-modal">
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="p-5 text-center">
            <i class="icon-success"></i>
            <div class="my-3">Copy Link เรียบร้อยแล้ว</div>
            <button class="btn btn-outline btn-close" (click)="modalRef.hide()">ปิด</button>
        </div>
    </div>
</ng-template>
