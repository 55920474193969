export const THEME: any = {
    A1: 1,
    B1: 2,
    A2E: 3,
    A2TH: 4,
    A3E: 5,
    A3TH: 6,
    OUTBOUND1: 7,

    // Special Template
    DEALWOW: 90,
    GIFT_A_WOW: 91,
};
