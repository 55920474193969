declare let $: any;

import { isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { AgencyProductCategory, AgencyInfo } from './../../../../models/agency.model';
import { IBanner } from 'src/app/models/banner.model';
import { selectBannerConfig } from 'src/app/stores/banner-store/banner.selectors';
import {
    Component,
    OnInit,
    OnDestroy,
    HostListener,
    ViewChild,
    ElementRef,
    Renderer2,
    Input,
    AfterViewInit,
    AfterViewChecked,
    ChangeDetectorRef,
    Inject,
    Injector,
    PLATFORM_ID,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TemplateConfig } from 'src/app/models/template.model';
import { Store, select } from '@ngrx/store';
import { selectThemeConfig, selectDisplayHeaderBanner } from 'src/app/stores/template-store/template.selectors';
import { takeUntil, filter } from 'rxjs/operators';
import { State } from 'src/app/stores/reducers';
import { selectAgencyInfo } from 'src/app/stores/agency-store/agency.selectors';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ICountryContinents } from 'src/app/models/country.model';
import { IProvince } from 'src/app/models/province.model';
import { selectActiveCountries } from 'src/app/stores/country-store/country.selectors';
import { selectActiveProvinces } from 'src/app/stores/province-store/province.selectors';
import { loadActiveCountries } from 'src/app/stores/country-store/country.actions';
import { loadActiveProvinces } from 'src/app/stores/province-store/province.actions';
import { MENU } from 'src/app/constants/menu.constant';
import { TemplateService } from 'src/app/services/template.service';

@Component({
    selector: 'app-header-a',
    templateUrl: './header-a.component.html',
    styleUrls: ['./header-a.component.scss'],
})
export class HeaderAComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    @Input() showPortfolio = false;
    @Input() showBlog = false;

    themeConfig$: Observable<TemplateConfig>;

    countryContinents$: Observable<ICountryContinents>;
    provinces: IProvince[] = [];
    regionIdOrder = [1, 3, 2, 5, 4, 6];

    bannerConfig$: Observable<IBanner[]>;
    agencyInfo: AgencyInfo;
    displayBanner$: Observable<boolean>;
    toggleMobileMenu: boolean;
    toggleMobileSubMenu: boolean;
    currentRoute: string;
    isNotHome: boolean = false;
    appVersion: string = environment.appVersion;
    activeSubmenu: number = -1;
    logoNotVisible = false;
    campaignCategory: AgencyProductCategory;
    MENU = MENU;

    hideSubMenu = false;

    private isServer = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    @ViewChild('header_bottom', { read: ElementRef }) headerBottom: ElementRef;
    @ViewChild('desktop_carousel', { read: ElementRef }) desktopCarousel: ElementRef;
    @ViewChild('logo', { read: ElementRef }) logo: ElementRef;

    @ViewChild('outboundNavMenu', { read: ElementRef }) outboundNavMenu: ElementRef;
    @ViewChild('domesticNavMenu', { read: ElementRef }) domesticNavMenu: ElementRef;
    @ViewChild('voucherNavMenu', { read: ElementRef }) voucherNavMenu: ElementRef;

    constructor(
        // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) protected platformId: Object,
        private injector: Injector,
        private store: Store<State>,
        private router: Router,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private location: Location,
        private templateService: TemplateService
    ) {
        this.isServer = isPlatformServer(this.platformId);

        this.themeConfig$ = this.store.select(selectThemeConfig);
        this.displayBanner$ = this.store.select(selectDisplayHeaderBanner);
    }

    ngOnInit(): void {
        this.loadBannerConfig();
        this.loadAgencyInfo();
        this.loadActiveCountries();
        this.loadActiveProvinces();
    }

    ngAfterViewInit(): void {
        if (!this.isServer) {
            this.store.pipe(select(selectDisplayHeaderBanner), takeUntil(this.unsubscribe$)).subscribe(() => {
                $('#home_image').carousel({
                    interval: 10000,
                });
                $('#home_image2').carousel({
                    interval: 10000,
                });
            });
        }
    }

    ngAfterViewChecked(): void {
        this.checkBannerMargin();
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private getTemplate(): void {
        let host: string;
        if (isPlatformServer(this.platformId)) {
            host = this.injector.get('host');
        } else if (isPlatformBrowser(this.platformId)) {
            host = window.location.hostname;
        } else {
            // ERRROR
            return;
        }

        this.themeConfig$ = this.templateService.getTemplateConfig(this.agencyInfo.subdomain, true);
    }

    private loadBannerConfig(): void {
        this.bannerConfig$ = this.store.pipe(select(selectBannerConfig), takeUntil(this.unsubscribe$));
    }

    private loadAgencyInfo(): void {
        this.store.pipe(select(selectAgencyInfo), takeUntil(this.unsubscribe$)).subscribe((agencyInfo: AgencyInfo) => {
            if (!agencyInfo) {
                return;
            }

            this.agencyInfo = agencyInfo;
            this.campaignCategory = this.agencyInfo.productCategories.find((category) => category.slug === 'campaign');

            this.getTemplate();

            this.store.dispatch(loadActiveCountries({ subDomain: agencyInfo.subdomain }));
            this.store.dispatch(loadActiveProvinces({ subDomain: agencyInfo.subdomain }));
        });
    }

    private loadActiveCountries(): void {
        this.countryContinents$ = this.store.pipe(select(selectActiveCountries), takeUntil(this.unsubscribe$));
    }

    private loadActiveProvinces(): void {
        this.store
            .pipe(
                select(selectActiveProvinces),
                filter((resp) => !!resp),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((provinceRegions) => {
                const regions = provinceRegions.regions;
                let com = [];
                // concat province array
                for (let i = 0; i < regions.length; i++) {
                    com = [...com, regions[i].provinces];
                }
                const provinces = [].concat.apply([], com);
                // order by nameTH
                provinces.sort((a, b) => a.nameTh.localeCompare(b.nameTh));
                this.provinces = provinces;
            });
    }

    private checkBannerMargin() {
        if (!this.isServer && this.headerBottom) {
            const marginTop = this.headerBottom.nativeElement.offsetHeight + 46;
            if (this.desktopCarousel) {
                this.renderer.setStyle(this.desktopCarousel.nativeElement, 'margin-top', -marginTop + 'px');
                this.renderer.setStyle(this.desktopCarousel.nativeElement, 'min-height', marginTop + 'px');
            }

            if (this.headerBottom.nativeElement.offsetHeight > 60) {
                this.renderer.setStyle(this.logo.nativeElement, 'margin-top', '6px');
            }
        }
    }

    onMobileSubmenuClick(activeSubmenu: number = 0): void {
        this.toggleMobileSubMenu = !this.toggleMobileSubMenu;
        if (this.toggleMobileSubMenu) {
            this.activeSubmenu = activeSubmenu;
        } else {
            this.activeSubmenu = -1;
        }
    }

    getIsShownMenuBySlug(slug: string): boolean {
        if (!this.agencyInfo) {
            return false;
        }

        return this.agencyInfo.menus.find((menu) => menu.slug === slug).isShown;
    }

    goBack(): void {
        this.location.back();
    }

    onSubMenuClick(): void {
        // Temporary d-none class dropdown-menu for mouse out.
        this.hideSubMenu = true;

        setTimeout(() => {
            this.hideSubMenu = false;
        }, 100);
    }

    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        const el = document.getElementById('logo');
        if (el) {
            const elemTop = el.getBoundingClientRect().top;
            const elemBottom = el.getBoundingClientRect().bottom;

            const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
            if (this.isNotHome) {
                if (!isVisible) {
                    this.logoNotVisible = true;
                } else {
                    this.logoNotVisible = false;
                }
            } else {
                this.logoNotVisible = false;
            }
        }
    }
}
