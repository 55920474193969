import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './product-search-result.reducer';
import { ProductSearchResultEffects } from './product-search-result.effects';

export const FEATURE_NAME = 'product-search-result';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(FEATURE_NAME, reducer),
        EffectsModule.forFeature([ProductSearchResultEffects]),
    ],
    providers: [ProductSearchResultEffects],
})
export class ProductSearchResultStoreModule {}
