import { IDatabaseParameter } from '../database-parameter';
import { ISortParameter, SortParameterHelper } from '../sort-parameter';

export class ProductListingQuery {
    public filters: IProductListingFilter;
    public sortParameters: IProductListingSortParameter[];
    public databaseParameter: IDatabaseParameter;

    public constructor(
        filters: IProductListingFilter = null,
        databaseParameter: IDatabaseParameter = null,
        sortParameters: IProductListingSortParameter[] = []
    ) {
        this.filters = filters;
        this.databaseParameter = databaseParameter;
        this.sortParameters = sortParameters;
    }

    public getQueryString(displayExpiredProducts: boolean): string {
        const filters = Object.assign({}, this.filters);
        filters.display_expired_products = displayExpiredProducts;
        const params = new URLSearchParams();

        if (this.databaseParameter) {
            if (this.databaseParameter.dbOffset) {
                params.set('db_offset', this.databaseParameter.dbOffset.toString());
            }
            if (this.databaseParameter.dbLimit) {
                params.set('db_limit', this.databaseParameter.dbLimit.toString());
            }
        }
        params.set('sort', SortParameterHelper.makeSortQueryString(this.sortParameters));
        params.set('filters', JSON.stringify(filters));

        return params.toString();
    }
}

export interface IProductListingSortParameter extends ISortParameter {
    columnName: ProductListingSortableColumns;
    isAscending: boolean;
}

export interface IProductListingFilter {
    category_sub_product_ids?: number[];
    un_recommended_category_sub_product_id?: number;
    region_ids?: number[];
    excluded_product_pool_ids?: number[];
    display_expired_products?: boolean;
}

export enum ProductListingSortableColumns {
    has_periods = 'has_periods',
}
