export class ImageFile {
    public id: number;
    public url: string;
    public downloadUrl: string;
    public ordinal: number;
    public isMain: boolean;

    public deserialize(data: any): this {
        this.id = data.image_file_id;
        this.url = data.image_file_url ? data.image_file_url : data.preview_url;
        this.ordinal = data.ordinal;
        this.isMain = data.is_main;
        this.downloadUrl = this.makeDownloadUrl(this.url);
        return this;
    }

    private makeDownloadUrl(url: string): string {
        if (!url) return null;
        const segments = url.split('/');

        if (segments[segments.length - 1].includes('_preview.')) {
            segments[segments.length - 1] = segments[segments.length - 1].replace('_preview.', '_download.');
        }
        return segments.join('/');
    }
}
