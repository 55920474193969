<div
    style="background-color: red"
    class="header__top-mobile d-block d-lg-none"
    [ngStyle]="{
        'background-color': (themeConfig$ | async)?.mainColor,
        color: (themeConfig$ | async)?.agencyNameColor
    }"
>
    <div class="d-flex align-items-center">
        <a
            href="#"
            data-toggle="modal"
            data-target="#menuleft"
            class="menus mx-3"
            (click)="toggleMobileMenu = !toggleMobileMenu"
        >
            <img src="../assets/images/ico-menu.svg" width="28" height="24"
        /></a>
        <a *ngIf="isNotHome" (click)="goBack()" class="menus mx-3"
            ><img src="../assets/images/ico-back.svg" width="28" height="24"
        /></a>

        <div class="user__profile-mobile">
            <div *ngIf="agencyInfo" class="user__image">
                <ng-container *ngIf="agencyInfo.logoDisplayMobileUrl; else noLogoDisplayMobileTemplete">
                    <img [src]="agencyInfo.logoDisplayMobileUrl" style="object-fit: cover" />
                </ng-container>
                <ng-template #noLogoDisplayMobileTemplete>
                    <img [src]="agencyInfo.logoDisplayUrl" style="object-fit: cover" />
                </ng-template>
            </div>
        </div>
    </div>
</div>

<!-- mobile menu -->
<div class="header__mobile--menu d-flex" *ngIf="toggleMobileMenu">
    <div
        class="backdrop d-lg-none"
        [class.d-none]="!toggleMobileMenu"
        (click)="toggleMobileMenu = false; toggleMobileSubMenu = false"
    ></div>
    <div
        class="mobile__menu--left"
        [ngStyle]="{
            'background-color': (themeConfig$ | async)?.secondaryColor,
            color: (themeConfig$ | async)?.agencyNameColor
        }"
    >
        <div
            class="d-flex mobile__title--menu px-2 py-3"
            [ngStyle]="{
                'background-color': (themeConfig$ | async)?.mainColor
            }"
        >
            <a href="javascript: void(0)" (click)="toggleMobileMenu = !toggleMobileMenu">
                <img src="/assets/images/ico-close-w.svg" width="14" height="14" />
            </a>
            <div class="ml-2">
                <ng-container *ngIf="agencyInfo">
                    <div class="mobile__agency--name">{{ agencyInfo.displayName }}</div>
                </ng-container>
            </div>
        </div>
        <div class="d-flex mobile__main--menu p-1" style="height: 70%">
            <ul class="list-group list-group-flush w-100">
                <li class="list-group-item">
                    <a [routerLink]="['/']">หน้าแรก</a>
                </li>
                <li class="list-group-item">
                    <a [routerLink]="['/', 'voucher']"> โปรโมชั่นอาหาร </a>
                </li>
                <li class="list-group-item">
                    <a [routerLink]="['/', 'blog']"> บทความ </a>
                </li>
                <li class="list-group-item">
                    <a [routerLink]="['/', 'about-us']"> เกี่ยวกับเรา </a>
                </li>
                <li class="list-group-item">
                    <a [routerLink]="['/', 'about-us']"> ติดต่อเรา </a>
                </li>
            </ul>
        </div>
        <div class="d-flex mobile__content--menu p-1">
            <ul class="list-group list-group-flush w-100">
                <li class="list-group-item pb-0">
                    <a routerLink="/ข้อตกลงการใช้งาน">ข้อตกลงการใช้งาน</a>
                </li>
                <li class="list-group-item pt-0">
                    <a routerLink="/นโยบายคุ้มครองข้อมูลส่วนบุคคล">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>
                </li>
            </ul>
        </div>
        <div class="d-flex mobile__contact--menu p-1">
            <span class="m-2">
                <a href="javascript: void(0)"><img src="/assets/images/ico-facebook-w.svg" width="24" height="24" /></a>
            </span>
            <span class="m-2">
                <a href="javascript: void(0)"><img src="/assets/images/ico-line-w.svg" width="24" height="24" /></a>
            </span>
            <span class="m-2">
                <a href="javascript: void(0)"><img src="/assets/images/ico-mail-w.svg" width="24" height="24" /></a>
            </span>
            <span class="m-2">
                <a href="javascript: void(0)"><img src="/assets/images/ico-call-w.svg" width="24" height="24" /></a>
            </span>
        </div>
    </div>
    <div class="mobile__menu--right" *ngIf="toggleMobileSubMenu">
        <!-- Outbound -->
        <ng-container *ngFor="let continent of (countryContinents$ | async)?.continents">
            <div class="mobile__sub--content m-4" [class.active]="activeSubmenu === continent.id">
                <h6>ทัวร์{{ continent.nameTh }}</h6>
                <ul class="list-group list-group-flush w-100">
                    <ng-container *ngFor="let country of continent?.countries">
                        <li *ngIf="country.id !== 1" class="list-group-item">
                            <a [routerLink]="'/' + country.slug + '-tour'">ทัวร์{{ country.nameTh }}</a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </ng-container>

        <!-- Domestic -->
        <div class="mobile__sub--content m-4" [class.active]="activeSubmenu === 1">
            <ul class="list-group list-group-flush w-100">
                <ng-container *ngFor="let province of provinces">
                    <li class="list-group-item">
                        <a [routerLink]="'/' + province.slug + '-tour'">ทัวร์{{ province.nameTh }}</a>
                    </li>
                </ng-container>
            </ul>
        </div>

        <!-- Voucher -->
        <div class="mobile__sub--content m-4" [class.active]="activeSubmenu === 11">
            <ul class="list-group list-group-flush w-100">
                <ng-container *ngFor="let subCat of campaignCategory.productSubCategories">
                    <li class="list-group-item" *ngIf="subCat.isAllowed">
                        <a [routerLink]="['/', subCat.slug]">{{ subCat.nameTh }}</a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>

<!-- desktop menu -->
<!--  -->
<div
    class="header__bottom d-none d-lg-block"
    #header_bottom
    [ngStyle]="{
        'background-color': (themeConfig$ | async)?.mainColor,
        color: (themeConfig$ | async)?.agencyNameColor
    }"
>
    <div class="container d-flex align-items-center position-relative">
        <a [routerLink]="['/']" class="user__profile">
            <div *ngIf="agencyInfo" class="user__image mr-3">
                <img id="logo" [src]="agencyInfo.logoDisplayUrl" style="object-fit: cover" width="100%" height="100%" />
            </div>
        </a>

        <ul class="header__menu header__menu--left d-inline-flex list-unstyled mb-0 mr-auto">
            <li class="nav-item">
                <a [routerLink]="['/']" class="nav-link">หน้าแรก</a>
            </li>
            <li class="nav-item">
                <a class="nav-link nav-link" [routerLink]="['/', 'voucher']"> โปรโมชั่นอาหาร </a>
            </li>
            <li class="nav-item">
                <a class="nav-link nav-link" [routerLink]="['/', 'blog']"> บทความ </a>
            </li>
            <li class="nav-item">
                <a class="nav-link nav-link" [routerLink]="['/', 'about-us']"> เกี่ยวกับเรา </a>
            </li>
            <li class="nav-item">
                <a class="nav-link nav-link" [routerLink]="['/', 'about-us']"> ติดต่อเรา </a>
            </li>
        </ul>
    </div>
</div>
