import { Component } from '@angular/core';

@Component({
    selector: 'app-footer-gift-a-wow',
    templateUrl: './footer-gift-a-wow.component.html',
    styleUrls: ['./footer-gift-a-wow.component.scss'],
})
export class FooterGiftAWowComponent {
    constructor() {}
    scrollToTop(): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
