import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// Interfaces, Models and Contants
import { IApiResponse } from '../models/interfaces/api-response.interface';
import { TemplateConfigConfig } from '../models/interfaces/response/template-config-response.interface';
import { TemplateConfig } from '../models/template.model';

// Services
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class TemplateService extends BaseHttpService {
    public getTemplateConfig(
        subDomain: string,
        requestOnServerSide: boolean = false
    ): Observable<TemplateConfig | null> {
        const url = environment.apiMallUrl + ENDPOINTS.TEMPLATE.CONFIG.replace('{subdomain}', subDomain);
        return this.get<IApiResponse<TemplateConfigConfig>>(url, {
            forPlatform: requestOnServerSide ? 'server' : 'browser',
        }).pipe(
            map((response: IApiResponse<TemplateConfigConfig>) => {
                if (response.status !== 'success') {
                    return null;
                }

                return new TemplateConfig().deserialize(response.data);
            })
        );
    }
}
