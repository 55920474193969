import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './country.reducer';
import { CountryEffects } from './country.effects';

export const FEATURE_NAME = 'country';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([CountryEffects])],
    providers: [CountryEffects],
})
export class CountryStoreModule {}
