import { CampaignTypeSlug } from 'src/app/constants/enums/campaign.enum';
import { ImageFile } from './image-file.model';
import { VoucherListItem } from './voucher-list-item.model';

export class CampaignDetail {
    public id: number;
    public partnerId: number;
    public typeSlug: CampaignTypeSlug;
    public name: string;
    public price: number;
    public priceCompare: number;
    public discountPercentage: number;
    public voucherQuantitySold: number;
    public reviewCount: number;
    public starRating: number;
    public shopDescription: string;
    public shopImageFileUrl: string;
    public shopBackgroundImageFileUrl: string;
    public voucherCondition: string;
    public experienceDescription: string;
    public experienceImageFiles: ImageFile[];
    public bannerImageFiles: ImageFile[];
    public shopName: string;
    public address: string;
    public shopLocation: string;

    public officeTelephoneNumber: string;
    public additionalPhoneNumber: string;
    public googleMapLocationLatitude: string;
    public googleMapLocationLongitude: string;
    public openingTime: string;
    public allowToIssueTaxInvoice: boolean;
    public vouchers: VoucherListItem[];

    public deserialize(data: any): this {
        this.id = data.id;
        this.partnerId = data.partner_id;
        this.typeSlug = data.type_slug;
        this.name = data.name;
        this.price = data.price;
        this.priceCompare = data.price_compare;
        this.discountPercentage = data.discount_percentage;
        this.voucherQuantitySold = data.voucher_quantity_sold;
        this.reviewCount = data.review_count;
        this.starRating = data.star_rating;
        this.shopDescription = data.shop_description;
        this.shopImageFileUrl = data.shop_image_file_url;
        this.shopBackgroundImageFileUrl = data.shop_background_image_file_url;
        this.voucherCondition = data.voucher_condition;
        this.experienceDescription = data.experience_description;
        this.experienceImageFiles = (data.experience_image_files as any[]).map((x) => new ImageFile().deserialize(x));
        this.bannerImageFiles = (data.banner_image_files as any[]).map((x) => new ImageFile().deserialize(x));
        this.shopName = data.shop_name;
        this.address = data.address;
        this.shopLocation = data.location_name;

        this.officeTelephoneNumber = data.office_telephone_number;
        this.additionalPhoneNumber = data.additional_phone_number;
        this.googleMapLocationLatitude = data.google_map_location_latitude;
        this.googleMapLocationLongitude = data.google_map_location_longitude;
        this.openingTime = data.opening_time;
        this.allowToIssueTaxInvoice = data.allow_to_issue_tax_invoice;
        this.vouchers = (data.vouchers as any[]).map((x) => new VoucherListItem().deserialize(x));

        return this;
    }
}
