import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';

import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService extends BaseHttpService {
    getOrderDetailByKey(subDomain: string, key: string): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.ORDER.GET_ORDER_PRODUCT_CATEGORY_BY_KEY.replace('{subdomain}', subDomain).replace('{key}', key);
        return this.get(url);
    }

    getOrderProgramDetail(subDomain: string, key: string): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.ORDER.GET_ORDER_PRODUCT_DETAIL.replace('{subdomain}', subDomain).replace('{key}', key);
        return this.get(url);
    }
}
