import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        const e = event as KeyboardEvent;
        const key = e.key;
        if (!key) {
            return;
        }

        if (key.length == 1) {
            const isKeyAllowed: boolean = new RegExp('^[0-9]$').test(key);
            if (!isKeyAllowed) {
                e.preventDefault();
            }
        }
    }
}
