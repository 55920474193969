import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base/base-http.service';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';

@Injectable({
    providedIn: 'root',
})
export class BannerService extends BaseHttpService {
    getBannerConfig(subDomain: string): Observable<any> {
        const url = environment.apiMallUrl + ENDPOINTS.BANNER.CONFIG.replace('{subdomain}', subDomain);
        return this.get(url);
    }
}
