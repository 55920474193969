import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './lead.reducer';
import { LeadEffects } from './lead.effects';

export const FEATURE_NAME = 'lead';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([LeadEffects])],
    providers: [LeadEffects],
})
export class LeadStoreModule {}
