import { IProvince, IProvinceRegions } from './../../models/province.model';
import { createAction, props } from '@ngrx/store';
import { IProvinceRecommend } from 'src/app/models/province-recommend.model';

export const loadActiveProvinces = createAction(
    '[Province] Load active provinces config.',
    props<{ subDomain: string }>()
);
export const loadActiveProvincesSuccess = createAction(
    '[Province] Load active provinces success.',
    props<{ data: IProvinceRegions }>()
);
export const loadActiveProvincesFailure = createAction(
    '[Province] Load active provinces failure.',
    props<{ error: any }>()
);

export const loadProvincesRecommend = createAction(
    '[Province] Load Provices Recommend.',
    props<{ subDomain: string }>()
);
export const loadProvincesRecommendSuccess = createAction(
    '[Province] Load Provices Recommend Success.',
    props<{ provincesRecommend: IProvinceRecommend[] }>()
);
export const loadProvincesRecommendFailure = createAction(
    '[Province] Load Provices Recommend Failure.',
    props<{ error: any }>()
);

export const loadAllProvince = createAction('[Province] Load All Provices.');
export const loadAllProvinceSuccess = createAction(
    '[Province] Load All Provices Success.',
    props<{ provinces: IProvince[] }>()
);
export const loadAllProvinceFailure = createAction('[Province] Load All Provices Failure.', props<{ error: any }>());
