import { Action, createReducer, on } from '@ngrx/store';
import { ProductSearchResultState, initialState } from './product-search-result.state';
import * as ProductSearchResultActions from './product-search-result.actions';

export const productSearchResultReducer = createReducer(
    initialState,
    on(ProductSearchResultActions.loadProductSearchResults, (state) => ({
        ...state,
        error: null,
    })),
    on(ProductSearchResultActions.loadProductSearchResultsSuccess, (state, action) => ({
        ...state,
        productSearchResults: action.productSearchResults,
        error: null,
    })),
    on(ProductSearchResultActions.loadProductSearchResultsFailure, (state, action) => ({
        ...state,
        error: action.error,
    })),
    on(ProductSearchResultActions.resetProductSearchResults, (state) => ({
        ...state,
        productSearchResults: null,
        error: null,
    }))
);

export function reducer(state: ProductSearchResultState | undefined, action: Action) {
    return productSearchResultReducer(state, action);
}
