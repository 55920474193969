<footer class="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-7">
                    <div class="row">
                        <div class="col-12 col-md-4 footer-col1">
                            <div class="row">
                                <ul class="col-5 col-md-12">
                                    <li><a href="#" title="หน้าแรก">หน้าแรก</a></li>
                                    <li><a href="#" title="เทศกาล">เทศกาล</a></li>
                                    <li><a href="#" title="วันพิเศษ">วันพิเศษ</a></li>
                                    <li><a href="#" title="บทความ">บทความ</a></li>
                                </ul>
                                <ul class="col-7 col-md-12 footer-col1-1">
                                    <li><a href="#" title="เกี่ยวกับเรา">เกี่ยวกับเรา</a></li>
                                    <li><a href="#" title="ติดต่อเรา">ติดต่อเรา</a></li>
                                    <li><a href="#" title=""></a></li>
                                    <li><a href="#" title=""></a></li>
                                    <li class="footer-col1-mb"><a href="#" title="ข้อตกลงการใข้งาน">ข้อตกลงการใข้งาน</a>
                                    </li>
                                    <li class="footer-col1-mb"><a href="#"
                                            title="นโยบายคุ้มครองข้อมูลส่วนบุคคล">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-md-8 footer-col2">
                            <div class="row">
                                <ul class="col-6">
                                    <li><a href="#" title="ของขวัญสุดพิเศษ">ของขวัญสุดพิเศษ</a></li>
                                    <li><a href="#" title="กระเช้า">กระเช้า</a></li>
                                    <li><a href="#" title="เค้ก">เค้ก</a></li>
                                    <li><a href="#" title="ขนม">ขนม</a></li>
                                    <li><a href="#" title="ลูกโป่ง">ลูกโป่ง</a></li>
                                    <li><a href="#" title="ดอกไม้">ดอกไม้</a></li>
                                </ul>
                                <ul class="col-6">
                                    <li><a href="#" title="ต้นไม้">ต้นไม้</a></li>
                                    <li><a href="#" title="แกดเจ็ต">แกดเจ็ต</a></li>
                                    <li><a href="#" title="เครื่องประดับ">เครื่องประดับ</a></li>
                                    <li><a href="#" title="เสื้อผ้า">เสื้อผ้า</a></li>
                                    <li><a href="#" title="ตุ๊กตา">ตุ๊กตา</a></li>
                                    <li><a href="#" title="ของมงคล">ของมงคล</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-5">
                    <div class="row">
                        <div class="col footer-col3">
                            <strong>Tourwow Co., Ltd.</strong>
                            <ul>
                                <li><i class="icon icon--phone"></i><a href="#" title="">025439876</a></li>
                                <li><i class="icon icon--mail"></i><a href="#" title="">support.giftawow@tourwow.com</a>
                                </li>
                                <li><i class="icon icon--facebook-square"></i><a href="#" title="facebook">giftawow</a>
                                </li>
                                <li><i class="icon icon--line"></i><a href="#" title="line">@giftawow</a></li>
                            </ul>
                        </div>
                        <div class="col footer-col4">
                            <div class="social">
                                <a href="#" title="facebook"><i class="icon icon--facebook"></i></a>
                                <a href="#" title="line"><i class="icon icon--line"></i></a>
                                <a href="#" title="e-mail"><i class="icon icon--mail"></i></a>
                                <a href="#" title="telephone"><i class="icon icon--phone"></i></a>
                            </div>
                            <img src="/assets/images/gift-a-wow-theme/qr-contact.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <p>
            <span>Powered by</span><img src="/assets/images/gift-a-wow-theme/logo-tourwow.png" alt="Logo TourWow">
        </p>
        <a href="#" title="ข้อตกลงการใข้งาน">ข้อตกลงการใข้งาน</a>
        <a href="#" title="นโยบายคุ้มครองข้อมูลส่วนบุคคล">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>
    </div>
    <a (click)="scrollToTop()" title="back to top" class="back-top-top" id="backToTop"><i class="icon icon--arr-line-top"></i></a>
</footer>