import { Component, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { selectAgencyInfo } from 'src/app/stores/agency-store/agency.selectors';
import { AgencyProductCategory, AgencyInfo } from 'src/app/models/agency.model';

@Component({
    selector: 'app-vouchers-submenu',
    templateUrl: './vouchers-submenu.component.html',
    styleUrls: ['./vouchers-submenu.component.scss'],
})
export class VouchersSubmenuComponent implements OnInit {
    private agencyInfo: AgencyInfo;
    public campaignCategory: AgencyProductCategory;

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.loadAgencyInfo();
    }

    private loadAgencyInfo(): void {
        this.store.pipe(select(selectAgencyInfo)).subscribe((data) => {
            this.agencyInfo = data;
            this.campaignCategory = this.agencyInfo.productCategories.find((category) => category.slug === 'campaign');
        });
    }
}
