import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './agency.reducer';
import { AgencyEffects } from './agency.effects';

export const FEATURE_NAME = 'agency';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([AgencyEffects])],
    providers: [AgencyEffects],
})
export class AgencyStoreModule {}
