<ng-container *ngIf="themeLoaded">
    <app-header-a
        [showPortfolio]=true
        [showBlog]=true
    ></app-header-a>
    <app-minipad></app-minipad>
    <router-outlet></router-outlet>
    <app-footer-a
        [showPortfolio]=true
        [showBlog]=true
    ></app-footer-a>
</ng-container>
