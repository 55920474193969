import { Action, createReducer, on } from '@ngrx/store';
import * as TemplateActions from './template.actions';
import { TemplateConfig } from '../../models/template.model';

export interface TemplateState {
    themeConfig: TemplateConfig;
    displayHeaderBanner: boolean;
    displayContactBox: boolean;
    errorMessage: string | null;
}

export const initialState: TemplateState = {
    themeConfig: null,
    displayHeaderBanner: true,
    displayContactBox: true,
    errorMessage: null,
};

export const templateReducer = createReducer(
    initialState,

    on(TemplateActions.setDisplayHeaderBanner, (state, action) => {
        return {
            ...state,
            displayHeaderBanner: action.isShow,
        };
    }),

    on(TemplateActions.setDisplayContactBox, (state, action) => {
        return {
            ...state,
            displayContactBox: action.isShow,
        };
    }),

    on(TemplateActions.loadTemplateConfig, (state, action) => {
        return {
            ...state,
        };
    }),
    on(TemplateActions.loadTemplateConfigSuccess, (state, action) => {
        return {
            ...state,
            themeConfig: action.data,
        };
    }),
    on(TemplateActions.loadTemplateConfigFailure, (state, action) => {
        return {
            ...state,
            error: action,
        };
    }),

    on(TemplateActions.setTemplateConfig, (state, action) => {
        return {
            ...state,
            themeConfig: action.config,
        };
    })
);

export function reducer(state: TemplateState | undefined, action: Action) {
    return templateReducer(state, action);
}
