import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProvince from './province-store.module';
import * as state from './province.reducer';

export const selectProvinceState = createFeatureSelector<state.ProvinceState>(fromProvince.FEATURE_NAME);

export const selectActiveProvinces = createSelector(
    selectProvinceState,
    (state: state.ProvinceState) => state.provinces
);

export const selectProvincesRecommend = createSelector(
    selectProvinceState,
    (state: state.ProvinceState) => state.provincesRecommend
);

export const selectAllProvince = createSelector(selectProvinceState, (state: state.ProvinceState) => state.allProvince);
