export interface ISortParameter {
    columnName: string;
    isAscending: boolean;
}

export class SortParameterHelper {
    public static makeSortQueryString(sortParameters: ISortParameter[]): string {
        return sortParameters
            .map((sortParameter) => (sortParameter.isAscending ? '' : '-') + sortParameter.columnName)
            .join(',');
    }
}
