import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseHttpService } from './base/base-http.service';

import { UrlHelper } from '../helpers/url.helper';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';

// Interfaces and Models
import { IDomainFilters } from '../models/interfaces/domain-filters.interface';
import { IApiResponse } from '../models/interfaces/api-response.interface';
import { ISeoConfigResponse } from '../models/interfaces/response/seo-config-response.interface';
import { SeoConfigs } from '../models/seo-configs.model';
import { AgencyInfo } from '../models/agency.model';
import { AgencyInfoResponse } from '../models/interfaces/response/agency-info-response.interface';

@Injectable({
    providedIn: 'root',
})
export class AgencyService extends BaseHttpService {
    getInfo(filter: IDomainFilters, requestOnServerSide: boolean = false): Observable<AgencyInfo | null> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.AGENCY.INFO +
            '?' +
            UrlHelper.getQueryString({ filters: { domain: filter.domain, subdomain: filter.subDomain } });
        return this.get<IApiResponse<AgencyInfoResponse>>(url, {
            forPlatform: requestOnServerSide ? 'server' : 'browser',
        }).pipe(
            map((response: IApiResponse<AgencyInfoResponse>) => {
                if (response.status !== 'success') {
                    return null;
                }

                return new AgencyInfo().deserialize(response.data);
            })
        );
    }

    getAboutUsDetails(subDomain: string): Observable<any> {
        const url = environment.apiMallUrl + ENDPOINTS.AGENCY.ABOUT_US_DETAILS.replace('{subdomain}', subDomain);
        return this.get(url);
    }

    getCustomContentDetail(subDomain: string, customContentId: number): Observable<any> {
        let url = environment.apiMallUrl + ENDPOINTS.AGENCY.CUSTOM_CONTENTS;
        url = url.replace('{subdomain}', subDomain);
        url = url.replace('{custom_content_id}', customContentId.toString());
        return this.get(url);
    }

    getSeoConfigs(filter: IDomainFilters, requestOnServerSide: boolean = false): Observable<SeoConfigs | null> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.AGENCY.SEO_CONFIGS +
            '?' +
            UrlHelper.getQueryString({ filters: { domain: filter.domain, subdomain: filter.subDomain } });
        return this.get<IApiResponse<ISeoConfigResponse>>(url, {
            forPlatform: requestOnServerSide ? 'server' : 'browser',
        }).pipe(
            map((response: IApiResponse<ISeoConfigResponse>) => {
                if (response.status !== 'success') {
                    // Error from API
                    return null;
                }

                return new SeoConfigs().deserialise(response.data);
            })
        );
    }
}
