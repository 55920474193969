import { SeoConfigs } from '../models/seo-configs.model';

export class MetaTagsHelper {
    public static getTitleAndDescription(
        route: string,
        seoConfigs: SeoConfigs
    ): { title: string; metaDescription: string } {
        const isProgramDetailPage = this.isProgramDetailPage(route);
        const isOtherDetailPage = this.isOtherDetailPage(route);

        switch (route) {
            case '/':
                return seoConfigs.homepage;
            case '/outbound-tour':
                return seoConfigs.outboundPage;
            case '/outbound-tour/package':
                return seoConfigs.outboundPackagePage;
            case '/thailand-tour':
                return seoConfigs.domesticPage;
            case '/voucher':
                return seoConfigs.voucherPage;
            case '/blog':
                return { title: 'บทความ', metaDescription: 'รวมบทความท่องเที่ยว ทั้งทัวร์ในประเทศ ทัวร์ต่างประเทศ' };
            case '/gallery':
                return {
                    title: 'ผลงานที่ผ่านมา',
                    metaDescription: 'ลูกค้าองค์กรที่ไว้วางใจใช้บริการกับเรา',
                };
            case '/about-us':
                return {
                    title: 'เกี่ยวกับเรา',
                    metaDescription: 'บริษัททัวร์ชั้นนำ คัดสรรเฉพาะทัวร์คุณภาพ เชื่อถือได้ คุ้มราคา',
                };
            case '/404':
                return { title: '404', metaDescription: '' };
            default:
                return { title: this.buildTitle(route, isProgramDetailPage, isOtherDetailPage), metaDescription: '' };
        }
    }

    private static isProgramDetailPage(route: string): boolean {
        if (route.indexOf('-tour/') >= 0) {
            return true;
        }
        return false;
    }

    private static isOtherDetailPage(route: string): boolean {
        if (route.startsWith('/gallery/') || route.startsWith('/blog/')) {
            return true;
        }
        return false;
    }

    private static buildTitle(route: string, isProgramDetailPage: boolean, isOtherDetailPage: boolean): string {
        route = /[^/]*$/.exec(route)[0]; // Everything after last slash (/)

        if (isProgramDetailPage) {
            const index = route.indexOf('-', route.indexOf('-') + 1);
            route = route.substring(index + 1);
        }

        if (isOtherDetailPage) {
            const index = route.indexOf('-');
            route = route.substring(index + 1);
        }

        return decodeURIComponent(route);
    }
}
