import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { State } from 'src/app/stores/reducers';
import { Store, select } from '@ngrx/store';
import { takeUntil, filter, first, take } from 'rxjs/operators';
import { selectActiveProvinces } from 'src/app/stores/province-store/province.selectors';
import { IProvinceRegion } from 'src/app/models/province.model';

@Component({
    selector: 'app-provinces-submenu',
    templateUrl: './provinces-submenu.component.html',
    styleUrls: ['./provinces-submenu.component.scss'],
})
export class ProvincesSubmenuComponent implements OnInit, OnDestroy {
    @Output() linkClick = new EventEmitter();

    private unsubscribe$: Subject<void> = new Subject<void>();
    public provinceRegions: IProvinceRegion[] = [];

    public regionIdOrder = [1, 3, 2, 5, 4, 6];

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.loadActiveProvinces();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private loadActiveProvinces(): void {
        this.store
            .pipe(
                select(selectActiveProvinces),
                filter((resp) => !!resp),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((provinceRegions) =>
                this.regionIdOrder.forEach((id) => {
                    this.provinceRegions.push(
                        provinceRegions.regions.find((region: IProvinceRegion) => region.id === id)
                    );
                })
            );
    }
}
