import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'striphtml',
})
export class StripHtmlPipe implements PipeTransform {
    transform(value: string, maximumLength?: number): any {
        let excludedHtml = value.replace(/<.*?>/g, '').replace(/\&nbsp;/g, ''); // replace tags

        if (maximumLength && excludedHtml.length >= maximumLength) {
            excludedHtml += '...';
        }

        return excludedHtml;
    }
}
