import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class CountryService extends BaseHttpService {
    getAllActive(subDomain: string): Observable<any> {
        const url = environment.apiMallUrl + ENDPOINTS.COUNTRY.ACTIVE.replace('{subdomain}', subDomain);
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const jsonFile = require('../constants/jsons/continents.json');
        jsonFile.continents.forEach((continent) => {
            continent.countries.map((country) => {
                country.icon_url = `${environment.mediaUrl}countries/${country.icon_url}`;
                return country;
            });
        });
        // Bypass
        return of({
            data: {
                continents: jsonFile.continents,
            },
            message: 'All countries.',
            status: 'success',
        });
        // return this.get(url);
    }

    getAll(): Observable<any> {
        const url = environment.apiMallUrl + ENDPOINTS.COUNTRY.ALL;
        return this.get(url);
    }

    getRecommend(subDomain: string): Observable<any> {
        const url = environment.apiMallUrl + ENDPOINTS.COUNTRY.RECOMMEND.replace('{subdomain}', subDomain);
        return this.get(url);
    }
}
