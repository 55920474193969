import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';

import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class LeadService extends BaseHttpService {
    createLead(subDomain: string, body: IRequestBodyForCreateLead): Observable<IResultForCreateLead> {
        const url = environment.apiMallUrl + ENDPOINTS.LEAD.CREATE.replace('{subdomain}', subDomain);
        return this.post(url, body).pipe(
            map((resp: any) => {
                if (resp.status == 'success') {
                    return { success: true, error: null };
                } else if (resp.status == 'error') {
                    return { success: false, error: resp.error };
                }
            })
        );
    }
}

export interface IResultForCreateLead {
    success: boolean;
    error: any;
}

export interface IRequestBodyForCreateLead {
    item_id: string;
    lead_type_slug: AvailableLeadTypeSlug;
    contact_name: string;
    phone_number: string;
    email: string;
    more_info: string;
    period_id: number;
}

export enum AvailableLeadTypeSlug {
    web = 'web',
    line = 'line',
    phone = 'phone',
}
