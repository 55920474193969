import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';

import { BaseHttpService } from './base/base-http.service';

import { ProductSearchQuery } from '../models/request/queries/product-search-query';

@Injectable({
    providedIn: 'root',
})
export class ProductSearchService extends BaseHttpService {
    getProductListAsPagination(
        subDomain: string,
        displayExpiredProducts: boolean,
        query: ProductSearchQuery
    ): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.PRODUCT.PAGINATED_LISTING.replace('{subdomain}', subDomain) +
            '?' +
            query.getQueryString(displayExpiredProducts);
        return this.get(url);
    }
}
