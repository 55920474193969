import { isPlatformServer } from '@angular/common';
import {
    Component,
    ComponentFactoryResolver,
    Inject,
    Injector,
    OnInit,
    PLATFORM_ID,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ThemeDirective } from './theme.directive';

import { Store } from '@ngrx/store';
import { State } from '../stores/reducers';
import { selectThemeConfig } from '../stores/template-store/template.selectors';

// Components
import { A1Component } from './designs/a-themes/a1/a1.component';
import { B1Component } from './designs/b-themes/b1/b1.component';
import { A3thComponent } from './designs/a-themes/a3th/a3th.component';
import { A3eComponent } from './designs/a-themes/a3e/a3e.component';
import { A2thComponent } from './designs/a-themes/a2th/a2th.component';
import { A2eComponent } from './designs/a-themes/a2e/a2e.component';
import { DealwowComponent } from './designs/special-theme/dealwow/dealwow.component';
import { GiftAWowComponent } from './designs/special-theme/gift-a-wow/gift-a-wow.component';

import { THEME } from '../constants/themes';

// Models
import { TemplateConfig } from '../models/template.model';
import { Outbound1Component } from './designs/outbound-themes/outbound1/outbound1.component';

@Component({
    selector: 'app-theme',
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss'],
})
export class ThemeComponent implements OnInit {
    @ViewChild(ThemeDirective, { static: true }) appThemeHost: ThemeDirective;
    themeComponent: any;

    private isServer = false;

    constructor(
        // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) protected platformId: Object,
        private injector: Injector,
        private store: Store<State>,

        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        this.isServer = isPlatformServer(this.platformId);
    }

    ngOnInit(): void {
        if (!this.isServer) {
            this.subscribeTemplateConfig();
        }
    }

    private subscribeTemplateConfig(): void {
        this.store.select(selectThemeConfig).subscribe((templateConfig: TemplateConfig | null) => {
            if (!templateConfig) {
                return;
            }

            this.renderTheme(templateConfig.id);
        });
    }

    /**
     * Render Template on Server Side Rendering.
     * @param themeId
     */
    private renderTheme(themeId: number): void {
        if (themeId == THEME.A1) {
            this.themeComponent = A1Component;
        } else if (themeId == THEME.A2E) {
            this.themeComponent = A2eComponent;
        } else if (themeId == THEME.A2TH) {
            this.themeComponent = A2thComponent;
        } else if (themeId == THEME.A3E) {
            this.themeComponent = A3eComponent;
        } else if (themeId == THEME.A3TH) {
            this.themeComponent = A3thComponent;
        } else if (themeId == THEME.B1) {
            this.themeComponent = B1Component;
        } else if (themeId == THEME.OUTBOUND1) {
            this.themeComponent = Outbound1Component;
        } else if (themeId == THEME.DEALWOW) {
            this.themeComponent = DealwowComponent;
        } else if (themeId == THEME.GIFT_A_WOW) {
            this.themeComponent = GiftAWowComponent;
        }

        this.viewContainerRef.detach();
        this.viewContainerRef.clear();

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.themeComponent);
        this.viewContainerRef = this.appThemeHost.viewContainerRef;
        this.viewContainerRef.createComponent<any>(componentFactory);
    }
}
