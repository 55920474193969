import { createAction, props } from '@ngrx/store';
import { TemplateConfig } from 'src/app/models/template.model';

export const setDisplayHeaderBanner = createAction(
    '[Template] Set display header banner.',
    props<{ isShow: boolean }>()
);

export const setDisplayContactBox = createAction('[Template] Set display contact box.', props<{ isShow: boolean }>());

export const loadTemplateConfig = createAction('[Template] Load template config.', props<{ subDomain: string }>());
export const loadTemplateConfigSuccess = createAction(
    '[Template] Load template config success.',
    props<{ data: TemplateConfig }>()
);
export const loadTemplateConfigFailure = createAction(
    '[Template] Load template config failure.',
    props<{ error: any }>()
);

export const setTemplateConfig = createAction('[Template] Set template config.', props<{ config: TemplateConfig }>());
