import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

const thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
];

const thaiMonthsAbbreviation = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
];

const dayOfWeekInThai = {
    Mon: 'จันทร์',
    Tue: 'อังคาร',
    Wed: 'พุธ',
    Thu: 'พฤหัสบดี',
    Fri: 'ศุกร์',
    Sat: 'เสาร์',
    Sun: 'อาทิตย์',
};

@Pipe({
    name: 'thaiMonthDateTime',
})
export class ThaiMonthDatetimePipe implements PipeTransform {
    transform(value: any, useAbbr: boolean = false, shortDateFormat = false, showFullYear: boolean = false): any {
        if (!value) {
            return;
        }
        const date = moment(value);
        if (!date.isValid()) {
            return;
        }

        const thaiDate = date.add(543, 'y');
        const yearFormat = showFullYear ? 'YYYY' : 'YY';
        const dateAsString = thaiDate.format(`DD/MM/${yearFormat} เวลา HH:mm`);

        const dateAsArray = dateAsString.split('/');
        const monthIndex = Number(dateAsArray[1]) - 1;
        let monthSelected = useAbbr ? thaiMonthsAbbreviation[monthIndex] : thaiMonths[monthIndex];
        monthSelected = shortDateFormat ? monthIndex + 1 + '' : monthSelected;
        dateAsArray['1'] = monthSelected;
        const result = shortDateFormat ? dateAsArray.join('/') : dateAsArray.join(' ');
        return result;
    }
}

@Pipe({
    name: 'thaidaterange',
})
export class ThaiDateRangePipe implements PipeTransform {
    transform(date1: string | Date, date2: string | Date): string {
        let result = '';
        const date1Obj = moment(date1);
        const date2Obj = moment(date2);
        if (!date1Obj.isValid() || !date2Obj.isValid()) {
            return result;
        }

        if (
            date1Obj.date() == date2Obj.date() &&
            date1Obj.month() == date2Obj.month() &&
            date1Obj.year() == date2Obj.year()
        ) {
            result = `${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(
                date1Obj
            )}`;
        } else if (date1Obj.month() == date2Obj.month() && date1Obj.year() == date2Obj.year()) {
            result = `${date1Obj.format('DD')} - ${date2Obj.format('DD')} ${
                thaiMonthsAbbreviation[date1Obj.month()]
            } ${this.convertToBuddhistYear(date1Obj)}`;
        } else if (date1Obj.month() != date2Obj.month() && date1Obj.year() != date2Obj.year()) {
            result = `${date1Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(
                date1Obj
            )} - ${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(
                date2Obj
            )}`;
        } else {
            result = `${date1Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} - ${date2Obj.format('DD')} ${
                thaiMonthsAbbreviation[date2Obj.month()]
            } ${this.convertToBuddhistYear(date2Obj)}`;
        }
        return result;
    }

    private convertToBuddhistYear(moment: moment.Moment): string {
        return (+moment.format('YYYY') + 543).toString().slice(2);
    }
}

@Pipe({
    name: 'thaimonthrange',
})
export class ThaiMonthRangePipe implements PipeTransform {
    transform(date1: string, date2: string): string {
        let result = '';
        const date1Obj = moment(date1);
        const date2Obj = moment(date2);
        if (!date1Obj.isValid() || !date2Obj.isValid()) {
            return result;
        }

        if (date1Obj.month() == date2Obj.month() && date1Obj.year() == date2Obj.year()) {
            result = `${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
        } else if (date1Obj.month() != date2Obj.month() && date1Obj.year() == date2Obj.year()) {
            result = `${thaiMonthsAbbreviation[date1Obj.month()]} - ${
                thaiMonthsAbbreviation[date2Obj.month()]
            } ${this.convertToBuddhistYear(date2Obj)}`;
        } else {
            result = `${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)} - ${
                thaiMonthsAbbreviation[date2Obj.month()]
            } ${this.convertToBuddhistYear(date2Obj)}`;
        }
        return result;
    }

    private convertToBuddhistYear(moment: moment.Moment): string {
        return (+moment.format('YYYY') + 543).toString().slice(2);
    }
}

@Pipe({
    name: 'thaidate',
})
export class ThaiDatePipe implements PipeTransform {
    transform(date: string | Date, displayDay: boolean = true): string {
        let result = '';
        const dateObj = moment(date);
        if (!dateObj.isValid()) {
            return result;
        }
        result = thaiMonthsAbbreviation[dateObj.month()] + ' ' + this.convertToBuddhistYear(dateObj);
        if (displayDay) {
            result = dateObj.format('DD') + ' ' + result;
        }
        return result;
    }

    private convertToBuddhistYear(moment: moment.Moment): string {
        return (+moment.format('YYYY') + 543).toString().slice(2);
    }
}
