import { UrlHelper } from 'src/app/helpers/url.helper';
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ProductSearchResult } from 'src/app/models/product-search-result.model';
import { select, Store } from '@ngrx/store';
import { selectAgencyInfo } from 'src/app/stores/agency-store/agency.selectors';
import { filter } from 'rxjs/operators';
import { State } from 'src/app/stores/reducers';
import { AgencyInfo } from 'src/app/models/agency.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-package-card-detail',
    templateUrl: './package-card-detail.component.html',
    styleUrls: ['./package-card-detail.component.scss'],
})
export class PackageCardDetailComponent implements OnInit {
    @Input() product: ProductSearchResult;
    @Input() isDetailPage: boolean = false;
    @Output() webContactClick = new EventEmitter<{ periodId: number }>();
    @Output() phoneContactClick = new EventEmitter<{ periodId: number }>();
    @Output() lineContactClick = new EventEmitter<{ periodId: number }>();
    @Output() showStickyActionBox: EventEmitter<boolean> = new EventEmitter<boolean>();

    public agencyInfo: AgencyInfo;
    private stopScrollListener: boolean = false;
    public friendlyUrl = UrlHelper;

    constructor(private router: Router, private store: Store<State>) {}

    ngOnInit(): void {
        this.getAgencyInfo();
    }

    private getAgencyInfo(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((ret) => ret != null)
            )
            .subscribe((agencyInfo) => {
                this.agencyInfo = agencyInfo;
            });
    }

    public onWebContactClick(): void {
        this.webContactClick.emit({ periodId: null });
    }

    public onPhoneContactClick(): void {
        this.phoneContactClick.emit({ periodId: null });
    }

    public onLineContactClick(): void {
        this.lineContactClick.emit({ periodId: null });
    }

    public canOpenLineContactModal(): boolean {
        return !!this.agencyInfo.lineUrl;
    }

    public getLinkUrlfirstUrlSegment(): string {
        return '/' + this.router.url.substring(1).split('/')[0];
    }

    @HostListener('window:scroll', [])
    public onWindowScroll(): void {
        if (!this.stopScrollListener) {
            const elementTarget = document.getElementById('mobile-contact-panel');
            if (window.scrollY > elementTarget.offsetTop + elementTarget.offsetHeight) {
                this.stopScrollListener = true;
                this.showStickyActionBox.emit(true);
            }
        }
    }
}
