import { IBanner } from './../../models/banner.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of, Observable, pipe } from 'rxjs';

import * as BannerAction from './banner.actions';
import { BannerService } from 'src/app/services/banner.service';

@Injectable()
export class BannerEffects {
    constructor(private actions$: Actions, private bannerService: BannerService, private router: Router) {}

    loadBanner = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(BannerAction.loadBannerConfig),
                concatMap((action) => {
                    return this.bannerService.getBannerConfig(action.subDomain).pipe(
                        map((response) => {
                            if (response.status !== 'success') {
                                return BannerAction.loadBannerConfigFailure(null);
                            }
                            const data = response.data;
                            const bannerConfig: IBanner[] = [
                                {
                                    imageMobileUrl: data.advertise_image_mobile_1_url,
                                    imageUrl: data.advertise_image_1_url,
                                    linkUrl: data.advertise_1_link_url,
                                },
                                {
                                    imageMobileUrl: data.advertise_image_mobile_2_url,
                                    imageUrl: data.advertise_image_2_url,
                                    linkUrl: data.advertise_2_link_url,
                                },
                                {
                                    imageMobileUrl: data.advertise_image_mobile_3_url,
                                    imageUrl: data.advertise_image_3_url,
                                    linkUrl: data.advertise_3_link_url,
                                },
                            ];

                            return BannerAction.loadBannerConfigSuccess({ data: bannerConfig });
                        }),
                        catchError((error) => {
                            return of(BannerAction.loadBannerConfigFailure(error));
                        })
                    );
                })
            )
    );
}
