import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as MonthActions from './month.actions';
import { MonthService } from 'src/app/services/month.service';
import { IMonthRange } from 'src/app/models/month.model';

@Injectable()
export class MonthEffects {
    constructor(private actions$: Actions, private monthService: MonthService) {}

    loadActiveMonthRange = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(MonthActions.loadActiveMonthRange),
                switchMap((action) => {
                    return this.monthService.getActiveMonthRange(action.subDomain, action.categorySubProductIds).pipe(
                        map((resp: any) => {
                            if (resp.status === 'success') {
                                const startMonth = moment(resp.data.start_month);
                                const endMonth = moment(resp.data.end_month);
                                const activeMonthRange: IMonthRange = {
                                    minMonth: null,
                                    maxMonth: null,
                                };
                                if (startMonth.isValid() && endMonth.isValid()) {
                                    activeMonthRange.minMonth = {
                                        month: startMonth.month() + 1,
                                        year: startMonth.year(),
                                    };
                                    activeMonthRange.maxMonth = {
                                        month: endMonth.month() + 1,
                                        year: endMonth.year(),
                                    };
                                }
                                return MonthActions.loadActiveMonthRangeSuccess({
                                    activeMonthRange,
                                });
                            } else if (resp.status === 'error') {
                                return MonthActions.loadActiveMonthRangeFailure({ error: resp.error });
                            }
                        }),
                        catchError((error) => of(MonthActions.loadActiveMonthRangeFailure({ error })))
                    );
                })
            )
    );
}
