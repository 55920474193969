import { ICustomContent } from './custom-content.model';
import { AgencyInfoResponse } from './interfaces/response/agency-info-response.interface';
import { Menu } from './menu.model';

export class AgencyInfo {
    id: number;
    slug: string;
    logoDisplayUrl: string;
    logoDisplayMobileUrl: string;
    displayName: string;
    taxNumber: string;
    licenseNumber: string;
    email: string;
    facebookName: string;
    facebookUrl: string;
    lineId: string;
    lineUrl: string;
    lineQrUrl: string;
    officeTelephoneNumber: string;
    additionalContactInfo: string;
    nameEn: string;
    nameTh: string;
    location: string;
    incentiveUrl: string;

    subdomain: string;
    domain: string;
    productCode: string;
    productDescription: string;
    dbdUrl: string;
    displayExpiredProducts: boolean;

    customContents: ICustomContent[];
    productCategories: AgencyProductCategory[];

    menus: Menu[];
    allowSellTour: boolean;
    googleTagManagerId: string | null;

    deserialize(data: AgencyInfoResponse): AgencyInfo {
        this.id = data.id;
        this.slug = data.config.agency_slug;
        this.logoDisplayUrl = data.config.logo_display_url;
        this.logoDisplayMobileUrl = data.config.logo_display_mobile_url || null;
        this.displayName = data.config.display_name;
        this.taxNumber = data.tax_number;
        this.licenseNumber = data.license_number;
        this.email = data.email;
        this.facebookName = data.facebook_name;
        this.facebookUrl = data.facebook_url;
        this.lineId = data.line_id;
        this.lineUrl = data.line_url;
        this.lineQrUrl = data.line_qr_url;
        this.officeTelephoneNumber = data.office_telephone_number;
        this.additionalContactInfo = data.additional_contact_info;
        this.nameEn = data.name_en;
        this.nameTh = data.name_th;
        this.location = data.address;
        this.incentiveUrl = data.incentive_url || null;

        this.domain = data.config.domain;
        this.subdomain = data.config.subdomain;
        this.productCode = data.config.product_code;
        this.productDescription = data.config.product_description;
        this.dbdUrl = data.config.dbd_url;
        this.displayExpiredProducts = data.config.display_expired_product;

        this.customContents = (data.custom_contents as any[]).map((customContentData) => ({
            id: customContentData.id,
            menuNameTh: customContentData.menu_name_th,
            menuNameEn: customContentData.menu_name_en,
            menuOrdinal: customContentData.menu_ordinal,
            isActive: customContentData.is_active,
        }));

        this.productCategories = (data.config.product_categories as any[]).map((category) =>
            new AgencyProductCategory().deserialize(category)
        );
        this.menus = data.menus && data.menus.length > 0 ? data.menus.map((menu) => new Menu().deserialize(menu)) : [];
        this.allowSellTour = data.config.payment_configs.allow_sell_tour;
        this.googleTagManagerId = data.config.seo_configs.google_tag_manager_id;

        return this;
    }
}

export interface IAboutUsResponse {
    agencyId: number;
    galleriesId: number;
    aboutUs: string;
}

export class AgencyProductCategory {
    public id: number;
    public slug: string;
    public name: string;
    public productSubCategories: AgencyProductSubCategory[];
    public isAllowed: boolean;

    public deserialize(data: any): this {
        this.id = data.id;
        this.slug = data.slug;
        this.name = data.name;
        this.productSubCategories = (data.product_sub_categories as any[]).map((subCategory) =>
            new AgencyProductSubCategory().deserialize(subCategory)
        );
        this.isAllowed = false;
        for (const category of this.productSubCategories) {
            if (category.isAllowed) this.isAllowed = true;
        }

        return this;
    }
}

export class AgencyProductSubCategory {
    public id: number;
    public slug: string;
    public nameEn: string;
    public nameTh: string;
    public isAllowed: boolean;

    public deserialize(data: any): this {
        this.id = data.id;
        this.slug = data.slug;
        this.nameEn = data.name;
        this.nameTh = data.name_th;
        this.isAllowed = data.is_allowed;

        return this;
    }
}
