import { Action, createReducer, on } from '@ngrx/store';
import { ProductState, initialState } from './product.state';
import * as ProductActions from './product.actions';
import { IProductBox } from 'src/app/models/product-box.model';

export const productReducer = createReducer(
    initialState,
    on(ProductActions.loadTourRecommendProducts, (state) => state),
    on(ProductActions.loadTourRecommendProductsSuccess, (state, action) => {
        // // Sorting recommended, subCat = 1, subCat = 2, subCat = 6
        let productBoxReccommend: IProductBox[] = [];
        let productBoxNotReccommend: IProductBox[] = [];
        productBoxReccommend = action.products.filter((program) => program.isRecommended);
        productBoxNotReccommend = action.products.filter((program) => !program.isRecommended);
        productBoxReccommend.sort((a, b) => {
            if (a.categorySubProductId < b.categorySubProductId) {
                return -1;
            }

            if (a.categorySubProductId > b.categorySubProductId) {
                return 1;
            }

            return 0;
        });

        return {
            ...state,
            tourRecommendProducts: productBoxReccommend.concat(productBoxNotReccommend),
        };
    }),
    on(ProductActions.loadTourRecommendProductsFailure, (state, action) => ({
        ...state,
        error: action.error,
    })),

    on(ProductActions.loadPackageTourProducts, (state) => state),
    on(ProductActions.loadPackageTourProductsSuccess, (state, action) => ({
        ...state,
        packageTourProducts: action.products,
    })),
    on(ProductActions.loadPackageTourProductsFailure, (state, action) => ({
        ...state,
        error: action.error,
    })),

    on(ProductActions.loadDomesticTourProducts, (state) => state),
    on(ProductActions.loadDomesticTourProductsSuccess, (state, action) => ({
        ...state,
        domesticTourProducts: action.products,
    })),
    on(ProductActions.loadDomesticTourProductsFailure, (state, action) => ({
        ...state,
        error: action.error,
    })),

    on(ProductActions.getProductDetail, (state) => ({ ...state, productDetail: null })),
    on(ProductActions.getProductDetailSuccess, (state, action) => ({ ...state, productDetail: action.product })),
    on(ProductActions.getProductDetailFailure, (state, action) => ({
        ...state,
        productDetail: null,
        error: action.error,
    })),

    on(ProductActions.getHighlightedShowcases, (state) => state),
    on(ProductActions.getHighlightedShowcasesSuccess, (state, action) => ({
        ...state,
        showcases: action.showcases,
    })),
    on(ProductActions.getHighlightedShowcasesFailure, (state, action) => ({
        ...state,
        error: action.error,
    })),

    on(ProductActions.loadPdfDetailFile, (state) => ({ ...state, pdfDetailFile: null })),
    on(ProductActions.loadPdfDetailFilesSuccess, (state, action) => ({
        ...state,
        pdfDetailFile: action.pdfDetailFile,
    })),
    on(ProductActions.loadTourRecommendProductsFailure, (state, action) => ({
        ...state,
        error: action.error,
    })),
    on(ProductActions.resetPdfDetailFile, (state) => ({ ...state, pdfDetailFile: null })),

    on(ProductActions.createProgramOrderSuccess, (state, action) => ({
        ...state,
        createProductOrderResponse: action.data,
    })),

    on(ProductActions.getOrderProgramDetailSuccess, (state, action) => ({
        ...state,
        orderProgramDetail: action.data,
    }))
);

export function reducer(state: ProductState | undefined, action: Action) {
    return productReducer(state, action);
}
