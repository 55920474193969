import { IHighlightedShowcase, IPdfDetailFile } from './../../models/product.model';
import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ProductAction from './product.actions';
import { ProductService } from 'src/app/services/product.service';
import { IProductBox, ProductBox } from 'src/app/models/product-box.model';
import { IProduct } from 'src/app/models/product.model';
import { ProductSearchResult } from 'src/app/models/product-search-result.model';
import { Router } from '@angular/router';
import {
    ProductListingQuery,
    ProductListingSortableColumns,
} from 'src/app/models/request/queries/product-listing-query';
import { RoomType } from 'src/app/models/room-type.model';
import { OrderService } from 'src/app/services/order.service';
import { OrderProgramDetail } from 'src/app/models/product/program/order-program-detail.model';

@Injectable()
export class ProductEffects {
    constructor(
        private actions$: Actions,
        private productService: ProductService,
        private orderService: OrderService,
        private router: Router
    ) {}

    loadTourRecommendProducts = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductAction.loadTourRecommendProducts),
                switchMap((action) => {
                    const query = new ProductListingQuery(
                        { category_sub_product_ids: [1, 2, 6, 7], un_recommended_category_sub_product_id: 1 },
                        { dbOffset: 0, dbLimit: action.limit }
                    );

                    return this.productService
                        .getProductList(action.subDomain, action.displayExpiredProducts, query)
                        .pipe(
                            map((resp: any) => {
                                const products = (resp.data as any[]).map((data) => new ProductBox().deserialize(data));
                                return ProductAction.loadTourRecommendProductsSuccess({ products });
                            }),
                            catchError((error) => of(ProductAction.loadTourRecommendProductsFailure(error)))
                        );
                })
            )
    );

    loadPackageTourProducts = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductAction.loadPackageTourProducts),
                switchMap((action) => {
                    const query = new ProductListingQuery(
                        { category_sub_product_ids: [6], excluded_product_pool_ids: action.excludedProductPoolIds },
                        { dbOffset: 0, dbLimit: 11 },
                        [{ columnName: ProductListingSortableColumns.has_periods, isAscending: true }]
                    );
                    return this.productService
                        .getProductList(action.subDomain, action.displayExpiredProducts, query)
                        .pipe(
                            map((resp: any) => {
                                const products = (resp.data as any[]).map((data) => new ProductBox().deserialize(data));
                                return ProductAction.loadPackageTourProductsSuccess({ products });
                            }),
                            catchError((error) => of(ProductAction.loadPackageTourProductsFailure(error)))
                        );
                })
            )
    );

    loadDomesticTourProducts = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductAction.loadDomesticTourProducts),
                switchMap((action) => {
                    const query = new ProductListingQuery(
                        { category_sub_product_ids: [6, 7], region_ids: [action.regionId] },
                        { dbOffset: 0, dbLimit: 7 }
                    );
                    return this.productService
                        .getProductList(action.subDomain, action.displayExpiredProducts, query)
                        .pipe(
                            map((resp: any) => {
                                const products = (resp.data as any[]).map((data) => new ProductBox().deserialize(data));
                                return ProductAction.loadDomesticTourProductsSuccess({ products });
                            }),
                            catchError((error) => of(ProductAction.loadDomesticTourProductsFailure(error)))
                        );
                })
            )
    );

    loadProductDetail = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductAction.getProductDetail),
                switchMap((action) => {
                    return this.productService.getProductDetail(action.subDomain, action.keyItem).pipe(
                        map((resp: any) => {
                            const data = resp.data;
                            // Please deserialize at NgRx selector. (Anuk)
                            const product: IProduct = {
                                id: data.id,
                                productResult: new ProductSearchResult().deserialize(data),
                                tourcode: data.tour_code,
                                name: data.name,
                                durationDay: data.duration_day,
                                durationNight: data.duration_night,
                                descriptions: data.product_descriptions.map((description) => {
                                    return {
                                        id: description.id,
                                        description: description.description,
                                        ordinal: description.ordinal,
                                        type: description.type_slug,
                                        imageUrl: description.image_url,
                                        imageContentUrl: description.image_content_url || '',
                                        imageThumbnailUrl: description.image_thumbnail_url || '',
                                        imageLocationName: description.image_location_name,
                                    };
                                }),
                                productOwner: {
                                    id: data.owner ? data.owner.id : null,
                                    nameEN: data.owner ? data.owner.name_en : null,
                                    nameTH: data.owner ? data.owner.name_th : null,
                                },
                                periods: data.periods
                                    .filter((period) => {
                                        const currentDate = new Date();
                                        currentDate.setHours(0, 0, 0, 0);
                                        return new Date(period.start_at) >= currentDate || period.image_url;
                                    })
                                    .map((period) => {
                                        return {
                                            id: period.id,
                                            startAt: new Date(period.start_at),
                                            endAt: new Date(period.end_at),
                                            price: parseInt(period.price),
                                            comparePrice: parseInt(period.compare_price),
                                            priceChildNoBed: parseInt(period.price_child_no_bed),
                                            priceChildNoBedCompare: parseInt(period.price_child_no_bed_compare),
                                            imageUrl: period.image_url,

                                            quantityRemaining: +period.quantity_remaining,
                                            roomTypes: period.room_types.map((roomType) =>
                                                new RoomType().deserialize(roomType)
                                            ),
                                        };
                                    }),
                                tourCondition: data.tour_condition,
                                hasPdfDetailFile: data.has_pdf_detail_file,
                            };
                            return ProductAction.getProductDetailSuccess({ product });
                        }),
                        catchError((error) => of(ProductAction.getProductDetailFailure(error)))
                    );
                })
            )
    );

    getShowcases = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductAction.getHighlightedShowcases),
                switchMap((action) => {
                    return this.productService.getHighlightedShowcases(action.subDomain).pipe(
                        map((resp: any) => {
                            if (resp.status === 'success') {
                                const showcases = (resp.data as any[]).map((data) => {
                                    return {
                                        categorySubProductsId: data.category_sub_products_id,
                                        productsId: data.products_id,
                                        ownerChannel: data.owner_channel,
                                        ownerId: data.owner_id,
                                        productName: data.product_name,
                                        isFromAgcyProduct: data.is_from_agcy_product,
                                        showcaseBannerUrl: data.showcase_banner_url,
                                    };
                                });
                                return ProductAction.getHighlightedShowcasesSuccess({ showcases });
                            } else {
                                return ProductAction.getHighlightedShowcasesFailure(resp);
                            }
                        }),
                        catchError((error) => of(ProductAction.getHighlightedShowcasesFailure(error)))
                    );
                })
            )
    );

    loadPdfDetailFile = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductAction.loadPdfDetailFile),
                switchMap((action) => {
                    return this.productService.getPdfDetailFile(action.subDomain, action.poolKey).pipe(
                        map((resp: any) => {
                            if (resp.status == 'success') {
                                const data = resp.data;
                                const pdfDetailFile: IPdfDetailFile = {
                                    url: data.url,
                                };
                                return ProductAction.loadPdfDetailFilesSuccess({ pdfDetailFile });
                            } else {
                                return ProductAction.loadDomesticTourProductsFailure(resp);
                            }
                        }),
                        catchError((error) => of(ProductAction.loadPdfDetailFileFailure(error)))
                    );
                })
            )
    );

    createProgramOrder = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductAction.createProgramOrder),
                switchMap((action) => {
                    return this.productService
                        .createProgramOrder(action.subDomain, action.poolKey, action.bookingData)
                        .pipe(
                            map((resp: any) => {
                                if (resp.status === 'success') {
                                    return ProductAction.createProgramOrderSuccess({
                                        data: { id: resp.data.id, orderTempKey: resp.data.order_temp_key },
                                    });
                                }

                                return ProductAction.createProgramOrderFailure(resp);
                            }),
                            catchError((error) => of(ProductAction.createProgramOrderFailure(error)))
                        );
                })
            )
    );

    getOrderProgramDetail = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductAction.getOrderProgramDetail),
                switchMap((action) => {
                    return this.orderService.getOrderProgramDetail(action.subDomain, action.key).pipe(
                        map((resp: any) => {
                            if (resp.status === 'success') {
                                const data: OrderProgramDetail = new OrderProgramDetail().deserialize(resp.data);
                                return ProductAction.getOrderProgramDetailSuccess({ data: data });
                            }

                            return ProductAction.getOrderProgramDetailFailure({ error: resp });
                        }),
                        catchError((error) => of(ProductAction.getOrderProgramDetailFailure(error)))
                    );
                })
            )
    );
}
