import { Location } from '@angular/common';
import { AgencyProductCategory, AgencyInfo } from '../../../../models/agency.model';
import { IBanner } from 'src/app/models/banner.model';
import {
    Component,
    OnInit,
    OnDestroy,
    HostListener,
    ViewChild,
    ElementRef,
    Renderer2,
    Input,
    AfterViewChecked,
    ChangeDetectorRef,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TemplateConfig } from 'src/app/models/template.model';
import { Store, select } from '@ngrx/store';
import { selectThemeConfig } from 'src/app/stores/template-store/template.selectors';
import { takeUntil } from 'rxjs/operators';
import { State } from 'src/app/stores/reducers';
import { selectAgencyInfo } from 'src/app/stores/agency-store/agency.selectors';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ICountryContinents } from 'src/app/models/country.model';
import { IProvince } from 'src/app/models/province.model';
import { loadActiveCountries } from 'src/app/stores/country-store/country.actions';
import { loadActiveProvinces } from 'src/app/stores/province-store/province.actions';

declare let $: any;

@Component({
    selector: 'app-header-dealwow',
    templateUrl: './header-dealwow.component.html',
    styleUrls: ['./header-dealwow.component.scss'],
})
export class HeaderDealwowComponent implements OnInit, AfterViewChecked, OnDestroy {
    @Input() public showPortfolio = false;
    @Input() public showBlog = false;

    public countryContinents$: Observable<ICountryContinents>;
    public provinces: IProvince[] = [];
    public regionIdOrder = [1, 3, 2, 5, 4, 6];

    private unsubscribe$: Subject<void> = new Subject<void>();
    public themeConfig$: Observable<TemplateConfig>;
    public bannerConfig$: Observable<IBanner[]>;
    public agencyInfo: AgencyInfo;
    public displayBanner$: Observable<boolean>;
    public toggleMobileMenu: boolean;
    public toggleMobileSubMenu: boolean;
    public isUrlChanged: boolean = false;
    public currentRoute: string;
    public isNotHome: boolean = false;
    public appVersion: string = environment.appVersion;
    public activeSubmenu: number = -1;
    public logoNotVisible = false;

    public campaignCategory: AgencyProductCategory;

    @ViewChild('header_bottom', { read: ElementRef }) headerBottom: ElementRef;
    @ViewChild('desktop_carousel', { read: ElementRef }) desktopCarousel: ElementRef;
    @ViewChild('logo', { read: ElementRef }) logo: ElementRef;

    constructor(
        private store: Store<State>,
        private router: Router,
        private renderer: Renderer2,
        private location: Location,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.loadThemeConfig();
        this.loadAgencyInfo();
        this.subscribeToRouterEvents();
    }

    ngAfterViewChecked(): void {
        this.checkIfHome();
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private loadThemeConfig(): void {
        // Load Template
        this.themeConfig$ = this.store.pipe(select(selectThemeConfig), takeUntil(this.unsubscribe$));
    }

    private loadAgencyInfo(): void {
        this.store.pipe(select(selectAgencyInfo), takeUntil(this.unsubscribe$)).subscribe((agencyInfo: AgencyInfo) => {
            this.agencyInfo = agencyInfo;
            this.campaignCategory = this.agencyInfo.productCategories.find((category) => category.slug === 'campaign');
            this.store.dispatch(loadActiveCountries({ subDomain: agencyInfo.subdomain }));
            this.store.dispatch(loadActiveProvinces({ subDomain: agencyInfo.subdomain }));

            this.setErrorPage404();
        });
    }

    private setErrorPage404(): void {
        for (const subCategory of this.campaignCategory.productSubCategories) {
            if (!subCategory.isAllowed) {
                if (this.router.url == '/' + subCategory.slug) {
                    this.router.navigate(['404']);
                }
            }
        }
    }

    private subscribeToRouterEvents(): void {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.currentRoute = this.router.url;
            }
            if (val instanceof NavigationEnd) {
                if (this.currentRoute === '/') {
                    this.isUrlChanged = true;
                } else {
                    this.isUrlChanged = false;
                }
                this.toggleMobileMenu = false;
                this.toggleMobileSubMenu = false;
            }
        });
    }

    public onMenuClick(url: string): void {
        if (this.isUrlChanged && this.router.url === url) {
            this.isUrlChanged = false;
        }
    }

    private checkIfHome(): void {
        if (this.router.url !== '/') {
            this.isNotHome = true;
        }

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (this.router.url !== '/') {
                    this.isNotHome = true;
                    this.isUrlChanged = true;
                } else {
                    this.isNotHome = false;
                }
            }
        });
    }

    public onMobileSubmenuClick(activeSubmenu: number = 0): void {
        this.toggleMobileSubMenu = !this.toggleMobileSubMenu;
        if (this.toggleMobileSubMenu) {
            this.activeSubmenu = activeSubmenu;
        } else {
            this.activeSubmenu = -1;
        }
    }

    public goBack(): void {
        this.location.back();
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.isUrlChanged = true;
        const el = document.getElementById('logo');
        if (el) {
            const elemTop = el.getBoundingClientRect().top;
            const elemBottom = el.getBoundingClientRect().bottom;

            const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
            if (this.isNotHome) {
                if (!isVisible) {
                    this.logoNotVisible = true;
                } else {
                    this.logoNotVisible = false;
                }
            } else {
                this.logoNotVisible = false;
            }
        }
    }
}
