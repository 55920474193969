import { RoomTypeSlug } from '../constants/enums/room-type.enum';

export class RoomType {
    slug: string;
    text: string;
    price: number;
    priceCompare: number;
    discountPercentage: number | null;
    ordinal: number;

    quantity: number;

    public get total(): number {
        return this.price * this.quantity;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public deserialize(data: any): RoomType {
        this.slug = data.slug;
        this.priceCompare = +data.price_compare;
        this.price = +data.price;
        this.quantity = 0;

        this.ordinal = this.getOrdinal(data.slug);
        this.discountPercentage = this.getDiscountPercentage(this.price, this.priceCompare);
        this.text = this.getText(data.slug);

        return this;
    }

    private getOrdinal(slug: string): number {
        let ordinal = 99;
        switch (slug) {
            case RoomTypeSlug.ADULT_DOUBLE:
                ordinal = 1;
                break;
            case RoomTypeSlug.ADULT_SINGLE:
                ordinal = 2;
                break;
            case RoomTypeSlug.ADULT_TRIPLE:
                ordinal = 4;
                break;
            case RoomTypeSlug.CHILD_BED:
                ordinal = 5;
                break;
            case RoomTypeSlug.CHILD_NO_BED:
                ordinal = 3;
                break;
            case RoomTypeSlug.INFANT:
                ordinal = 6;
                break;
            case RoomTypeSlug.JOIN_LAND:
                ordinal = 7;
                break;
            default:
                break;
        }

        return ordinal;
    }

    private getDiscountPercentage(price: number, priceCompare: number): number | null {
        if (!priceCompare) {
            return null;
        }

        return Math.ceil(((priceCompare - price) / priceCompare) * 100);
    }

    private getText(slug: string): string | null {
        let text: string;
        switch (slug) {
            case RoomTypeSlug.ADULT_DOUBLE:
                text = 'ผู้ใหญ่ พักคู่';
                break;
            case RoomTypeSlug.ADULT_SINGLE:
                text = 'ผู้ใหญ่ พักเดี่ยว';
                break;
            case RoomTypeSlug.ADULT_TRIPLE:
                text = 'ผู้ใหญ่ พักสาม';
                break;
            case RoomTypeSlug.CHILD_BED:
                text = 'เด็กมีเตียง';
                break;
            case RoomTypeSlug.CHILD_NO_BED:
                text = 'เด็กไม่มีเตียง';
                break;
            case RoomTypeSlug.INFANT:
                text = 'ทารก';
                break;
            case RoomTypeSlug.JOIN_LAND:
                text = 'จอยแลนด์';
                break;
            default:
                break;
        }

        return text;
    }
}
