import { ISeoConfigResponse } from './interfaces/response/seo-config-response.interface';
import { SeoGoogleAnalytics } from './seo-google-analytics.model';

export class SeoConfigs {
    displayName: string;
    homepage: SeoTags;
    outboundPage: SeoTags;
    outboundPackagePage: SeoTags;
    domesticPage: SeoTags;
    voucherPage: SeoTags;
    googleAnalytics: SeoGoogleAnalytics;

    public deserialise(obj: ISeoConfigResponse): SeoConfigs {
        this.displayName = obj.agency_configs.display_name;
        this.homepage = new SeoTags(obj.homepage_title, obj.homepage_meta_description);
        this.outboundPage = new SeoTags(obj.outbound_page_title, obj.outbound_page_meta_description);
        this.outboundPackagePage = new SeoTags(
            obj.outbound_package_page_title,
            obj.outbound_package_page_meta_description
        );
        this.domesticPage = new SeoTags(obj.domestic_page_title, obj.domestic_page_meta_description);
        this.voucherPage = new SeoTags(obj.voucher_page_title, obj.voucher_page_meta_description);
        this.googleAnalytics = new SeoGoogleAnalytics(obj.google_analytics_id, obj.google_analytics_property_id);

        return this;
    }
}

class SeoTags {
    title: string;
    metaDescription: string;

    constructor(title: string, metaDescription: string) {
        this.title = title;
        this.metaDescription = metaDescription;
    }
}
