<div class="search-panel d-none d-lg-block" [class.active]="displayLocationList">
    <div class="search__title d-flex justify-content-between align-items-end px-3 pt-3 pb-3">
        <div class="font-weight-bold">ตัวช่วยค้นหา</div>
        <div class="search__reset" style="cursor: pointer" (click)="resetAllFilters()">
            <img src="/assets/images/ico-refresh.svg" width="18" class="mr-2" />
            <span>เริ่มใหม่</span>
        </div>
    </div>
    <div>
        <div class="search-panel_top">
            <div class="search__label p-3">{{ isDomestic ? 'จังหวัด' : 'ประเทศ' }}</div>
            <div class="menu-location-select px-3" [class.active]="displayLocationList" (click)="toggleLocationList()">
                <label>{{ isDomestic ? selectedProvinceName : selectedCountryName }}</label>
                <div class="menu-location-list" *ngIf="displayLocationList">
                    <div class="d-flex justify-content-between align-item-center py-2">
                        <div class="location-list-title">เลือก{{ isDomestic ? 'จังหวัด' : 'ประเทศ' }}</div>
                        <img src="/assets/images/ico-close.svg" width="8" class="mr-3" />
                    </div>

                    <ul *ngIf="isDomestic && provinces.length > 0" class="location-list">
                        <li [ngClass]="{ selected: currentProvinceId === null }" (click)="selectProvince(null)">
                            ทุกจังหวัด
                        </li>
                        <li
                            *ngFor="let province of provinces"
                            [ngClass]="{ selected: currentProvinceId === province.id }"
                            (click)="selectProvince(province)"
                        >
                            {{ province.nameTh }}
                        </li>
                    </ul>

                    <ul *ngIf="!isDomestic && countries.length > 0" class="location-list">
                        <li [ngClass]="{ selected: currentCountryId === null }" (click)="selectCountry(null)">
                            ทุกประเทศ
                        </li>
                        <li
                            *ngFor="let country of countries"
                            [ngClass]="{ selected: currentCountryId === country.id }"
                            (click)="selectCountry(country)"
                        >
                            {{ country.nameTh }}
                        </li>
                    </ul>
                </div>
            </div>
            <ng-container *ngIf="campaignTypeOptions?.length">
                <div class="search__label p-3">ประเภท</div>
                <div class="form-group search__price">
                    <ng-container *ngFor="let campaignTypeOption of campaignTypeOptions; let i = index">
                        <div class="custom-control custom-checkbox border-0" *ngIf="isShowCampaignTypeOption[i]">
                            <input
                                class="custom-control-input"
                                type="checkbox"
                                [checked]="campaignTypeOption.selected"
                            />
                            <label
                                class="custom-control-label"
                                (click)="toggleCampaignTypeOption(i); displayLocationList = false"
                            >
                                {{ campaignTypeOption.label }}
                            </label>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="priceRangeOptions?.length">
                <div class="search__label p-3">ช่วงราคา</div>
                <div class="form-group search__price">
                    <ng-container *ngFor="let priceRangeOption of priceRangeOptions; let i = index">
                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" type="checkbox" [checked]="priceRangeOption.selected" />
                            <label
                                class="custom-control-label"
                                (click)="togglePriceRangeOption(i); displayLocationList = false"
                            >
                                {{
                                    !priceRangeOption.minPrice
                                        ? 'ไม่เกิน'
                                        : (priceRangeOption.minPrice | number: '1.0-0')
                                }}
                                {{ !priceRangeOption.minPrice || !priceRangeOption.maxPrice ? ' ' : ' - ' }}
                                {{
                                    !priceRangeOption.maxPrice
                                        ? 'ขึ้นไป'
                                        : (priceRangeOption.maxPrice | number: '1.0-0')
                                }}
                            </label>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="discountRangeOptions?.length">
                <div class="search__label p-3">ส่วนลด</div>
                <div class="form-group search__price">
                    <ng-container *ngFor="let discountRangeOption of discountRangeOptions; let i = index">
                        <div class="custom-control custom-checkbox">
                            <input
                                class="custom-control-input"
                                type="checkbox"
                                [checked]="discountRangeOption.selected"
                            />
                            <label
                                class="custom-control-label"
                                (click)="toggleDiscountRangeOptions(i); displayLocationList = false"
                            >
                                {{ discountRangeOption.minDiscount + '%' }}
                                {{ !discountRangeOption.minDiscount || !discountRangeOption.maxDiscount ? ' ' : ' - ' }}
                                {{
                                    !discountRangeOption.maxDiscount ? 'ขึ้นไป' : discountRangeOption.maxDiscount + '%'
                                }}
                            </label>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="search-panel_bottom py-3" *ngIf="monthOptions?.length">
            <div class="search__label py-3 px-3">เดือน</div>
            <div class="form-group search__month">
                <div class="row no-gutters mb-4">
                    <ng-container *ngFor="let monthOption of monthOptions; let i = index">
                        <div class="col-lg-6 col-md-12">
                            <div class="custom-control custom-checkbox d-flex justify-content-start">
                                <input class="custom-control-input" type="checkbox" [checked]="monthOption.selected" />
                                <label
                                    class="custom-control-label"
                                    (click)="toggleMonthOption(i); displayLocationList = false"
                                >
                                    {{ monthOption.label }}
                                </label>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="search-panel-mobile d-lg-none">
    <div class="outside-container">
        <div class="inside-container">
            <div class="row top-row">
                <div class="col-1 back-button" (click)="onHideMobileFilter()">
                    <img src="/assets/images/icon-arrow-left-with-pointer.png" />
                </div>
                <div class="col-6 title">ตัวช่วยค้นหา</div>
                <div class="col-4 search-reset" (click)="resetAllFilters()">
                    <img src="/assets/images/ico-refresh.svg" class="mr-2" />
                    <span>เริ่มใหม่</span>
                </div>
            </div>
            <div class="location-container">
                <p class="title">{{ isDomestic ? 'จังหวัด' : 'ประเทศ' }}</p>
                <div (click)="displayMobileLocation = true" class="row selected-location selection-area">
                    <div class="col-9 my-auto">
                        <label>{{ isDomestic ? selectedProvinceName : selectedCountryName }}</label>
                    </div>
                    <div class="col-3">
                        <img src="/assets/images/ico-arrow-right.svg" />
                    </div>
                </div>
            </div>
            <ng-container *ngIf="campaignTypeOptions?.length">
                <div class="voucher-type-container">
                    <p class="title">ประเภท</p>
                    <div class="form-group search__price selection-area">
                        <ng-container *ngFor="let campaignTypeOption of campaignTypeOptions; let i = index">
                            <div class="custom-control custom-checkbox" *ngIf="isShowCampaignTypeOption[i]">
                                <input
                                    class="custom-control-input"
                                    type="checkbox"
                                    [checked]="campaignTypeOption.selected"
                                />
                                <label class="custom-control-label" (click)="toggleCampaignTypeOption(i)">
                                    {{ campaignTypeOption.label }}
                                </label>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <div class="price-container">
                <p class="title">ช่วงราคา</p>
                <div class="form-group search__price selection-area">
                    <ng-container *ngFor="let priceRangeOption of priceRangeOptions; let i = index">
                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" type="checkbox" [checked]="priceRangeOption.selected" />
                            <label class="custom-control-label" (click)="togglePriceRangeOption(i)">
                                {{ !priceRangeOption.minPrice ? 'ไม่เกิน' : (priceRangeOption.minPrice | number) }}
                                {{ !priceRangeOption.minPrice || !priceRangeOption.maxPrice ? ' ' : ' - ' }}
                                {{ !priceRangeOption.maxPrice ? 'ขึ้นไป' : (priceRangeOption.maxPrice | number) }}
                            </label>
                        </div>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="discountRangeOptions?.length">
                <div class="discount-container">
                    <p class="title">ส่วนลด</p>
                    <div class="form-group search__price selection-area">
                        <ng-container *ngFor="let discountRangeOption of discountRangeOptions; let i = index">
                            <div class="custom-control custom-checkbox">
                                <input
                                    class="custom-control-input"
                                    type="checkbox"
                                    [checked]="discountRangeOption.selected"
                                />
                                <label class="custom-control-label" (click)="toggleDiscountRangeOptions(i)">
                                    {{ discountRangeOption.minDiscount + '%' }}
                                    {{
                                        !discountRangeOption.minDiscount || !discountRangeOption.maxDiscount
                                            ? ' '
                                            : ' - '
                                    }}
                                    {{
                                        !discountRangeOption.maxDiscount
                                            ? 'ขึ้นไป'
                                            : discountRangeOption.maxDiscount + '%'
                                    }}
                                </label>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="monthOptions?.length">
                <div class="month-container">
                    <p class="title">เดือน</p>
                    <div class="form-group search__month selection-area">
                        <div class="row no-gutters mb-4">
                            <ng-container *ngFor="let monthOption of monthOptions; let i = index">
                                <div class="col-6">
                                    <div class="custom-control custom-checkbox d-flex justify-content-start">
                                        <input
                                            class="custom-control-input"
                                            type="checkbox"
                                            [checked]="monthOption.selected"
                                        />
                                        <label class="custom-control-label" (click)="toggleMonthOption(i)">
                                            {{ monthOption.label }}
                                        </label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="displayMobileLocation" class="outside-container location-list">
        <div class="top-row-location row">
            <div class="col-6">
                <p class="title">เลือก{{ isDomestic ? 'จังหวัด' : 'ประเทศ' }}</p>
            </div>
            <div class="col-6 link-wrapper">
                <a (click)="displayMobileLocation = false">ยกเลิก</a>
            </div>
        </div>
        <div class="inside-container">
            <ul *ngIf="isDomestic && provinces.length > 0" class="location-list">
                <li
                    [ngClass]="{ selected: currentProvinceId === null }"
                    (click)="selectProvince(null); displayMobileLocation = false"
                >
                    ทุกจังหวัด
                </li>
                <li
                    *ngFor="let province of provinces"
                    [ngClass]="{ selected: currentProvinceId === province.id }"
                    (click)="selectProvince(province); displayMobileLocation = false"
                >
                    {{ province.nameTh }}
                </li>
            </ul>

            <ul *ngIf="!isDomestic && countries.length > 0" class="location-list">
                <li
                    [ngClass]="{ selected: currentCountryId === null }"
                    (click)="selectCountry(null); displayMobileLocation = false"
                >
                    ทุกประเทศ
                </li>
                <li
                    *ngFor="let country of countries"
                    [ngClass]="{ selected: currentCountryId === country.id }"
                    (click)="selectCountry(country); displayMobileLocation = false"
                >
                    {{ country.nameTh }}
                </li>
            </ul>
        </div>
    </div>
    <div class="submit-search-container">
        <button (click)="onHideMobileFilter()" class="btn">แสดงผล {{ total }} {{ labelAfterTotal }}</button>
    </div>
</div>
<div
    *ngIf="displayLocationList || displayMobileLocation"
    class="backdrop n-none d-lg-block"
    (click)="displayLocationList = false; displayMobileLocation = false"
></div>
