<!-- paging -->
<div class="search__result-paging  d-flex  justify-content-center  justify-content-md-end">
    <nav>
        <ul class="pagination mt-3 my-3  mx-5">
            <li class="page-item">
                <a class="page-link" aria-label="Previous" (click)="changeCurrentPage(1)"
                    [class.disabled]="currentPage === 1">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
            <li class="page-item">
                <a class="page-link prev" aria-label="Previous" (click)="decrementPage()"
                    [class.disabled]="currentPage === 1">
                    <span aria-hidden="true">&lt;</span>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
            <ng-container *ngIf="hasPreviousSpacer">
                <li class="page-item" [ngClass]="{ 'active': currentPage === 1 }">
                    <a class="page-link" (click)="changeCurrentPage(1)">1</a>
                </li>
                <li class="page-item"><a class="page-link">...</a></li>
            </ng-container>
            <ng-container *ngFor="let page of pageList">
                <li class="page-item" [ngClass]="{ 'active': currentPage === page }">
                    <a class="page-link" (click)="changeCurrentPage(page)">{{ page }}</a>
                </li>
            </ng-container>
            <ng-container *ngIf="hasNextSpacer">
                <li class="page-item"><a class="page-link">...</a></li>
                <li class="page-item" [ngClass]="{ 'active': currentPage === totalPage }">
                    <a class="page-link" (click)="changeCurrentPage(totalPage)">{{ totalPage }}</a>
                </li>
            </ng-container>
            <li class="page-item">
                <a class="page-link next" aria-label="Next" (click)="incrementPage()"
                    [class.disabled]="currentPage === totalPage">
                    <span aria-hidden="true">&gt;</span>
                    <span class="sr-only">Next</span>
                </a>
            </li>
            <li class="page-item">
                <a class="page-link" aria-label="Next" (click)="changeCurrentPage(totalPage)"
                    [class.disabled]="currentPage === totalPage">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                </a>
            </li>
        </ul>
    </nav>
</div>