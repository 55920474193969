import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IProvince } from 'src/app/models/province.model';
import { ICountry } from 'src/app/models/country.model';
import { Location } from '@angular/common';
import { IMonthOption } from 'src/app/models/imonth-option.interface';
import { IPriceRangeOption } from 'src/app/models/iprice-range-option.interface';
import { ICampaignTypeOption } from 'src/app/models/icampaign-type-options.interface';
import { IDiscountRangeOption } from 'src/app/models/idiscount-range-options.interface';

import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { selectAgencyInfo } from 'src/app/stores/agency-store/agency.selectors';
import { AgencyProductCategory, AgencyInfo } from 'src/app/models/agency.model';

@Component({
    selector: 'app-search-panel',
    templateUrl: './search-panel.component.html',
    styleUrls: ['./search-panel.component.scss'],
})
export class SearchPanelComponent implements OnInit {
    @Input() isDomestic: boolean = true;
    @Input() disableUrlChange: boolean = false;

    @Input() provinces: IProvince[] = [];
    @Input() countries: ICountry[] = [];
    @Input() priceRangeOptions: IPriceRangeOption[] = [];
    @Input() campaignTypeOptions: ICampaignTypeOption[] = [];
    @Input() discountRangeOptions: IDiscountRangeOption[] = [];
    @Input() monthOptions: IMonthOption[] = [];
    @Input() selectedCountryName: string;
    @Input() selectedProvinceName: string;
    @Input() total: number;
    @Input() currentProvinceId: number;
    @Input() currentCountryId: number;
    @Input() labelAfterTotal: string = 'โปรแกรมทัวร์';

    @Output() provinceChange = new EventEmitter<IProvince | null>();
    @Output() countryChange = new EventEmitter<ICountry | null>();
    @Output() priceRangeOptionsChange = new EventEmitter<IPriceRangeOption[]>();
    @Output() campaignTypeOptionsChange = new EventEmitter<ICampaignTypeOption[]>();
    @Output() discountRangeOptionsChange = new EventEmitter<IDiscountRangeOption[]>();
    @Output() monthOptionsChange = new EventEmitter<IMonthOption[]>();
    @Output() hideMobileFilter = new EventEmitter<boolean>();

    @Output() filtersReset = new EventEmitter<void>();

    displayLocationList: boolean = false;
    displayMobileLocation: boolean = false;

    campaignCategory: AgencyProductCategory;
    isShowCampaignTypeOption: boolean[] = [];

    private agencyInfo: AgencyInfo;

    constructor(private location: Location, private store: Store<State>) {}

    ngOnInit(): void {
        this.store.pipe(select(selectAgencyInfo)).subscribe((data) => {
            this.agencyInfo = data;
            this.campaignCategory = this.agencyInfo?.productCategories.find((category) => category.slug === 'campaign');
            for (const itemFilters of this.campaignTypeOptions) {
                this.isShowCampaignTypeOption.push(
                    this.campaignCategory.productSubCategories.find(
                        (subCategory) => subCategory.slug === itemFilters.value
                    ).isAllowed
                );
            }
        });
    }

    resetAllFilters(): void {
        if (this.isDomestic) {
            this.selectProvince(null);
        } else {
            this.selectCountry(null);
        }

        this.priceRangeOptions.forEach((option) => (option.selected = false));
        this.monthOptions.forEach((option) => (option.selected = false));
        this.campaignTypeOptions.forEach((option) => (option.selected = false));
        this.discountRangeOptions.forEach((option) => (option.selected = false));
        this.filtersReset.emit();
    }

    selectProvince(province?: IProvince): void {
        if (province === null && this.currentProvinceId !== null) {
            this.currentProvinceId = null;
            this.provinceChange.emit(null);
        } else if (province && this.currentProvinceId !== province.id) {
            this.currentProvinceId = province.id;
            this.provinceChange.emit(province);
        }
        this.getSelectedProvinceName();
    }

    selectCountry(country?: ICountry): void {
        if (country === null && this.currentCountryId !== null) {
            this.currentCountryId = null;
            this.countryChange.emit(null);
        } else if (country && this.currentCountryId !== country.id) {
            this.currentCountryId = country.id;
            this.countryChange.emit(country);
        }
        this.getSelectedCountryName();
    }

    toggleLocationList(): void {
        this.displayLocationList = !this.displayLocationList;
    }

    toggleCampaignTypeOption(index: number): void {
        this.campaignTypeOptions[index].selected = !this.campaignTypeOptions[index].selected;
        this.campaignTypeOptionsChange.emit(this.campaignTypeOptions);
    }

    togglePriceRangeOption(index: number): void {
        this.priceRangeOptions[index].selected = !this.priceRangeOptions[index].selected;
        this.priceRangeOptionsChange.emit(this.priceRangeOptions);
    }

    toggleDiscountRangeOptions(index: number): void {
        this.discountRangeOptions[index].selected = !this.discountRangeOptions[index].selected;
        this.discountRangeOptionsChange.emit(this.discountRangeOptions);
    }

    toggleMonthOption(index: number): void {
        this.monthOptions[index].selected = !this.monthOptions[index].selected;
        this.monthOptionsChange.emit(this.monthOptions);
    }

    private getSelectedCountryName(): void {
        if (this.currentCountryId) {
            const country: ICountry = this.countries.find((country) => country.id === this.currentCountryId);
            this.selectedCountryName = country.nameTh;
            if (!this.disableUrlChange) {
                this.location.go('/' + country.slug + '-tour');
            }
        } else {
            this.selectedCountryName = 'ทุกประเทศ';
            if (!this.disableUrlChange) {
                this.location.go('/outbound-tour');
            }
        }
    }

    private getSelectedProvinceName(): void {
        if (this.currentProvinceId) {
            const province: IProvince = this.provinces.find((province) => province.id === this.currentProvinceId);
            this.selectedProvinceName = province.nameTh;
            if (!this.disableUrlChange) {
                this.location.go('/' + province.slug + '-tour');
            }
        } else {
            this.selectedProvinceName = 'ทุกจังหวัด';
            if (!this.disableUrlChange) {
                this.location.go('/thailand-tour');
            }
        }
    }

    onHideMobileFilter(): void {
        this.hideMobileFilter.emit(true);
    }
}
