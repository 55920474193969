import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCountry from './country-store.module';
import * as state from './country.reducer';

export const selectCountryState = createFeatureSelector<state.CountryState>(fromCountry.FEATURE_NAME);

export const selectActiveCountries = createSelector(selectCountryState, (state: state.CountryState) => state.countries);

export const selectAllCountry = createSelector(selectCountryState, (state: state.CountryState) => state.allCountry);

export const selectCountriesRecommend = createSelector(
    selectCountryState,
    (state: state.CountryState) => state.countriesRecommend
);
