import { Action, createReducer, on, State } from '@ngrx/store';
import { Portfolio, PortfolioDescription } from 'src/app/models/portfolio.model';
import * as PortfolioActions from './portfolio.actions';

export interface PortfolioState {
    portfolioDetail: Portfolio;
    portfolioDetailErrorMessage: string;

    portfolioPaginatedListing: PortfolioDescription[];
    portfolioPaginatedListingErrorMessage: string;
}

export const initialState: PortfolioState = {
    portfolioDetail: null,
    portfolioDetailErrorMessage: '',
    portfolioPaginatedListing: null,
    portfolioPaginatedListingErrorMessage: '',
};

export const portfolioReducer = createReducer(
    initialState,

    on(PortfolioActions.loadPortfolioDetail, (state, action) => {
        return {
            ...state,
            portfolioDetail: null,
            portfolioDetailErrorMessage: '',
        };
    }),
    on(PortfolioActions.loadPortfolioDetailSuccess, (state, action) => {
        return {
            ...state,
            portfolioDetail: action.data,
            portfolioDetailErrorMessage: '',
        };
    }),
    on(PortfolioActions.loadPortfolioDetailFailure, (state, action) => {
        return {
            ...state,
            portfolioDetail: null,
            portfolioDetailErrorMessage: action.errorMessage,
        };
    }),

    on(PortfolioActions.loadPortfolioPaginatedListing, (state, action) => {
        return {
            ...state,
            portfolioPaginatedListing: null,
            portfolioPaginatedListingErrorMessage: '',
        };
    }),
    on(PortfolioActions.loadPortfolioPaginatedListingSuccess, (state, action) => {
        return {
            ...state,
            portfolioPaginatedListing: action.data,
            portfolioPaginatedListingErrorMessage: '',
        };
    }),
    on(PortfolioActions.loadPortfolioPaginatedListingFailure, (state, action) => {
        return {
            ...state,
            portfolioPaginatedListing: null,
            portfolioPaginatedListingErrorMessage: action.errorMessage,
        };
    })
);

export function reducer(state: PortfolioState | undefined, action: Action) {
    return portfolioReducer(state, action);
}
