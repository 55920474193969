<div class="submenu__frame d-flex p-3">
    <ng-container *ngFor="let region of provinceRegions">
        <div class="submenu__col list_unstyled">
            <div class="submenu__head">ทัวร์{{ region.name }}</div>
            <ul class="submenu__list list-unstyled">
                <ng-container *ngFor="let province of region?.provinces">
                    <li class="">
                        <a (click)="linkClick.next()" [routerLink]="'/thailand-tour/' + province.slug + '-tour'"
                            >ทัวร์{{ province.nameTh }}</a
                        >
                    </li>
                </ng-container>
            </ul>
        </div>
    </ng-container>
</div>
