import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './template.reducer';
import { TemplateEffects } from './template.effects';

export const FEATURE_NAME = 'template';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([TemplateEffects])],
    providers: [TemplateEffects],
})
export class TemplateStoreModule {}
