import { Component, OnInit, Input } from '@angular/core';
import { ImageFile } from 'src/app/models/product/campaign/image-file.model';

@Component({
    selector: 'app-slide-top',
    templateUrl: '../slide-top/slide-top.component.html',
    styleUrls: ['../slide-top/slide-top-desktop.component.scss', '../slide-top/slide-top-mobile.component.scss'],
})
export class SlideTopComponent implements OnInit {
    @Input() bannerMainList: ImageFile[] = [];

    public bannerMainLeftSrc: string = '';
    public bannerMainRightSrc: string = '';

    public bannerMainIndex: number = 0;
    public bannerMainLeftIndex: number = null;
    public bannerMainRightIndex: number = null;

    public previousIndex = 0;

    constructor() {}

    ngOnInit(): void {
        this.bannerMainInit();
    }

    public bannerMainInit(): void {
        if (this.bannerMainList.length > 0) {
            this.onSelectThumbnailBannerMain(this.bannerMainList.findIndex((i) => i.isMain));
        }
    }

    public onSelectThumbnailBannerMain(index: number): void {
        this.previousIndex = this.bannerMainIndex;
        this.bannerMainIndex = index;
        this.changeSlideBannerMainLeftAndRight(index);
    }

    public changeSlideBannerMainLeftAndRight(index: number): void {
        if (this.bannerMainList.length > 0) {
            if (this.bannerMainList.length == 1) {
                this.bannerMainLeftIndex = null;
                this.bannerMainRightIndex = null;
            } else if (this.bannerMainList.length == 2) {
                this.bannerMainLeftIndex = null;
                this.bannerMainRightIndex = index + 1;
            } else {
                if (index == 0) {
                    this.bannerMainLeftIndex = this.bannerMainList.length - 1;
                    this.bannerMainRightIndex = index + 1;
                } else if (index == this.bannerMainList.length - 1) {
                    this.bannerMainLeftIndex = index - 1;
                    this.bannerMainRightIndex = 0;
                } else {
                    this.bannerMainLeftIndex = index - 1;
                    this.bannerMainRightIndex = index + 1;
                }
            }
        }
    }
}
