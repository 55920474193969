import { ImageFile } from './image-file.model';
import * as moment from 'moment';
export class OrderDetail {
    id: number;
    orderCode: string;
    voucherQuantity: number;
    customer: Customer;
    payment: Payment;
    campaign: Campaign;

    public deserialize(data: any): this {
        this.id = data.id;
        this.orderCode = data.order_code;
        this.voucherQuantity = data.voucher_quantity;
        this.customer = new Customer().deserialize(data.customer);
        this.payment = new Payment().deserialize(data.payment);
        this.campaign = new Campaign().deserialize(data.campaign);
        return this;
    }
}

export class Customer {
    contactName: string;
    phoneNumber: string;
    email: string;
    customerRemark: string;

    public deserialize(data: any): this {
        this.contactName = data.contact_name;
        this.phoneNumber = data.phone_number;
        this.email = data.email;
        this.customerRemark = data.customer_remark;
        return this;
    }
}

export class Payment {
    paymentMethod: string;
    paymentStatus: string;
    sumTotal: number;
    fee: number;
    paidAt: Date;
    usageDate: Date;
    serviceProviderRemark: string;
    detailPdfUrl: string;

    public deserialize(data: any): this {
        this.paymentMethod = data.payment_method;
        this.paymentStatus = data.payment_status;
        this.sumTotal = +data.sum_total;
        this.fee = +data.fee;
        this.paidAt = data.paid_at ? moment(data.paid_at, 'YYYY-MM-DD HH:mm:ss').toDate() : null;
        this.usageDate = data.usage_date ? moment(data.usage_date, 'YYYY-MM-DD HH:mm:ss').toDate() : null;
        this.serviceProviderRemark = data.service_provider_remark;
        this.detailPdfUrl = data.detail_pdf_url;
        return this;
    }
}

export class Campaign {
    id: number;
    name: string;
    allowToIssueTaxInvoice: boolean;

    shopName: string;
    shopPhoneNumber: string;
    shopPhoneNumberAddition: string;

    googleMapLocationLatitude: string;
    googleMapLacationLongitude: string;
    address: string;
    voucherCondition: string;
    voucher: Voucher;
    voucherTickets: VoucherTicket[];

    public deserialize(data: any): this {
        this.id = data.id;
        this.name = data.name;
        this.allowToIssueTaxInvoice = data.allow_to_issue_tax_invoice;

        this.shopName = data.shop_name;
        this.shopPhoneNumber = data.office_telephone_number;
        this.shopPhoneNumberAddition = data.additional_phone_number;

        this.googleMapLocationLatitude = data.google_map_location_latitude;
        this.googleMapLacationLongitude = data.google_map_location_longitude;
        this.address = data.address;
        this.voucherCondition = data.voucher_condition;
        this.voucher = new Voucher().deserialize(data.voucher);
        this.voucherTickets = data.voucher_tickets.map((m) => new VoucherTicket().deserialize(m));

        return this;
    }
}

export class Voucher {
    id: number;
    name: string;
    mainBannerImageFileUrl: ImageFile;
    voucherEndDate: Date;

    description: string;
    bannerImageFilesURL: ImageFile[];

    public deserialize(data: any): this {
        this.id = data.id;
        this.name = data.name;
        this.mainBannerImageFileUrl = data.main_banner_image_file_url;
        this.voucherEndDate = data.voucher_end_date ? new Date(data.voucher_end_date) : null;

        this.description = data.description;
        this.bannerImageFilesURL = (data.banner_image_files as any[]).map((x) => {
            return new ImageFile().deserialize(x);
        });

        return this;
    }
}

export class VoucherTicket {
    id: number;
    ticketCode: string;
    status: string;
    usedAt: Date;
    isExpired: boolean;

    public deserialize(data: any): this {
        this.id = data.id;
        this.ticketCode = data.ticket_code;
        this.status = data.status;
        this.usedAt = data.used_at ? new Date(data.used_at) : null;
        this.isExpired = data.is_expired;
        return this;
    }
}
