<nav aria-label="breadcrumb" class="breadcrumb-container">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a [routerLink]="['/']">หน้าแรก</a>
            <span class="span-arrow-right"> > </span>
        </li>
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
            <li class="breadcrumb-item active">
                <span *ngIf="breadcrumb.active">
                    <a [routerLink]="[breadcrumb.link]">{{ breadcrumb.text }}</a>
                </span>
                <span *ngIf="!breadcrumb.active">
                    {{ breadcrumb.text }}
                </span>
                <span *ngIf="i !== breadcrumbs.length - 1" class="span-arrow-right"> > </span>
            </li>
        </ng-container>
    </ol>
</nav>
