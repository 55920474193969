<footer id="footer-a" class="pt-5" [ngStyle]="{ 'background-color': (themeConfig$ | async)?.mainColor }">
    <div class="container">
        <div
            class="footer__link row flex-sm-column flex-md-row justify-content-center"
            [ngStyle]="{ color: (themeConfig$ | async)?.agencyNameColor }"
        >
            <div class="col-12 col-lg-6">
                <div class="row">
                    <div class="contact-link col-12 col-lg-6">
                        <ul class="list-unstyled">
                            <li>
                                <a class="text-bold" [routerLink]="['/']">หน้าแรก</a>
                            </li>
                            <li *ngIf="showBlog">
                                <a class="text-bold" [routerLink]="['/blog']">บทความ</a>
                            </li>
                            <li *ngIf="showPortfolio">
                                <a class="text-bold" [routerLink]="['/gallery']">ผลงานที่ผ่านมา</a>
                            </li>
                            <li>
                                <a class="text-bold" [routerLink]="['/', 'about-us']">เกี่ยวกับเรา</a>
                            </li>
                        </ul>
                    </div>
                    <div class="tour-link col-6">
                        <ul class="list-unstyled">
                            <li>
                                <a class="text-bold" [routerLink]="['/', 'outbound-tour']">ทัวร์ต่างประเทศ</a>
                            </li>
                            <li>
                                <a class="text-bold" [routerLink]="['/', 'thailand-tour']">ทัวร์ในประเทศ</a>
                            </li>
                            <li>
                                <a class="text-bold" [routerLink]="['/', 'outbound-tour', 'package']"
                                    >แพ็กเกจทัวร์ต่างประเทศ</a
                                >
                            </li>
                            <ng-container *ngFor="let customContent of (agencyInfo$ | async)?.customContents">
                                <li *ngIf="customContent.isActive">
                                    <a class="text-bold" [routerLink]="['/', customContent.menuNameTh]">
                                        {{ customContent.menuNameTh }}
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 d-flex justify-content-between" *ngIf="agencyInfo$ | async as agencyInfo">
                <div class="company_profile">
                    <div class="company_name mb-2 font-weight-bold">
                        {{ agencyInfo.displayName }}
                    </div>
                    <div class="company_cert">เลขที่ใบอนุญาต {{ agencyInfo.licenseNumber }}</div>
                    <div class="company_contact mt-3">
                        <a
                            href="tel:{{ agencyInfo.officeTelephoneNumber }}"
                            [ngClass]="{ 'mb-1': agencyInfo.additionalContactInfo }"
                        >
                            <img
                                src="/assets/images/ico-call-w-i.svg"
                                width="20"
                                height="20"
                                *ngIf="agencyInfo.officeTelephoneNumber"
                            />
                            {{ agencyInfo.officeTelephoneNumber | phoneNumber }}
                        </a>
                        <div *ngIf="agencyInfo.additionalContactInfo" class="mb-2 additional-contact-info">
                            <span>{{ agencyInfo.additionalContactInfo }}</span>
                        </div>
                        <a href="mailto:{{ agencyInfo.email }}">
                            <img
                                src="/assets/images/ico-mail-w-i.svg"
                                width="20"
                                height="20"
                                *ngIf="agencyInfo.email"
                            />
                            {{ agencyInfo.email }}
                        </a>
                        <a
                            [attr.href]="agencyInfo.facebookUrl || null"
                            [attr.target]="agencyInfo.facebookUrl ? '_blank' : null"
                        >
                            <img src="/assets/images/ico-facebook-w.svg" width="20" height="20" />
                            {{ agencyInfo.facebookName || '-' }}
                        </a>
                        <a
                            [attr.href]="agencyInfo.lineUrl || null"
                            [attr.target]="agencyInfo.lineUrl ? '_blank' : null"
                            [class.disabled]="!agencyInfo.lineId"
                        >
                            <img src="/assets/images/ico-line-w-s.svg" width="20" height="20" />
                            {{ agencyInfo.lineId || '-' }}
                        </a>
                    </div>
                </div>
                <div class="company_contact-ico position-relative">
                    <div class="d-inline-flex flex-wrap">
                        <a
                            [attr.href]="agencyInfo.facebookUrl || null"
                            [attr.target]="agencyInfo.facebookUrl ? '_blank' : null"
                            class="contact-us d-block mr-2 mb-2"
                        >
                            <img src="/assets/images/ico-facebook-w.svg" width="45" height="45"
                        /></a>
                        <a (click)="lineGetModal()" class="contact-us d-block">
                            <img src="/assets/images/ico-line-w.svg" width="45" height="45"
                        /></a>
                        <a
                            *ngIf="agencyInfo.email"
                            href="mailto:{{ agencyInfo.email }}"
                            class="contact-us d-block mr-2 mb-2"
                            ><img src="/assets/images/ico-mail-w.svg" width="45" height="45"
                        /></a>
                        <a (click)="phoneGetModal()" class="contact-us d-block">
                            <img src="/assets/images/ico-call-w.svg" width="45" height="45"
                        /></a>
                        <img
                            class="line-qr-img mb-2"
                            *ngIf="agencyInfo.lineQrUrl"
                            [src]="agencyInfo.lineQrUrl"
                            width="97"
                            height="97"
                        />
                        <a
                            class="d-block"
                            *ngIf="agencyInfo.dbdUrl"
                            [attr.href]="agencyInfo.dbdUrl"
                            [attr.target]="agencyInfo.facebookUrl ? '_blank' : null"
                        >
                            <img
                                class="dbd-verified-img"
                                src="assets/images/dbd-verified.png"
                                width="97"
                                height="auto"
                            />
                        </a>
                    </div>
                    <div
                        class="popup popup-line dropdown-menu"
                        [class.show]="showLinePopup"
                        [class.no-line-id]="!agencyInfo.lineId"
                        [class.no-line-qr]="!agencyInfo.lineQrUrl"
                    >
                        <div class="txt_id-title" *ngIf="agencyInfo.lineId">ไอดีไลน์</div>
                        <div class="txt_id-popover" *ngIf="agencyInfo.lineId">{{ agencyInfo.lineId }}</div>
                        <a
                            [attr.href]="agencyInfo.lineUrl || null"
                            [attr.target]="agencyInfo.lineUrl ? '_blank' : null"
                            class="d-block h-auto"
                        >
                            <img *ngIf="agencyInfo.lineQrUrl" [src]="agencyInfo.lineQrUrl" width="130" height="130" />
                        </a>
                    </div>
                    <div class="popup popup-call dropdown-menu" [class.show]="showPhonePopup">
                        <a href="tel:{{ agencyInfo.officeTelephoneNumber }}" class="d-block h-auto">
                            <div class="txt_id-title">เบอร์โทรติดต่อ</div>
                            <div class="txt_id-popover">{{ agencyInfo.officeTelephoneNumber | phoneNumber }}</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="footer-bottom text-white py-4 mt-3 text-center"
        [class.extra-margin]="isShowActionBoxBelowFooter()"
        [ngStyle]="{ 'background-color': (themeConfig$ | async)?.secondaryColor }"
    >
        <img class="mr-3" src="/assets/images/logo-powered-by-twm.png" width="auto" height="50" />
        <img class="ml-3" src="/assets/images/logo-twp-service.png" width="auto" height="50" />
    </div>
    <div
        *ngIf="showLinePopup || showPhonePopup"
        (click)="showLinePopup = false; showPhonePopup = false"
        class="footer-backdrop"
    ></div>
</footer>
