import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { State } from 'src/app/stores/reducers';
import { selectActiveCountries } from 'src/app/stores/country-store/country.selectors';
import { ICountryContinents } from 'src/app/models/country.model';

@Component({
    selector: 'app-countries-submenu',
    templateUrl: './countries-submenu.component.html',
    styleUrls: ['./countries-submenu.component.scss'],
})
export class CountriesSubmenuComponent implements OnInit, OnDestroy {
    @Output() linkClick = new EventEmitter();

    private unsubscribe$: Subject<void> = new Subject<void>();
    countryContinents$: Observable<ICountryContinents>;

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.loadActiveCountries();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private loadActiveCountries(): void {
        this.countryContinents$ = this.store.pipe(select(selectActiveCountries), takeUntil(this.unsubscribe$));
    }
}
