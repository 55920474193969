import { DateHelper } from 'src/app/helpers/date.helper';
import { ImageFile } from './image-file.model';

export class VoucherListItem {
    public id: number;
    public name: string;
    public price: number;
    public priceCompare: number;
    public maxQuantity: number;
    public quantitySold: number;
    public quantityRemaining: number;
    public voucherStartDate: Date;
    public voucherEndDate: Date;
    public bannerImageFiles: ImageFile[];

    public bannerMainImageFileUrl: string;
    public description: string;
    public isFreeVoucher: boolean;

    public deserialize(data: any): this {
        this.id = data.id;
        this.name = data.name;
        this.price = data.price;
        this.priceCompare = data.price_compare;
        this.maxQuantity = data.max_quantity;
        this.quantitySold = data.quantity_sold;
        this.quantityRemaining = data.quantity_remaining;
        this.voucherStartDate = DateHelper.convertStringToDate(data.voucher_start_date);
        this.voucherEndDate = DateHelper.convertStringToDate(data.voucher_end_date);
        this.bannerImageFiles = (data.banner_image_files as any[]).map((x) => new ImageFile().deserialize(x));

        this.bannerMainImageFileUrl = this.bannerImageFiles.find((x) => x.isMain)?.url || null;
        this.description = data.description;
        this.isFreeVoucher = data.is_free;

        return this;
    }
}
