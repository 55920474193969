import { UrlHelper } from '../helpers/url.helper';

export interface IPortfolio {
    id: number;
    title: string;
    description: IPortfolioDescription[];
}

export interface IPortfolioDescription {
    id: number;
    title?: string;
    description: string;
    imageUrl: string;
    imagePreviewUrl: string;
    isImageType: boolean;
    friendlyUrl: string;
    total?: number;
}

export class Portfolio implements IPortfolio {
    id: number;
    title: string;
    description: IPortfolioDescription[];

    public deserialize(data: any): this {
        this.id = data.id;
        this.title = data.title;
        this.description = data.portfolio_descriptions.map((p) => new PortfolioDescription().deserialize(p));

        return this;
    }
}

export class PortfolioDescription implements IPortfolioDescription {
    id: number;
    title: string;
    description: string;
    imageUrl: string;
    imagePreviewUrl: string;
    isImageType: boolean;
    friendlyUrl: string;
    total: number;

    public deserialize(data: any, total?: number): this {
        this.id = data.id;
        this.friendlyUrl = UrlHelper.getFriendlyUrlForPortfolio(this.id, data.title);
        this.isImageType = data.type_slug && data.type_slug === 'image';
        if (this.isImageType) {
            // for portfolio detail (slug = image)
            this.description = data.image_description;
            this.imageUrl = data.image_url;
        } else if (data.image_thumbnail_url) {
            // for paginated listing
            this.title = data.title;
            this.description = data.description;
            this.imagePreviewUrl = data.image_preview_url;
            this.imageUrl = data.image_thumbnail_url;
            this.total = total;
        } else {
            // for portfolio detail (slug = text)
            this.description = data.description;
        }

        return this;
    }
}
