import { UrlHelper } from 'src/app/helpers/url.helper';

export interface IBlogListing {
    page: number;
    pageEntries: number;
    total: number;
    blogsList: IBlog[];
}

export interface IBlog {
    id: number;
    title: string;
    description: string;
    imageSrcPreview: string;
    imageSrcThumbnail: string;
    relativeUrl: string;
}

export interface IBlogDetail {
    id: number;
    title: string;
    tags: string[];
    descriptions: IBlogDescription[];
}

export interface IBlogDescription {
    id: number;
    typeSlug: 'image' | 'text';
    ordinal: number;
    description?: string;
    imageSrc?: string;
    imageDescription?: string;
}

export class BlogModel {
    public deserialise(obj: any): IBlogListing {
        const blogListing: IBlogListing = {
            page: obj.page,
            pageEntries: obj.page_entries,
            total: obj.total,
            blogsList: [],
        };

        obj.result.map((blogItem: any) => {
            const tempBlog: IBlog = {
                id: blogItem.id,
                title: blogItem.title,
                description: this.getDescription(blogItem.main_description),
                imageSrcPreview: blogItem.main_image_preview_url,
                imageSrcThumbnail: blogItem.main_image_thumbnail_url,
                relativeUrl: null,
            };
            tempBlog.relativeUrl = UrlHelper.getFriendlyUrlForBlog(tempBlog.id, tempBlog.title);
            blogListing.blogsList.push(tempBlog);
        });

        return blogListing;
    }

    private getDescription(description: string): string {
        let descriptionString = '';
        if (description) {
            descriptionString += description.substring(0, 200) + '... ';
        }
        return descriptionString;
    }

    public deserialiseDetail(obj: any): IBlogDetail {
        const blogDetail: IBlogDetail = {
            id: obj.id,
            title: obj.title,
            tags: obj.tags,
            descriptions: [],
        };

        obj.blog_descriptions.map((description: any) => {
            const tempDescription: IBlogDescription = {
                id: description.id,
                typeSlug: description.type_slug,
                ordinal: description.ordinal,
                description: description.description || null,
                imageSrc: description.image_url || null,
                imageDescription: description.image_description || null,
            };
            blogDetail.descriptions.push(tempDescription);
        });

        return blogDetail;
    }
}
