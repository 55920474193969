import { IMonthRange } from 'src/app/models/month.model';

export interface MonthState {
    activeMonthRange: IMonthRange;
    error: any;
}

export const initialState: MonthState = {
    activeMonthRange: null,
    error: null,
};
