import { Action, createReducer, on, State } from '@ngrx/store';
import { CampaignListItem } from 'src/app/models/product/campaign/campaign-list-item.model';
import { IPaginationResponse } from 'src/app/models/response/pagination-response';
import * as CampaignActions from './campaign.actions';
import { CampaignDetail } from 'src/app/models/product/campaign/campaign-detail.model';
import { OrderDetail } from 'src/app/models/product/campaign/order-detail.model';
import { CampaignOrderResponse } from 'src/app/modules/products/campaign/models/campaign-order.model';
import { CampaignChaophrayaCruiseShop } from 'src/app/models/product/campaign/campaign-chaophraya-cruise-shop.model';

export interface CampaignState {
    paginatedCampaigns: IPaginationResponse<CampaignListItem[]>;
    campaignDetail: CampaignDetail;
    orderDetail: OrderDetail;
    orderCreation: CampaignOrderResponse;
    campaignChaophrayaCruiseShops: CampaignChaophrayaCruiseShop[];
}

export const initialState: CampaignState = {
    paginatedCampaigns: null,
    campaignDetail: null,
    orderDetail: null,
    orderCreation: null,
    campaignChaophrayaCruiseShops: null,
};

export const campaignReducer = createReducer(
    initialState,

    on(CampaignActions.getPaginatedCampaigns, (state) => state),
    on(CampaignActions.getPaginatedCampaignsSuccess, (state, action) => ({
        ...state,
        paginatedCampaigns: action.paginatedCampaigns,
    })),
    on(CampaignActions.getPaginatedCampaignsFailure, (state) => state),

    on(CampaignActions.getCampaignDetail, (state) => state),
    on(CampaignActions.getCampaignDetailSuccess, (state, action) => ({
        ...state,
        campaignDetail: action.campaign,
    })),
    on(CampaignActions.getCampaignDetailFailure, (state) => state),

    //
    on(CampaignActions.createCampaignOrder, (state) => state),
    on(CampaignActions.createCampaignOrderSuccess, (state, action) => ({
        ...state,
        orderCreation: action.response,
    })),
    on(CampaignActions.createCampaignOrderOutOfStockFailure, (state, action) => ({
        ...state,
        orderCreation: action.response,
    })),
    on(CampaignActions.createCampaignOrderAlreadyOrderFailure, (state, action) => ({
        ...state,
        orderCreation: action.response,
    })),
    on(CampaignActions.createCampaignOrderReset, (state) => ({
        ...state,
        orderCreation: null,
    })),

    //
    on(CampaignActions.getOrderDetail, (state) => state),
    on(CampaignActions.getOrderDetailSuccess, (state, action) => ({
        ...state,
        orderDetail: action.order,
    })),
    on(CampaignActions.getOrderDetailFailure, (state) => state),

    //
    on(CampaignActions.getChaophrayaCruiseUpdate, (state) => state),
    on(CampaignActions.getChaophrayaCruiseUpdateSuccess, (state, action) => ({
        ...state,
        campaignChaophrayaCruiseShops: action.data,
    })),
    on(CampaignActions.getChaophrayaCruiseUpdateFailure, (state) => state)
);

export function reducer(state: CampaignState | undefined, action: Action) {
    return campaignReducer(state, action);
}
