import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PortfolioService } from 'src/app/services/portfolio.service';
import * as PortfolioAction from './portfolio.actions';
import { PortfolioDescription, Portfolio } from 'src/app/models/portfolio.model';

@Injectable()
export class PortfolioEffects {
    constructor(private actions$: Actions, private portfolioService: PortfolioService) {}

    getDetail = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(PortfolioAction.loadPortfolioDetail),
                switchMap((action) => {
                    return this.portfolioService.getDetail(action.subDomain, action.portfolioId).pipe(
                        map((response) => {
                            if (response.status === 'success') {
                                const portfolioDetail = new Portfolio().deserialize(response.data);
                                return PortfolioAction.loadPortfolioDetailSuccess({ data: portfolioDetail });
                            } else {
                                throw new Error(`${response.error.code} ${response.error.type}`);
                            }
                        }),
                        catchError((error) => {
                            return of(PortfolioAction.loadPortfolioDetailFailure({ errorMessage: error }));
                        })
                    );
                })
            )
    );

    getPaginatedListing = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(PortfolioAction.loadPortfolioPaginatedListing),
                switchMap((action) => {
                    return this.portfolioService.getPaginatedListing(action.subDomain, action.criteria || null).pipe(
                        map((response) => {
                            if (response.status === 'success') {
                                const portfolioListing = response.data.result.map((x) =>
                                    new PortfolioDescription().deserialize(x, response.data.total)
                                );
                                return PortfolioAction.loadPortfolioPaginatedListingSuccess({ data: portfolioListing });
                            } else {
                                throw new Error(`${response.error.code} ${response.error.type}`);
                            }
                        }),
                        catchError((error) => {
                            return of(PortfolioAction.loadPortfolioPaginatedListingFailure({ errorMessage: error }));
                        })
                    );
                })
            )
    );
}
