import { CampaignTypeSlug } from 'src/app/constants/enums/campaign.enum';

export class CampaignListItem {
    public id: number;
    public name: string;
    public typeSlug: CampaignTypeSlug;
    public mainBannerImageFileUrl: string;
    public locationName: string;
    public price: number;
    public priceCompare: number;
    public discountPercentage: number;
    public voucherQuantitySold: number;
    public reviewCount: number;
    public starRating: number;
    public province: CampaignProvince;

    public deserialize(data: any): this {
        this.id = data.id;
        this.name = data.name;
        this.typeSlug = data.type_slug;
        this.mainBannerImageFileUrl = data.main_banner_image_file_url;
        this.locationName = data.location_name;
        this.price = data.price;
        this.priceCompare = data.price_compare;
        this.discountPercentage = data.discount_percentage;
        this.voucherQuantitySold = data.voucher_quantity_sold;
        this.reviewCount = data.review_count;
        this.starRating = data.star_rating;
        this.province = data.province ? new CampaignProvince().deserialize(data.province) : null;
        return this;
    }
}

export class CampaignProvince {
    public id: number;
    public nameEn: string;
    public nameTh: string;
    public countryId: number;

    public deserialize(data: any): this {
        this.id = data.id;
        this.nameEn = data.name_en;
        this.nameTh = data.name_th;
        this.countryId = data.country_id;
        return this;
    }
}
