import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENDPOINTS } from '../constants/endpoint.constant';
import { environment } from 'src/environments/environment';

import { ProductListingQuery } from '../models/request/queries/product-listing-query';
import { IBookingTour } from '../models/booking-tour.interface';

import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class ProductService extends BaseHttpService {
    getProductList(subDomain: string, displayExpiredProducts: boolean, query: ProductListingQuery): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.PRODUCT.LISTING.replace('{subdomain}', subDomain) +
            '?' +
            query.getQueryString(displayExpiredProducts);

        return this.get(url);
    }

    getProductDetail(subDomain: string, keyItem: string): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.PRODUCT.DETAIL.replace('{subdomain}', subDomain).replace('{key}', keyItem);

        return this.get(url);
    }

    getHighlightedShowcases(subDomain: string): Observable<any> {
        const url = environment.apiMallUrl + ENDPOINTS.PRODUCT.HIGHLIGHTED_SHOWCASES.replace('{subdomain}', subDomain);

        return this.get(url);
    }

    getPdfDetailFile(subDomain: string, poolKey: string): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.PRODUCT.PDF_DETAIL_FILE.replace('{subdomain}', subDomain).replace('{key}', poolKey);

        return this.get(url);
    }

    createProgramOrder(subDomain: string, poolKey: string, data: IBookingTour): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.PRODUCT.CREATE_ORDER.replace('{subdomain}', subDomain).replace('{pool_key}', poolKey);
        return this.post(url, data);
    }
}
