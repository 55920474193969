import { IBanner } from './../../models/banner.model';
import { Action, createReducer, on, State } from '@ngrx/store';
import * as BannerActions from './banner.actions';

export interface BannerState {
    bannerConfig: IBanner[];
    errorMessage: string | null;
}

export const initialState: BannerState = {
    bannerConfig: [],
    errorMessage: null,
};

export const bannerReducer = createReducer(
    initialState,

    on(BannerActions.loadBannerConfig, (state, action) => {
        return {
            ...state,
        };
    }),
    on(BannerActions.loadBannerConfigSuccess, (state, action) => {
        return {
            ...state,
            bannerConfig: action.data,
        };
    }),
    on(BannerActions.loadBannerConfigFailure, (state, action) => {
        return {
            ...state,
            error: action,
        };
    })
);

export function reducer(state: BannerState | undefined, action: Action) {
    return bannerReducer(state, action);
}
