export enum CampaignTypeSlug {
    FOOD = 'food',
    HOTEL = 'hotel',
    CRUISE = 'cruise',
    CAR_RENT = 'car-rent',
    SPA = 'spa',
    ACTIVITY = 'activity',
    CAFE = 'cafe',
    BEAUTY = 'beauty',
    TRAVEL_PACKAGE = 'travel-package',
    YACHT = 'yacht',
    PARACHUTE = 'parachute',
    DIVING = 'diving',
    CHAOPHRAYA_CRUISE = 'chaophraya-cruise',
    CHAOPRAYA_CRUISE = 'chaopraya-cruise',
}
