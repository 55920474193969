import { CampaignTypeSlug } from './enums/campaign.enum';

export const DEFAULT_VOUCHER_MENU: IVoucherMenu = { slug: 'voucher', text: 'เวาเชอร์และคูปองสินค้า' };

export const VOUCHER_MENUS: IVoucherMenu[] = [
    DEFAULT_VOUCHER_MENU,
    { slug: CampaignTypeSlug.HOTEL, text: 'โรงแรมและที่พัก' },
    { slug: CampaignTypeSlug.FOOD, text: 'อาหารและเครื่องดื่ม' },
    { slug: CampaignTypeSlug.CRUISE, text: 'ล่องเรือ' },
    { slug: CampaignTypeSlug.CAR_RENT, text: 'รถเช่า' },
    { slug: CampaignTypeSlug.SPA, text: 'สปา' },
    { slug: CampaignTypeSlug.ACTIVITY, text: 'กิจกรรม' },
    { slug: CampaignTypeSlug.CAFE, text: 'คาเฟ่' },
    { slug: CampaignTypeSlug.BEAUTY, text: 'ความสวยความงาม' },
    { slug: CampaignTypeSlug.TRAVEL_PACKAGE, text: 'แพคเกจท่องเที่ยว' },
    { slug: CampaignTypeSlug.YACHT, text: 'ล่องเรือยอร์ช' },
    { slug: CampaignTypeSlug.PARACHUTE, text: 'พาราชูต' },
    { slug: CampaignTypeSlug.DIVING, text: 'ดำน้ำ' },
    { slug: CampaignTypeSlug.CHAOPHRAYA_CRUISE, text: 'ล่องเรือเจ้าพระยา' },
    { slug: CampaignTypeSlug.CHAOPRAYA_CRUISE, text: 'ล่องเรือเจ้าพระยา' },
];

interface IVoucherMenu {
    slug: string;
    text: string;
}
