import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/modules/shared/shared.module';

// Themes
import { B1Component } from './designs/b-themes/b1/b1.component';
import { A1Component } from './designs/a-themes/a1/a1.component';
import { A2eComponent } from './designs/a-themes/a2e/a2e.component';
import { A2thComponent } from './designs/a-themes/a2th/a2th.component';
import { A3eComponent } from './designs/a-themes/a3e/a3e.component';
import { A3thComponent } from './designs/a-themes/a3th/a3th.component';
import { Outbound1Component } from './designs/outbound-themes/outbound1/outbound1.component';
import { DealwowComponent } from './designs/special-theme/dealwow/dealwow.component';
import { GiftAWowComponent } from './designs/special-theme/gift-a-wow/gift-a-wow.component';

// Headers
import { HeaderAComponent } from './shared-partials/headers/header-a/header-a.component';
import { HeaderDealwowComponent } from './shared-partials/headers/header-dealwow/header-dealwow.component';
import { CountriesSubmenuComponent } from './shared-partials/headers/header-a/countries-submenu/countries-submenu.component';
import { ProvincesSubmenuComponent } from './shared-partials/headers/header-a/provinces-submenu/provinces-submenu.component';
import { HeaderGiftAWowComponent } from './shared-partials/headers/header-gift-a-wow/header-gift-a-wow.component';

// Footers
import { FooterAComponent } from './shared-partials/footers/footer-a/footer-a.component';
import { FooterDealwowComponent } from './shared-partials/footers/footer-dealwow/footer-dealwow.component';
import { FooterGiftAWowComponent } from './shared-partials/footers/footer-gift-a-wow/footer-gift-a-wow.component';

// Other Partials
import { MinipadComponent } from './shared-partials/minipad/minipad.component';
import { VouchersSubmenuComponent } from './shared-partials/headers/header-a/vouchers-submenu/vouchers-submenu.component';

const THEMES_LIST = [A1Component, B1Component, A2eComponent, A2thComponent, A3eComponent, A3thComponent];

@NgModule({
    declarations: [
        THEMES_LIST,
        HeaderAComponent,
        HeaderDealwowComponent,
        HeaderGiftAWowComponent,
        CountriesSubmenuComponent,
        ProvincesSubmenuComponent,
        FooterAComponent,
        FooterDealwowComponent,
        FooterGiftAWowComponent,
        MinipadComponent,
        A2eComponent,
        A2thComponent,
        A3eComponent,
        A3thComponent,
        Outbound1Component,
        VouchersSubmenuComponent,
        DealwowComponent,
        GiftAWowComponent,
    ],
    imports: [CommonModule, RouterModule, SharedModule],
})
export class ThemeModule {}
