import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

export interface PageMetadata {
    displayName: string;
    title: string;
    // image will be added later
    description: string;
    keywords: string[];
    type: string;
}

const defaultMetadata: PageMetadata = {
    displayName: 'Tourwow Mall',
    title: '',
    description: 'โปรแกรมทัวร์ แพ็กเกจทัวร์ ท่องเที่ยวในประเทศ และต่างประเทศ',
    keywords: [],
    type: 'website',
};

@Injectable({
    providedIn: 'root',
})
export class MetadataService {
    constructor(private metaTagService: Meta, private titleService: Title, private router: Router) {}

    updateMetadata(metadata: Partial<PageMetadata>, index: boolean = true): void {
        const pageMetadata: PageMetadata = { ...defaultMetadata, ...metadata };
        const metatags: MetaDefinition[] = this.generateMetaDefinitions(pageMetadata);

        this.metaTagService.addTags([
            ...metatags,
            { property: 'og:url', content: `${this.router.url}` },
            { name: 'robots', content: index ? 'index, follow' : 'noindex' },
            { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
        ]);

        this.titleService.setTitle(this.titleBuilder(pageMetadata));
    }

    private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
        return [
            { property: 'title', content: this.titleBuilder(metadata) },
            { property: 'og:title', content: this.titleBuilder(metadata) },

            { property: 'description', content: metadata.description },
            { property: 'og:description', content: metadata.description },

            { property: 'keywords', content: metadata.keywords.join(', ') },

            { property: 'og:type', content: metadata.type },
        ];
    }

    private titleBuilder(metadata: PageMetadata): string {
        return (metadata.title ? metadata.title + ' - ' : '') + metadata.displayName;
    }
}
