import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
    selector: 'app-filter-panel',
    templateUrl: './filter-panel.component.html',
    styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements OnInit {
    @Input() labelBeforeTotal: string = 'ทัวร์';
    @Input() labelAfterTotal: string = 'โปรแกรมทัวร์';
    @Input() total: number = 0;
    @Input() sortingOptions: ISortingOption[] = [];

    @Output() sortingOptionsChange = new EventEmitter<ISortingOption[]>();
    @Output() showMobileSearchFilter = new EventEmitter<boolean>();

    public currentOption: ISortingOption;

    public displaySortingOptions: boolean = false;
    public displayBackdrop: boolean = false;
    public showStickyHeader: boolean = false;

    private oldScrollY: number;

    ngOnInit(): void {
        this.currentOption = this.sortingOptions[0];
    }

    public toggleSortingOptionsDisplay(): void {
        this.displaySortingOptions = !this.displaySortingOptions;
        this.displayBackdrop = this.displaySortingOptions;
    }

    public selectOption(id: number): void {
        this.sortingOptions.forEach((option) => {
            if (option.id === id) {
                option.selected = true;
                if (id !== this.currentOption.id) {
                    this.sortingOptionsChange.emit(this.sortingOptions);
                }
                this.currentOption = option;
            } else {
                option.selected = false;
            }
        });
        this.toggleSortingOptionsDisplay();
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (this.oldScrollY > window.scrollY) {
            this.showStickyHeader = true;
        } else {
            this.showStickyHeader = false;
        }
        this.oldScrollY = window.scrollY;
    }

    public onShowMobileSearchFilter(): void {
        this.showMobileSearchFilter.emit(true);
    }
}

export interface ISortingOption {
    id: number;
    selected: boolean;
    label: string;
}
