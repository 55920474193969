import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTemplate from './template-store.module';
import * as state from './template.reducer';

export const selectTemplateState = createFeatureSelector<state.TemplateState>(fromTemplate.FEATURE_NAME);

export const selectThemeConfig = createSelector(selectTemplateState, (state: state.TemplateState) => state.themeConfig);

export const selectDisplayHeaderBanner = createSelector(
    selectTemplateState,
    (state: state.TemplateState) => state.displayHeaderBanner
);

export const selectDisplayContactBox = createSelector(
    selectTemplateState,
    (state: state.TemplateState) => state.displayContactBox
);
