import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './month.reducer';
import { MonthEffects } from './month.effects';

export const FEATURE_NAME = 'month';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([MonthEffects])],
    providers: [MonthEffects],
})
export class MonthStoreModule {}
