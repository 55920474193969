import { ProductSearchResult } from 'src/app/models/product-search-result.model';
import { IPaginationResponse } from 'src/app/models/response/pagination-response';

export interface ProductSearchResultState {
    productSearchResults: IPaginationResponse<ProductSearchResult[]>;
    error: any;
}

export const initialState: ProductSearchResultState = {
    productSearchResults: null,
    error: null,
};
