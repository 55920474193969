import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';

//
import { TemplateState } from './template-store/template.reducer';
import { AgencyState } from './agency-store/agency.reducer';
import { BannerState } from './banner-store/banner.reducer';
import { CountryState } from './country-store/country.reducer';
import { ProvinceState } from './province-store/province.reducer';
import { MonthState } from './month-store/month.state';
import { LeadState } from './lead-store/lead.reducer';
import { ProductState } from './product-store/product.state';
import { ProductSearchResultState } from './product-search-result-store/product-search-result.state';
import { PortfolioState } from './portfolio-store/portfolio.reducer';

export interface State {
    template: TemplateState;
    agency: AgencyState;
    banner: BannerState;
    country: CountryState;
    province: ProvinceState;
    month: MonthState;
    lead: LeadState;
    product: ProductState;
    productSearchResult: ProductSearchResultState;
    portfolio: PortfolioState;
}

export const reducers: ActionReducerMap<State> = {
    template: null,
    agency: null,
    banner: null,
    country: null,
    province: null,
    month: null,
    lead: null,
    product: null,
    productSearchResult: null,
    portfolio: null,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
