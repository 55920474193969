import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CampaignChaophrayaCruiseShop } from 'src/app/models/product/campaign/campaign-chaophraya-cruise-shop.model';
import { CampaignDetail } from 'src/app/models/product/campaign/campaign-detail.model';
import { CampaignListItem } from 'src/app/models/product/campaign/campaign-list-item.model';
import { OrderDetail } from 'src/app/models/product/campaign/order-detail.model';
import { CampaignOrderResponse } from 'src/app/modules/products/campaign/models/campaign-order.model';
import { CampaignService } from 'src/app/services/campaign.service';
import * as CampaignActions from './campaign.actions';

@Injectable()
export class CampaignEffects {
    constructor(private actions$: Actions, private campaignService: CampaignService) {}

    loadPaginatedCampaigns = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(CampaignActions.getPaginatedCampaigns),
                switchMap((action) => {
                    return this.campaignService.getCampaignListPaginated(action.subDomain, action.query).pipe(
                        map((resp) => {
                            if (resp.status === 'success') {
                                return CampaignActions.getPaginatedCampaignsSuccess({
                                    paginatedCampaigns: {
                                        page: resp.data.page,
                                        pageEntries: resp.data.page_entries,
                                        total: resp.data.total,
                                        result: (resp.data.result as any[]).map((item) =>
                                            new CampaignListItem().deserialize(item)
                                        ),
                                    },
                                });
                            }
                            return CampaignActions.getPaginatedCampaignsFailure({ error: resp });
                        }),
                        catchError((error) => of(CampaignActions.getPaginatedCampaignsFailure({ error: error })))
                    );
                })
            )
    );

    loadCampaignDetail = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(CampaignActions.getCampaignDetail),
                switchMap((action) => {
                    return this.campaignService.getCampaignDetail(action.subDomain, action.campaignId).pipe(
                        map((resp) => {
                            if (resp.status === 'success') {
                                return CampaignActions.getCampaignDetailSuccess({
                                    campaign: new CampaignDetail().deserialize(resp.data),
                                });
                            }
                            return CampaignActions.getCampaignDetailFailure({ error: resp });
                        }),
                        catchError((error) => of(CampaignActions.getCampaignDetailFailure({ error: error })))
                    );
                })
            )
    );

    createCampaignOrder = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(CampaignActions.createCampaignOrder),
                switchMap((action) => {
                    const failResponse = {
                        isSuccess: false,
                        orderId: null,
                        orderTempKey: null,
                    };

                    return this.campaignService
                        .createCampaignOrder(action.subDomain, action.campaignId, action.data)
                        .pipe(
                            map((resp) => {
                                if (resp.status === 'success') {
                                    const response = new CampaignOrderResponse().deserialize(resp.data);
                                    response.isSuccess = true;

                                    return CampaignActions.createCampaignOrderSuccess({ response });
                                } else {
                                    const response = new CampaignOrderResponse();
                                    response.isSuccess = false;
                                    if (resp.error.notices.cannot_create_order != undefined) {
                                        return CampaignActions.createCampaignOrderAlreadyOrderFailure({ response });
                                    } else if (resp.error.notices.cannot_create_payment != undefined) {
                                        return CampaignActions.createCampaignOrderOutOfStockFailure({ response });
                                    }
                                }

                                // return CampaignActions.setOrderCreationIsSuccess(failResponse);
                            })
                            // catchError((error) => of(CampaignActions.setOrderCreationIsSuccess(failResponse)))
                        );
                })
            )
    );

    loadOrderDetail = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(CampaignActions.getOrderDetail),
                switchMap((action) => {
                    return this.campaignService.getOrderDetail(action.subDomain, action.key).pipe(
                        map((resp) => {
                            if (resp.status === 'success') {
                                const order = new OrderDetail().deserialize(resp.data);
                                return CampaignActions.getOrderDetailSuccess({
                                    order,
                                });
                            }
                            return CampaignActions.getOrderDetailFailure({ error: resp });
                        }),
                        catchError((error) => of(CampaignActions.getOrderDetailFailure({ error: error })))
                    );
                })
            )
    );

    getChaophrayaCruiseUpdate = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(CampaignActions.getChaophrayaCruiseUpdate),
                switchMap((action) => {
                    return this.campaignService.getChaophrayaCruiseUpdate(action.subDomain).pipe(
                        map((resp) => {
                            if (resp.status === 'success') {
                                return CampaignActions.getChaophrayaCruiseUpdateSuccess({
                                    data: resp.data.map((shop) => new CampaignChaophrayaCruiseShop().deserialize(shop)),
                                });
                            }
                            return CampaignActions.getChaophrayaCruiseUpdateFailure({ error: resp });
                        }),
                        catchError((error) => of(CampaignActions.getChaophrayaCruiseUpdateFailure({ error: error })))
                    );
                })
            )
    );
}
