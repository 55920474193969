import { DateHelper } from 'src/app/helpers/date.helper';
import { ImageFile } from './image-file.model';

export class CampaignChaophrayaCruiseShop {
    id: number;
    shopName: string;
    cruises: ChaophrayaCruise[];

    public deserialize(data: any): this {
        this.id = data.id;
        this.shopName = data.shop_name;
        this.cruises = data.chaophraya_cruises.map((cruise) => new ChaophrayaCruise().deserialize(cruise));

        return this;
    }
}

export class ChaophrayaCruise {
    id: number;
    name: string;
    updatedAt: Date;
    imageFiles: ImageFile[];

    public deserialize(data: any): this {
        this.id = data.id;
        this.name = data.name;
        this.updatedAt = DateHelper.convertStringToDate(data.seat_layout_updated_at);
        this.imageFiles = data.seat_layout_image_files.map((file) => new ImageFile().deserialize(file));

        return this;
    }
}
