import { createAction, props } from '@ngrx/store';
import { IPaginationResponse } from 'src/app/models/response/pagination-response';
import { ProductSearchResult } from 'src/app/models/product-search-result.model';
import { ProductSearchQuery } from 'src/app/models/request/queries/product-search-query';

export const loadProductSearchResults = createAction(
    '[Product Search Result] Load Product Search Result.',
    props<{
        subDomain: string;
        displayExpiredProducts: boolean;
        query: ProductSearchQuery;
    }>()
);
export const loadProductSearchResultsSuccess = createAction(
    '[Product Search Result] Load Product Search Result Success.',
    props<{ productSearchResults: IPaginationResponse<ProductSearchResult[]> }>()
);
export const loadProductSearchResultsFailure = createAction(
    '[Product Search Result] Load Product Search Result Failure.',
    props<{ error: any }>()
);
export const resetProductSearchResults = createAction('[Product Search Result] Reset Product Search Result.');
