<ng-container *ngIf="product">
    <div class="package__card d-flex no-gutters mb-4" [class.in-detail-page]="isDetailPage">
        <a
            target="_blank"
            [routerLink]="[
                getLinkUrlfirstUrlSegment(),
                friendlyUrl.getFriendlyUrlForPackageCardDetail(product, agencyInfo)
            ]"
            class="package__card-image col-4"
        >
            <img appImageLoader [src]="product?.bannerThumbnailUrl" [defaultSrc]="product?.bannerUrl || ''" />
        </a>
        <div class="package__card-content col p-4 d-flex flex-column">
            <a
                target="_blank"
                [routerLink]="[
                    getLinkUrlfirstUrlSegment(),
                    friendlyUrl.getFriendlyUrlForPackageCardDetail(product, agencyInfo)
                ]"
                class="package__card-title text-bold mb-2 mb-lg-2"
                >{{ product.name }}</a
            >
            <div class="package__card-status row no-gutters mb-2">
                <div class="col-6 location">
                    <img src="/assets/images/ico-location.svg" width="16" height="16" class="location mr-1" />
                    {{ product.getDestinationString() || '-' }}
                </div>
                <div class="col-6">
                    <img src="/assets/images/ico-period.svg" width="16" height="16" class="mr-3" />
                    <ng-container *ngIf="product.durationDay > 1"
                        >{{ product.durationDay }} วัน {{ product.durationNight }} คืน</ng-container
                    >
                    <ng-container *ngIf="product.durationDay === 1">One day trip</ng-container>
                    <ng-container *ngIf="!product.durationDay && !product.durationNight">-</ng-container>
                </div>
                <div class="col-6 col-md-12">
                    <img src="/assets/images/ico-calendar.svg" width="16" height="16" class="mr-2" />
                    <span>{{
                        product.travelPeriodStartAtFirst | thaimonthrange: product.travelPeriodStartAtLast
                    }}</span>
                    <span *ngIf="!product.travelPeriodStartAtFirst">-</span>
                    <span *ngIf="product.periodsCount > 0">( {{ product.periodsCount }} ช่วงเวลา)</span>
                </div>
            </div>

            <div class="package__recommend d-inline-flex px-3 py-2 mb-2">
                <img src="/assets/images/icon-detail-star.svg" height="18" class="mr-2" />
                <span [innerHTML]="product?.hilightDescription || '-'"></span>
            </div>
            <div class="package__detail-other align-items-end justify-content-between d-flex">
                <div class="package__card-status">
                    <div *ngIf="product.goTransport">
                        <img src="/assets/images/ico-flight-go.svg" width="24" height="24" class="mr-2" />
                        {{ product.goTransport.nameEn }} ({{ product.goTransport.code }})
                    </div>
                    <div *ngIf="!product.goTransport && product.categoryTransport">
                        <img
                            *ngIf="product.categoryTransport.slug === 'bus'"
                            src="/assets/images/icon-bus.svg"
                            width="24"
                            height="24"
                            class="mr-2"
                        />
                        <img
                            *ngIf="product.categoryTransport.slug === 'train'"
                            src="/assets/images/icon-train.svg"
                            width="24"
                            height="24"
                            class="mr-2"
                        />
                        <img
                            *ngIf="product.categoryTransport.slug === 'boat'"
                            src="/assets/images/ico-boat.svg"
                            width="24"
                            height="24"
                            class="mr-2"
                        />
                        <img
                            *ngIf="product.categoryTransport.slug === 'van'"
                            src="/assets/images/ico-van.svg"
                            width="24"
                            height="24"
                            class="mr-2"
                        />
                        <img
                            *ngIf="product.categoryTransport.slug === 'car'"
                            src="/assets/images/icon-car.svg"
                            width="24"
                            height="24"
                            class="mr-2"
                        />
                        <img
                            *ngIf="product.categoryTransport.slug === 'plane'"
                            src="/assets/images/ico-flight-go.svg"
                            width="24"
                            height="24"
                            class="mr-2"
                        />
                        {{ product.categoryTransport.name }}
                    </div>
                </div>
                <div class="package__reserve">
                    <div class="package__price-net mb-2">
                        <div>ราคาเริ่มต้น</div>
                        <span class="font-weight-bold">{{ product.price | number: '1.0-0' }}</span>
                    </div>
                    <a
                        target="_blank"
                        [routerLink]="[
                            getLinkUrlfirstUrlSegment(),
                            friendlyUrl.getFriendlyUrlForPackageCardDetail(product, agencyInfo)
                        ]"
                        name="button"
                        class="btn btn-lg btn-green w-100"
                    >
                        จองทัวร์
                    </a>
                </div>
            </div>
        </div>
        <!-- booking mobile -->
        <div id="mobile-contact-panel" class="contact-panel-bottom action__group p-4">
            <!-- button -->
            <button type="button" name="button" class="btn btn-reserve w-100 mb-3" (click)="onWebContactClick()">
                จองผ่านเว็บ
            </button>
            <!-- line -->
            <div class="row no-gutters">
                <div class="col pr-2">
                    <button
                        type="button"
                        name="button"
                        class="btn btn-outline-line w-100"
                        (click)="onLineContactClick()"
                        [disabled]="!canOpenLineContactModal()"
                    >
                        <img src="/assets/images/icon-line.svg" width="22" height="22" /> จองผ่านไลน์
                    </button>
                </div>
                <div class="col pl-2">
                    <button
                        type="button"
                        name="button"
                        class="btn btn-outline-call w-100"
                        (click)="onPhoneContactClick()"
                    >
                        <img src="/assets/images/ico-call-w-i.svg" width="22" height="22" /> โทรจอง
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
