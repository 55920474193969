import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './province.reducer';
import { ProvinceEffects } from './province.effects';

export const FEATURE_NAME = 'province';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([ProvinceEffects])],
    providers: [ProvinceEffects],
})
export class ProvinceStoreModule {}
