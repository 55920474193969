import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appDiscountPercentage' })
export class DiscountPercentagePipe implements PipeTransform {
    transform(price: number, priceCompare: number): number {
        if (!priceCompare || priceCompare === 0) {
            return price;
        }

        price = +price;
        priceCompare = +priceCompare;
        let percentageReturn = 0;

        if (priceCompare === null || priceCompare < price) {
            return percentageReturn;
        }

        percentageReturn = Math.round(((priceCompare - price) / priceCompare) * 100);
        return percentageReturn;
    }
}
