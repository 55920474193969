import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
    @Input() totalPage: number;
    @Input() currentPage: number = 1;
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    pageList: number[];
    hasPreviousSpacer: boolean;
    hasNextSpacer: boolean;

    ngOnInit(): void {
        this.updatePageList();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('totalPage' in changes && changes.totalPage.currentValue !== changes.totalPage.previousValue) {
            this.currentPage = 1;
        }
        this.updatePageList();
    }

    changeCurrentPage(page: number): void {
        if (this.currentPage !== page) {
            this.pageChange.emit(page);
        }
        this.currentPage = page;
        this.updatePageList();
        this.scrollToTop();
    }

    decrementPage(): void {
        if (this.currentPage > 1) {
            this.changeCurrentPage(this.currentPage - 1);
        }
        this.scrollToTop();
    }

    incrementPage(): void {
        if (this.currentPage < this.totalPage) {
            this.changeCurrentPage(this.currentPage + 1);
        }
        this.scrollToTop();
    }

    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    private updatePageList(): void {
        const firstPage = this.currentPage >= 6 ? this.currentPage - 3 : 1;
        let lastPage = this.currentPage >= 6 ? this.currentPage : 5;
        if (lastPage > this.totalPage) {
            lastPage = this.totalPage;
        }
        const pageList = [];
        for (let i = firstPage; i <= lastPage; i++) {
            pageList.push(i);
        }
        this.pageList = pageList;
        this.updateHasPreviousSpacer();
        this.updateHasNextSpacer();
    }

    private updateHasPreviousSpacer(): void {
        this.hasPreviousSpacer = this.pageList[0] >= 3;
    }

    private updateHasNextSpacer(): void {
        this.hasNextSpacer = this.pageList[this.pageList.length - 1] <= this.totalPage - 2;
    }
}
