import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of, Observable, pipe } from 'rxjs';

import * as TemplateAction from './template.actions';
import { TemplateService } from 'src/app/services/template.service';
import { TemplateConfig } from '../../models/template.model';
import { TemplateConfigConfig } from 'src/app/models/interfaces/response/template-config-response.interface';

@Injectable()
export class TemplateEffects {
    constructor(private actions$: Actions, private templateService: TemplateService, private router: Router) {}

    loadTemplate = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(TemplateAction.loadTemplateConfig),
                concatMap((action) => {
                    return this.templateService.getTemplateConfig(action.subDomain).pipe(
                        map((templateConfig: TemplateConfig) => {
                            return TemplateAction.loadTemplateConfigSuccess({ data: templateConfig });
                        }),
                        catchError((error) => {
                            return of(TemplateAction.loadTemplateConfigFailure(error));
                        })
                    );
                })
            )
    );
}
