import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { HttpService } from './base/http.service';
import { switchMap, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class PortfolioService extends BaseHttpService {
    getDetail(subdomain: string, portfolioId: number): Observable<any> {
        return this.get(
            `${environment.apiMallUrl}${ENDPOINTS.PORTFOLIO.DETAIL}`
                .replace('{subdomain}', subdomain)
                .replace('{portfolio_id}', portfolioId + '')
        );
    }

    getPaginatedListing(subdomain: string, criteria: any): Observable<any> {
        let params = new HttpParams();
        if (criteria) {
            params = params.append('page', criteria.page);
            params = params.append('page_entries', criteria.pageEntries);
        }

        const url =
            environment.apiMallUrl +
            ENDPOINTS.PORTFOLIO.PAGINATED_LISTING.replace('{subdomain}', subdomain) +
            '?' +
            params.toString();

        return this.get(url);
    }
}
