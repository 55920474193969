<div class="position-relative" [ngClass]="{ 'd-none': (displayContactBox$ | async) === null }">
    <div class="scrollTop d-flex align-items-center flex-column">
        <div
            class="minipad flex-column p-3 mb-3 position-relative d-none"
            [ngClass]="isCloseModal || isResultOrDetailPage ? 'd-none' : 'd-lg-flex'"
        >
            <a
                *ngIf="(agencyInfo$ | async)?.facebookUrl"
                [href]="(agencyInfo$ | async)?.facebookUrl"
                data-toggle=""
                data-placement="left"
                ><img class="mb-2" src="/assets/images/ico-facebook.svg" width="36" height="36"
            /></a>
            <a
                *ngIf="(agencyInfo$ | async)?.email"
                href="mailto:{{ (agencyInfo$ | async)?.email }}"
                data-toggle=""
                data-placement="left"
                ><img class="mb-2" src="/assets/images/ico-mail.svg" width="36" height="36"
            /></a>
            <div *ngIf="(agencyInfo$ | async)?.lineQrUrl" class="pop_id-dropdown dropdown dropleft position-relative">
                <a href="#" class="dropdown-toggle pop_id-pad" data-toggle="dropdown">
                    <img class="mb-2" src="/assets/images/ico-line.svg" width="36" height="36" />
                </a>
                <div class="pop_id-content dropdown-menu">
                    <div class="txt_id-title" *ngIf="(agencyInfo$ | async)?.lineId">ไอดีไลน์</div>
                    <div class="txt_id-popover">{{ (agencyInfo$ | async)?.lineId }}</div>
                    <img [src]="(agencyInfo$ | async)?.lineQrUrl" width="105" height="105" />
                </div>
            </div>
            <div
                *ngIf="(agencyInfo$ | async)?.officeTelephoneNumber"
                class="pop_id-dropdown dropdown dropleft position-relative"
            >
                <a href="#" class="dropdown-toggle pop_id-pad" data-toggle="dropdown">
                    <img class="mb-2" src="/assets/images/ico-call.svg" width="36" height="36" />
                </a>
                <div class="pop_id-content dropdown-menu">
                    <div class="txt_id-title">เบอร์โทรติดต่อ</div>
                    <div class="txt_id-tel">{{ (agencyInfo$ | async)?.officeTelephoneNumber | phoneNumber }}</div>
                </div>
            </div>
            <a class="close-button" (click)="isCloseModal = true" data-toggle="" data-placement="left"
                ><img src="/assets/images/ico-close.svg" width="14" height="14"
            /></a>
        </div>
        <a
            class="to-top-button d-none"
            [ngClass]="{ 'd-lg-none': isTopOfScreen(), 'd-lg-block': !isTopOfScreen() }"
            (click)="scrollToTop()"
            ><img src="/assets/images/ico-gototop.svg" width="40" height="40"
        /></a>
    </div>
</div>
