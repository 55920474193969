import { ICountry, ICountryContinents } from './../../models/country.model';
import { Action, createReducer, on, State } from '@ngrx/store';
import * as CountryActions from './country.actions';
import { ICountryRecommend } from 'src/app/models/country-recommend.model';

export interface CountryState {
    countries: ICountryContinents;
    countriesRecommend: ICountryRecommend[];
    allCountry: ICountry[];
    errorMessage: string | null;
}

export const initialState: CountryState = {
    countries: null,
    countriesRecommend: [],
    allCountry: [],
    errorMessage: null,
};

export const countryReducer = createReducer(
    initialState,

    on(CountryActions.loadActiveCountries, (state, action) => state),
    on(CountryActions.loadActiveCountriesSuccess, (state, action) => ({ ...state, countries: action.data })),
    on(CountryActions.loadActiveCountriesFailure, (state, action) => ({ ...state, error: action })),

    on(CountryActions.loadAllCountry, (state) => state),
    on(CountryActions.loadAllCountrySuccess, (state, action) => ({ ...state, allCountry: action.countries })),
    on(CountryActions.loadAllCountryFailure, (state, action) => ({ ...state, error: action.error })),

    /** country recommend reducers */
    on(CountryActions.loadCountryRecommend, (state) => state),
    on(CountryActions.loadCountryRecommendSuccess, (state, action) => ({
        ...state,
        countriesRecommend: action.countriesRecommend,
    })),
    on(CountryActions.loadCountryRecommendFailure, (state, action) => ({ ...state, error: action.error }))
);

export function reducer(state: CountryState | undefined, action: Action) {
    return countryReducer(state, action);
}
