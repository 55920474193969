import { IHighlightedShowcase, IPdfDetailFile } from './../../models/product.model';
import { IProductBox } from 'src/app/models/product-box.model';
import { IProduct } from 'src/app/models/product.model';
import { IProgramOrderResponse } from 'src/app/models/response/program-order-response.interface';
import { OrderProgramDetail } from 'src/app/models/product/program/order-program-detail.model';

export interface ProductState {
    tourRecommendProducts: IProductBox[];
    packageTourProducts: IProductBox[];
    domesticTourProducts: IProductBox[];
    productDetail: IProduct;
    showcases: IHighlightedShowcase[];
    pdfDetailFile: IPdfDetailFile;
    createProductOrderResponse: IProgramOrderResponse;
    orderProgramDetail: OrderProgramDetail;
}

export const initialState: ProductState = {
    tourRecommendProducts: [],
    packageTourProducts: [],
    domesticTourProducts: [],
    productDetail: null,
    showcases: null,
    pdfDetailFile: null,
    createProductOrderResponse: null,
    orderProgramDetail: null,
};
