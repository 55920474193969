import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';

// shared directives
import { DynamicContentDirective } from 'src/app/directives/dynamic-content.directive';
import { OnlyNumberDirective } from 'src/app/directives/only-number.directive';

// shared pipes
import {
    ThaiDateRangePipe,
    ThaiDatePipe,
    ThaiMonthRangePipe,
    ThaiMonthDatetimePipe,
} from 'src/app/pipes/thaidate.pipe';
import { PhoneNumberPipe } from 'src/app/pipes/phone-number.pipe';
import { SanitizeHtmlPipe } from 'src/app/pipes/sanitize-html.pipe';
import { StripHtmlPipe } from 'src/app/pipes/strip-html.pipe';
import { DiscountPercentagePipe } from 'src/app/pipes/discount-percentage.pipe';
import { FilterObjectPipe } from 'src/app/pipes/filter-object.pipe';
import { PaymentMethodPipe } from 'src/app/pipes/payment-method.pipe';

// shared components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PackageCardDetailComponent } from './components/package-card-detail/package-card-detail.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { SearchPanelComponent } from './components/search-panel/search-panel.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SlideTopComponent } from './components/campaign-detail/slide-top/slide-top.component';
import { ImageLoaderModule } from 'src/app/directives/image-loader.module';

const PIPES = [
    ThaiDateRangePipe,
    ThaiDatePipe,
    ThaiMonthRangePipe,
    ThaiMonthDatetimePipe,
    PhoneNumberPipe,
    SanitizeHtmlPipe,
    StripHtmlPipe,
    DiscountPercentagePipe,
    FilterObjectPipe,
    PaymentMethodPipe,
];
const DIRECTIVES = [DynamicContentDirective, OnlyNumberDirective];
const COMPONENTS = [
    BreadcrumbComponent,
    PackageCardDetailComponent,
    SocialLinksComponent,
    SearchPanelComponent,
    FilterPanelComponent,
    PaginationComponent,
    SlideTopComponent,
];

@NgModule({
    declarations: [[...COMPONENTS], [...PIPES], [...DIRECTIVES]],
    imports: [CommonModule, RouterModule, CarouselModule, ImageLoaderModule],
    exports: [CarouselModule, [...COMPONENTS], [...PIPES], [...DIRECTIVES]],
    providers: [...PIPES],
})
export class SharedModule {}
