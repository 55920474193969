import { createAction, props } from '@ngrx/store';

// Interfaces and Models
import { SeoConfigs } from 'src/app/models/seo-configs.model';
import { AgencyInfo, IAboutUsResponse } from 'src/app/models/agency.model';
import { ICustomContentDetail } from 'src/app/models/custom-content.model';

export const setSeoConfig = createAction('[Agency] Set seo config.', props<{ data: SeoConfigs }>());
export const setAgencyInfo = createAction('[Agency] Set agency info.', props<{ data: AgencyInfo }>());

export const loadAboutUsInfo = createAction('[Agency] Load about us info.', props<{ subDomain: string }>());
export const loadAboutUsInfoSuccess = createAction(
    '[Agency] Load about us info success.',
    props<{ data: IAboutUsResponse }>()
);
export const loadAboutUsInfoFailure = createAction('[Agency] Load about us info failure.', props<{ error: any }>());

export const loadCustomContentDetail = createAction(
    '[Agency] Load custom content detail.',
    props<{ subDomain: string; customContentId: number }>()
);
export const loadCustomContentDetailSuccess = createAction(
    '[Agency] Load custom content detail success.',
    props<{ data: ICustomContentDetail }>()
);
export const loadCustomContentDetailFailure = createAction(
    '[Agency] Load custom content detail failure.',
    props<{ error: any }>()
);

export const loadCustomContentDetailReset = createAction('[Agency] Load custom content detail reset.');

export const loadBlogListing = createAction(
    '[Agency] Load blog listing.',
    props<{ subDomain: string; criteria: any }>()
);
export const loadBlogListingSuccess = createAction(
    '[Agency] Load blog listing success.',
    props<{ blogListing: any }>()
);
export const loadBlogListingFailure = createAction('[Agency] Load blog listing failure.', props<{ error: any }>());

export const loadBlogDetail = createAction('[Agency] Load blog detail.', props<{ subDomain: string; id: number }>());
export const loadBlogDetailSuccess = createAction('[Agency] Load blog detail success.', props<{ blogDetail: any }>());
export const loadBlogDetailFailure = createAction('[Agency] Load blog detail failure.', props<{ error: any }>());
