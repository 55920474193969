<div class="filter-panel-mobile" [class.pos-sticky]="showStickyHeader">
    <div class="filter-panel-mobile-header">ผลการค้นหา : {{ labelBeforeTotal }}</div>
    <div class="filter-result my-3">
        พบ <span>{{ total }}</span> {{ labelAfterTotal }}
    </div>
    <div class="filter-box mb-2">
        <a (click)="onShowMobileSearchFilter()" class="filter-search d-inline-flex align-items-center">
            <img src="/assets/images/ico-filter.svg" width="16" class="mr-3" />
            <div class="">ตัวช่วยค้นหา</div>
        </a>
        <div class="filter-sort d-inline-flex align-items-center" (click)="toggleSortingOptionsDisplay()"
            style="cursor: pointer">
            <img src="/assets/images/ico-sort.svg" width="16" class="mr-3" />
            <div>
                <div class="filter-text">เรียงตาม</div>
                <div class="filter-text-small blue-colour">{{ currentOption.label }}</div>
            </div>
        </div>
    </div>
</div>
<div class="filter-panel p-3 align-items-center justify-content-between d-none d-lg-flex mb-4"
    [ngStyle]="{'z-index': displayBackdrop?'1000':''}">
    <div class="filter-result">
        ผลการค้นหา {{ labelBeforeTotal }} พบ <span>{{ total }}</span> {{ labelAfterTotal }}
    </div>
    <div class="filter-sort" style="cursor: pointer" (click)="toggleSortingOptionsDisplay()">
        <span class="mr-2">
            <img src="/assets/images/ico-sort.svg" width="14" class="mr-2" />
            เรียงตาม
        </span>
        <span class="blue-colour">{{ currentOption.label }}</span><img src="/assets/images/ico-arrow-down.svg"
            width="12" class="ml-3" />
    </div>
</div>
<div *ngIf="displaySortingOptions" class="sorting-options-container" style="z-index: 1000">
    <div class="sorting-options d-flex flex-column" style="z-index: 1000">
        <div class="sorting-options-header px-2 row">
            <div class="col-6">
                <span>เรียงตาม</span>
            </div>
            <div class="col-6 cancel-button d-lg-none">
                <a (click)="toggleSortingOptionsDisplay()">ยกเลิก</a>
            </div>
        </div>
        <div *ngFor="let option of sortingOptions" class="sorting-options-item p-2"
            [ngClass]="{ selected: option.selected }" (click)="selectOption(option.id)">
            <span>{{ option.label }}</span>
        </div>
    </div>
</div>

<div *ngIf="displaySortingOptions" class="backdrop" (click)="toggleSortingOptionsDisplay()"></div>