import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENDPOINTS } from '../constants/endpoint.constant';
import { environment } from 'src/environments/environment';

import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class RegionService extends BaseHttpService {
    getProvincesRecommend(subDomain: string): Observable<any> {
        let url = environment.apiMallUrl + ENDPOINTS.REGION.GET_PROVINCE_RECCOMEND;
        url = url.replace('{subdomain}', subDomain);

        return this.get(url);
    }
}
