import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UrlHelper } from 'src/app/helpers/url.helper';
import { WindowDomHelper } from 'src/app/helpers/window-dom.helper';

@Component({
    selector: 'app-social-links',
    templateUrl: './social-links.component.html',
    styleUrls: ['./social-links.component.scss'],
})
export class SocialLinksComponent {
    @ViewChild('CopyLinkSuccessModal') copyLinkSuccessModal: TemplateRef<any>;
    @Input() sharingUrl: string;

    modalRef: BsModalRef = null;

    constructor(private modalService: BsModalService) {}

    popupLineShare(): void {
        const url = UrlHelper.lineSharingLink(this.sharingUrl);
        WindowDomHelper.popupCenter(url, 'Line Share', 900, 500);
    }

    popupFacebookShare(): void {
        const url = UrlHelper.facebookSharingLink(this.sharingUrl);
        WindowDomHelper.popupCenter(url, 'Facebook Share', 900, 500);
    }

    copyLink(): void {
        UrlHelper.copyLink(this.sharingUrl);
        this.modalRef = this.modalService.show(this.copyLinkSuccessModal, { class: 'modal-sm modal-dialog-centered' });
    }
}
