export const MENU = {
    TOUR_TYPE_OUTBOUND: {
        slug: 'tour_type_outbound',
        nameTH: 'ทัวร์ต่างประเทศ',
        nameEN: 'Outbound Tour',
    },
    TOUR_TYPE_DOMESTIC_PACKAGE: {
        slug: 'tour_type_domestic_package',
        nameTH: 'ทัวร์ในประเทศ',
        nameEN: 'Thailand Tour',
    },
    TOUR_TYPE_OUTBOUND_PACKAGE: {
        slug: 'tour_type_outbound_package',
        nameTH: 'แพ็กเกจทัวร์',
        nameEN: 'Package Tour',
    },
    CAMPAIGN_TYPE_TRAVEL_PACKAGE: {
        slug: 'campaign_type_travel_package',
        nameTH: 'แพ็กเกจท่องเที่ยว',
        nameEN: 'Travel Package',
    },
    CAMPAIGN_TYPE_CHAOPHRAYA_CRUISE: {
        slug: 'campaign_type_chaophraya_cruise',
        nameTH: 'ล่องเรือเจ้าพระยา',
        nameEN: 'Chaophraya Cruise',
    },
};
