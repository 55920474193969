import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { selectAgencyInfo } from 'src/app/stores/agency-store/agency.selectors';
import { State } from 'src/app/stores/reducers';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
    public appVersion: string = environment.appVersion;
    public agencyFound: boolean = false;

    constructor(private store: Store<State>) {}

    ngOnInit() {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((ret) => ret != null)
            )
            .subscribe(() => {
                this.agencyFound = true;
            });
    }
}
