import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectAgencyInfo } from 'src/app/stores/agency-store/agency.selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { State } from 'src/app/stores/reducers';
import { selectDisplayContactBox } from 'src/app/stores/template-store/template.selectors';
import { NavigationEnd, Router } from '@angular/router';
import { AgencyInfo } from 'src/app/models/agency.model';

@Component({
    selector: 'app-minipad',
    templateUrl: './minipad.component.html',
    styleUrls: ['./minipad.component.scss'],
})
export class MinipadComponent implements OnInit, AfterContentInit {
    private unsubscribe$: Subject<void> = new Subject<void>();
    agencyInfo$: Observable<AgencyInfo>;
    isCloseModal: boolean = false;
    displayContactBox$: Observable<boolean>;
    isResultOrDetailPage: boolean = false;

    constructor(private store: Store<State>, private router: Router) {}

    ngOnInit(): void {
        this.loadAgencyInfo();
        this.loadDisplayBoxContactConfig();
    }

    ngAfterContentInit(): void {
        this.checkIfDetailPage();
        this.subscribeToUrlChange();
    }

    private loadAgencyInfo(): void {
        this.agencyInfo$ = this.store.pipe(select(selectAgencyInfo), takeUntil(this.unsubscribe$));
    }

    public isTopOfScreen(): boolean {
        return window.scrollY === 0;
    }

    public scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    private loadDisplayBoxContactConfig(): void {
        this.displayContactBox$ = this.store.pipe(select(selectDisplayContactBox), takeUntil(this.unsubscribe$));
    }

    private subscribeToUrlChange(): void {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.checkIfDetailPage();
            }
        });
    }

    private checkIfDetailPage(): void {
        const searchResultPage = document.getElementById('search-result-page');
        const searchDetailPage = document.getElementById('search-detail-page');

        if (typeof searchDetailPage != 'undefined' && searchDetailPage != null) {
            this.isResultOrDetailPage = true;
        } else if (typeof searchResultPage != 'undefined' && searchResultPage != null) {
            this.isResultOrDetailPage = true;
        }
    }
}
