import { IHighlightedShowcase, IPdfDetailFile } from './../../models/product.model';
import { createAction, props } from '@ngrx/store';
import { IProductBox } from 'src/app/models/product-box.model';
import { IProduct } from 'src/app/models/product.model';
import { IBookingTour } from 'src/app/models/booking-tour.interface';
import { IProgramOrderResponse } from 'src/app/models/response/program-order-response.interface';
import { OrderProgramDetail } from 'src/app/models/product/program/order-program-detail.model';

export const loadTourRecommendProducts = createAction(
    '[Product Box] Load Tour Recommend Products.',
    props<{ subDomain: string; displayExpiredProducts: boolean; limit: number }>()
);
export const loadTourRecommendProductsSuccess = createAction(
    '[Product Box] Load Tour Recommend Products Success.',
    props<{ products: IProductBox[] }>()
);
export const loadTourRecommendProductsFailure = createAction(
    '[Product Box] Load Tour Recommend Products Failure.',
    props<{ error: any }>()
);

export const loadPackageTourProducts = createAction(
    '[Product Box] Load Package Tour Products.',
    props<{ subDomain: string; displayExpiredProducts: boolean; excludedProductPoolIds: number[] }>()
);
export const loadPackageTourProductsSuccess = createAction(
    '[Product Box] Load Package Tour Products Success.',
    props<{ products: IProductBox[] }>()
);
export const loadPackageTourProductsFailure = createAction(
    '[Product Box] Load Package Tour Products Failure.',
    props<{ error: any }>()
);

export const loadDomesticTourProducts = createAction(
    '[Product Box] Load Domestic Tour Products.',
    props<{ subDomain: string; displayExpiredProducts: boolean; regionId: number }>()
);
export const loadDomesticTourProductsSuccess = createAction(
    '[Product Box] Load Domestic Tour Products Success.',
    props<{ products: IProductBox[] }>()
);
export const loadDomesticTourProductsFailure = createAction(
    '[Product Box] Load Domestic Tour Products Failure.',
    props<{ error: any }>()
);

export const getProductDetail = createAction(
    '[Product] Load Product Detail.',
    props<{ subDomain: string; keyItem: string }>()
);
export const getProductDetailSuccess = createAction(
    '[Product] Load Product Detail Success.',
    props<{ product: IProduct }>()
);
export const getProductDetailFailure = createAction('[Product] Load Product Detail Failure.', props<{ error: any }>());

export const getHighlightedShowcases = createAction(
    '[Product] Load Highlighted Showcases.',
    props<{ subDomain: string }>()
);
export const getHighlightedShowcasesSuccess = createAction(
    '[Product] Load Highlighted Showcases Success.',
    props<{ showcases: IHighlightedShowcase[] }>()
);
export const getHighlightedShowcasesFailure = createAction(
    '[Product] Load Highlighted Showcases Failure.',
    props<{ error: any }>()
);

export const loadPdfDetailFile = createAction(
    '[Product] Load Pdf Detail File.',
    props<{ subDomain: string; poolKey: string }>()
);
export const loadPdfDetailFilesSuccess = createAction(
    '[Product] Load Pdf Detail File Success.',
    props<{ pdfDetailFile: IPdfDetailFile }>()
);
export const loadPdfDetailFileFailure = createAction(
    '[Product] Load Pdf Detail File Failure.',
    props<{ error: any }>()
);
export const resetPdfDetailFile = createAction('[Product] Reset Pdf Detail File.');

export const createProgramOrder = createAction(
    '[Product] Create Program Order.',
    props<{ subDomain: string; poolKey: string; bookingData: IBookingTour }>()
);
export const createProgramOrderSuccess = createAction(
    '[Product] Create Program Order Successful.',
    props<{ data: IProgramOrderResponse }>()
);
export const createProgramOrderFailure = createAction(
    '[Product] Create Program Order Failure.',
    props<{ error: any }>()
);

export const getOrderProgramDetail = createAction(
    '[Product] Get Program Order Detail.',
    props<{ subDomain: string; key: string }>()
);
export const getOrderProgramDetailSuccess = createAction(
    '[Product] Get Program Order Detail Successful.',
    props<{ data: OrderProgramDetail }>()
);
export const getOrderProgramDetailFailure = createAction(
    '[Product] Get Program Order Detail Failure.',
    props<{ error: any }>()
);
