import { TemplateConfigConfig } from './interfaces/response/template-config-response.interface';

export class TemplateConfig {
    id: number; // Templates id
    templateName: string;

    mainColor: string;
    secondaryColor: string;
    buttonColor: string;
    agencyNameColor: string;
    borderPromotionColor: string;
    domesticBgColor: string;
    recommendBgColor: string;
    packageBgColor: string;
    showcaseBgColor: string;
    galleryBgColor: string;
    countryBgColor: string;
    blogBgColor: string;

    headerBgImageUrl: string;
    promotionBgImageUrl: string;
    recommendBgImageUrl: string;
    packageBgImageUrl: string;
    domesticBgImageUrl: string;
    countryBgImageUrl: string;
    showcaseBgImageUrl: string;
    showcaseIconImageUrl: string;
    galleryBgImageUrl: string;
    blogBgImageUrl: string;

    galleryFontColor: string;
    recommendFontColor: string;
    countryFontColor: string;
    packageFontColor: string;
    domesticFontColor: string;
    showcaseFontColor: string;
    blogFontColor: string;

    orderConfirmButtonText: string;

    deserialize(data: TemplateConfigConfig): TemplateConfig {
        this.id = data.templates_id;
        (this.templateName = data.templates_id === 1 ? 'domestic-a' : 'B1'), (this.mainColor = data.main_color);
        this.secondaryColor = data.secondary_color;
        this.buttonColor = data.button_color;
        this.agencyNameColor = data.agency_name_color;
        this.borderPromotionColor = data.border_promotion_color;
        this.domesticBgColor = data.domestic_bg_color;
        this.recommendBgColor = data.recommend_bg_color;
        this.packageBgColor = data.package_bg_color;
        this.showcaseBgColor = data.showcase_bg_color;
        this.galleryBgColor = data.gallery_bg_color;
        this.countryBgColor = data.country_bg_color;
        this.blogBgColor = data.blog_bg_color;

        this.headerBgImageUrl = data.header_bg_image_url;
        this.promotionBgImageUrl = data.promotion_bg_image_url;
        this.recommendBgImageUrl = data.recommend_bg_image_url;
        this.packageBgImageUrl = data.package_bg_image_url;
        this.domesticBgImageUrl = data.domestic_bg_image_url;
        this.showcaseBgImageUrl = data.showcase_bg_image_url;
        this.showcaseIconImageUrl = data.showcase_icon_image_url;
        this.galleryBgImageUrl = data.gallery_bg_image_url;
        this.countryBgImageUrl = data.country_bg_image_url;
        this.blogBgImageUrl = data.blog_bg_image_url;

        this.galleryFontColor = data.gallery_font_color;
        this.recommendFontColor = data.recommend_font_color;
        this.countryFontColor = data.country_font_color;
        this.packageFontColor = data.package_font_color;
        this.domesticFontColor = data.domestic_font_color;
        this.showcaseFontColor = data.showcase_font_color;
        this.blogFontColor = data.blog_font_color;

        this.orderConfirmButtonText = data.order_confirm_button_text;
        return this;
    }
}
