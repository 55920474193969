import { Component } from '@angular/core';

@Component({
    selector: 'app-b1',
    templateUrl: './b1.component.html',
    styleUrls: ['./b1.component.scss'],
})
export class B1Component {
    constructor() {}
}
