import { createAction, props } from '@ngrx/store';
import { CampaignChaophrayaCruiseShop } from 'src/app/models/product/campaign/campaign-chaophraya-cruise-shop.model';
import { CampaignDetail } from 'src/app/models/product/campaign/campaign-detail.model';
import { CampaignListItem } from 'src/app/models/product/campaign/campaign-list-item.model';
import { ICampaignOrderCreationData } from 'src/app/models/product/campaign/campaign-order-creation-data.model';
import { OrderDetail } from 'src/app/models/product/campaign/order-detail.model';
import { CampaignListingQuery } from 'src/app/models/request/queries/campaign-listing-query';
import { IPaginationResponse } from 'src/app/models/response/pagination-response';
import { CampaignOrderResponse } from 'src/app/modules/products/campaign/models/campaign-order.model';

export enum ActionTypes {
    GET_PAGINATED_CAMPAIGNS = '[Campaign] Get paginated campaigns',
    GET_PAGINATED_CAMPAIGNS_SUCCESS = '[Campaign] Get paginated campaigns successfully',
    GET_PAGINATED_CAMPAIGNS_FAILURE = '[Campaign] Get paginated campaigns unsuccessfully',

    GET_CAMPAIGN_DETAIL = '[Campaign] Get campaign detail',
    GET_CAMPAIGN_DETAIL_SUCCESS = '[Campaign] Get campaign detail successfully',
    GET_CAMPAIGN_DETAIL_FAILURE = '[Campaign] Get campaign detail unsuccessfully',

    CREATE_CAMPAIGN_ORDER = '[Campaign] Create campaign order',
    CREATE_CAMPAIGN_ORDER_SUCCESS = '[Campaign] Create campaign order successfully',
    CREATE_CAMPAIGN_ORDER_OUT_OF_STOCK_FAILURE = '[Campaign] Create campaign order out of stock',
    CREATE_CAMPAIGN_ORDER_ALREADY_ORDER_FAILURE = '[Campaign] Create campaign order email or phone number already order',
    CREATE_CAMPAIGN_ORDER_RESET = '[Campaign] Create campaign order reset',

    GET_ORDER_DETAIL = '[Campaign] Get order detail',
    GET_ORDER_DETAIL_SUCCESS = '[Campaign] Get order detail successfully',
    GET_ORDER_DETAIL_FAILURE = '[Campaign] Get order detail unsuccessfully',

    GET_CHAOPHRAYA_CRUISE_UPDATE = '[Campaign] Get Chaophraya cruise update',
    GET_CHAOPHRAYA_CRUISE_UPDATE_SUCCESS = '[Campaign] Get Chaophraya cruise update success',
    GET_CHAOPHRAYA_CRUISE_UPDATE_FAILURE = '[Campaign] Get Chaophraya cruise update failure',
}

export const getPaginatedCampaigns = createAction(
    ActionTypes.GET_PAGINATED_CAMPAIGNS,
    props<{ subDomain: string; query: CampaignListingQuery }>()
);
export const getPaginatedCampaignsSuccess = createAction(
    ActionTypes.GET_PAGINATED_CAMPAIGNS_SUCCESS,
    props<{ paginatedCampaigns: IPaginationResponse<CampaignListItem[]> }>()
);
export const getPaginatedCampaignsFailure = createAction(
    ActionTypes.GET_PAGINATED_CAMPAIGNS_FAILURE,
    props<{ error: any }>()
);

export const getCampaignDetail = createAction(
    ActionTypes.GET_CAMPAIGN_DETAIL,
    props<{ subDomain: string; campaignId: number }>()
);
export const getCampaignDetailSuccess = createAction(
    ActionTypes.GET_CAMPAIGN_DETAIL_SUCCESS,
    props<{ campaign: CampaignDetail }>()
);
export const getCampaignDetailFailure = createAction(ActionTypes.GET_CAMPAIGN_DETAIL_FAILURE, props<{ error: any }>());

//
export const createCampaignOrder = createAction(
    ActionTypes.CREATE_CAMPAIGN_ORDER,
    props<{ subDomain: string; campaignId: number; data: ICampaignOrderCreationData }>()
);
export const createCampaignOrderSuccess = createAction(
    ActionTypes.CREATE_CAMPAIGN_ORDER_SUCCESS,
    props<{ response: CampaignOrderResponse }>()
);
export const createCampaignOrderOutOfStockFailure = createAction(
    ActionTypes.CREATE_CAMPAIGN_ORDER_OUT_OF_STOCK_FAILURE,
    props<{ response: CampaignOrderResponse }>()
);
export const createCampaignOrderAlreadyOrderFailure = createAction(
    ActionTypes.CREATE_CAMPAIGN_ORDER_ALREADY_ORDER_FAILURE,
    props<{ response: CampaignOrderResponse }>()
);
export const createCampaignOrderReset = createAction(ActionTypes.CREATE_CAMPAIGN_ORDER_RESET);

//
export const getOrderDetail = createAction(ActionTypes.GET_ORDER_DETAIL, props<{ subDomain: string; key: string }>());
export const getOrderDetailSuccess = createAction(
    ActionTypes.GET_ORDER_DETAIL_SUCCESS,
    props<{ order: OrderDetail }>()
);
export const getOrderDetailFailure = createAction(ActionTypes.GET_ORDER_DETAIL_FAILURE, props<{ error: any }>());

//
export const getChaophrayaCruiseUpdate = createAction(
    ActionTypes.GET_CHAOPHRAYA_CRUISE_UPDATE,
    props<{ subDomain: string }>()
);
export const getChaophrayaCruiseUpdateSuccess = createAction(
    ActionTypes.GET_CHAOPHRAYA_CRUISE_UPDATE_SUCCESS,
    props<{ data: CampaignChaophrayaCruiseShop[] }>()
);
export const getChaophrayaCruiseUpdateFailure = createAction(
    ActionTypes.GET_CHAOPHRAYA_CRUISE_UPDATE_FAILURE,
    props<{ error: any }>()
);
