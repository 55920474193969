import { createAction, props } from '@ngrx/store';
import { IMonthRange } from 'src/app/models/month.model';

export const loadActiveMonthRange = createAction(
    '[Month] Load Active Month Range.',
    props<{ subDomain: string; categorySubProductIds: number[] }>()
);
export const loadActiveMonthRangeSuccess = createAction(
    '[Month] Load Active Month Range Success.',
    props<{ activeMonthRange: IMonthRange }>()
);
export const loadActiveMonthRangeFailure = createAction(
    '[Month] Load Active Month Range Failure.',
    props<{ error: any }>()
);
