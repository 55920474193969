import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as LeadAction from './lead.actions';
import { LeadService, IResultForCreateLead } from 'src/app/services/lead.service';

@Injectable()
export class LeadEffects {
    constructor(private actions$: Actions, private leadService: LeadService, private router: Router) {}

    leadCreation = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(LeadAction.leadCreation),
                mergeMap((action) => {
                    return this.leadService.createLead(action.subDomain, action.body).pipe(
                        map((result: IResultForCreateLead) => {
                            if (result.success) {
                                return LeadAction.leadCreationSuccess({ result });
                            }
                            return LeadAction.leadCreationFailure({ result });
                        }),
                        catchError((error) => {
                            return of(
                                LeadAction.leadCreationFailure({
                                    result: {
                                        success: false,
                                        error,
                                    },
                                })
                            );
                        })
                    );
                })
            )
    );
}
