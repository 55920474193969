import { Component } from '@angular/core';

@Component({
    selector: 'app-header-gift-a-wow',
    templateUrl: './header-gift-a-wow.component.html',
    styleUrls: ['./header-gift-a-wow.component.scss'],
})
export class HeaderGiftAWowComponent {
    openNav: boolean = false;

    subNavMenuFestival: boolean = false;
    subNavMenuEvent: boolean = false;
    subNavMenuGift: boolean = false;

    constructor() {}

    openNavMenu(): void {
        this.openNav = !this.openNav;
        this.closeAllSubNavMenu();
    }

    openNavSubMenu(subNavSlug: string): void {
        this.closeAllSubNavMenu();

        if (subNavSlug === 'festival') {
            this.subNavMenuFestival = true;
        } else if (subNavSlug === 'event') {
            this.subNavMenuEvent = true;
        } else if (subNavSlug === 'gift') {
            this.subNavMenuGift = true;
        }
    }

    private closeAllSubNavMenu(): void {
        this.subNavMenuFestival = false;
        this.subNavMenuEvent = false;
        this.subNavMenuGift = false;
    }
}
