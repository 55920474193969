import { AgencyInfo } from '../models/agency.model';
import { IProductBox } from '../models/product-box.model';
import { IHighlightedShowcase } from '../models/product.model';
import { ProductSearchResult } from '../models/product-search-result.model';
import { IDomainFilters } from '../models/interfaces/domain-filters.interface';
import { environment } from 'src/environments/environment';

export class UrlHelper {
    public static getFriendlyUrlForPortfolio(id: number, friendlyName: string): string {
        return this.createFriendlyUrl(id, friendlyName);
    }

    public static getFriendlyUrlForBlog(id: number, friendlyName: string): string {
        return this.createFriendlyUrl(id, friendlyName);
    }

    public static getFriendlyUrlForCampaign(id: number, campaignName: string): string {
        return this.createFriendlyUrl(id, campaignName);
    }

    private static createFriendlyUrl(id: number, friendlyName: string): string {
        const friendlyUrl = `${id}-${friendlyName}`;

        return this.encodeAndShortenUrl(friendlyUrl);
    }

    public static getFriendlyUrlForPackageCardDetail(product: ProductSearchResult, agencyInfo: AgencyInfo): string {
        let countryAndProvinceSlug = '';
        product.destinations.map((destination) => {
            if (destination.nameEn) {
                return (countryAndProvinceSlug = countryAndProvinceSlug + ' ' + destination.nameEn.toLocaleLowerCase());
            }

            return countryAndProvinceSlug;
        });
        countryAndProvinceSlug = countryAndProvinceSlug.trim().split(' ').join('-');
        return UrlHelper.friendlyUrlGenerator(
            agencyInfo.productCode,
            product.ownerChannel,
            product.categorySubProductId,
            product.productId,
            product.name,
            countryAndProvinceSlug
        );
    }

    public static getFriendlyUrlForProductBox(product: IProductBox, agencyInfo: AgencyInfo): string {
        if (product) {
            const friendlyUrl = this.friendlyUrlGenerator(
                agencyInfo.productCode,
                product.ownerChannel,
                product.categorySubProductId,
                product.id,
                product.name
            );

            if (+product.categorySubProductId === 6) {
                return '/thailand-tour/' + friendlyUrl;
            } else {
                return '/outbound-tour/' + friendlyUrl;
            }
        } else {
            return null;
        }
    }

    public static getFriendlyUrlForShowcase(agencyInfo: AgencyInfo, showcase: IHighlightedShowcase): string {
        if (!showcase) {
            return null;
        }

        const friendlyUrl = this.friendlyUrlGenerator(
            agencyInfo ? agencyInfo.productCode : '',
            showcase.ownerChannel,
            +showcase.categorySubProductsId,
            showcase.productsId,
            showcase.productName
        );

        if (+showcase.categorySubProductsId === 6) {
            return '/thailand-tour/' + friendlyUrl;
        } else {
            return '/outbound-tour/' + friendlyUrl;
        }
    }

    private static friendlyUrlGenerator(
        productCode: string,
        ownerChannel: string,
        categorySubProductId: number,
        id: number,
        name: string,
        allCountryAndProvince: string = ''
    ): string {
        const separator = '-';
        const agencyCode = productCode;
        const productKey = (ownerChannel ? ownerChannel.toUpperCase() : '') + (categorySubProductId || '') + (id || '');
        const productName = name ? name.replace(/ /g, separator) : '';
        const destination = allCountryAndProvince ? allCountryAndProvince + separator : '';
        const friendlyUrl = agencyCode + separator + productKey + separator + destination + productName;

        return this.encodeAndShortenUrl(friendlyUrl);
    }

    private static encodeAndShortenUrl(friendlyUrl: string): string {
        const maximumCharactor = 50;
        const separator = '-';

        // remove invalid chars for url
        friendlyUrl = friendlyUrl.replace(/[|;$%@"<>()+,\[\]\/\\]/g, '');
        friendlyUrl = friendlyUrl
            .normalize('NFD') // Change diacritics
            .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
            .replace(/\s+/g, '-') // Change whitespace to dashes
            .toLowerCase() // Change to lowercase
            .replace(/&/g, '-and-') // Replace ampersand
            // .replace(/[^a-z0-9\-]/g, '') // Remove anything that is not a letter, number or dash
            .replace(/-+/g, '-') // Remove duplicate dashes
            .replace(/^-*/, '') // Remove starting dashes
            .replace(/-*$/, ''); // Remove trailing dashes

        if (friendlyUrl.length > maximumCharactor) {
            friendlyUrl = friendlyUrl.slice(0, friendlyUrl.slice(0, maximumCharactor).lastIndexOf(separator));
        }

        return friendlyUrl;
    }

    public static lineSharingLink(url: string): string {
        url = encodeURI(url);
        return 'https://social-plugins.line.me/lineit/share?url=' + url;
    }

    public static facebookSharingLink(url: string): string {
        url = encodeURI(url);
        return 'https://www.facebook.com/sharer/sharer.php?amp;src=sdkpreparse&u=' + url;
    }

    public static copyLink(url: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    public static getQueryString(obj: any): string {
        const params = new URLSearchParams();
        for (const key in obj) {
            let param = obj[key];
            if (typeof param === 'object') {
                param = JSON.stringify(param);
            }

            if (param !== undefined) {
                params.set(key, param);
            }
        }

        return params.toString();
    }

    public static getDomainFilters(host: string): IDomainFilters {
        const domainFilters: IDomainFilters = {
            domain: null,
            subDomain: null,
        };

        const subDomainRegex = /[a-zA-Z\d]+.tourwowmall.com/g;
        const matchSubdomain = subDomainRegex.exec(host);

        if (matchSubdomain && matchSubdomain.length > 0) {
            domainFilters.subDomain = matchSubdomain[0].split('.')[0];
        }

        const domainRegex = /^(?:https?:\/\/)?(?:www\.)?(.*)$/g;
        const matchDomain = domainRegex.exec(host);

        if (matchDomain && matchDomain.length > 0) {
            domainFilters.domain = matchDomain[1];
        }

        return domainFilters;
    }
}
