export const ENDPOINTS = {
    AGENCY: {
        INFO: 'twm/info',
        ABOUT_US_DETAILS: 'twm/{subdomain}/about-us',
        CUSTOM_CONTENTS: 'twm/{subdomain}/custom_contents/{custom_content_id}',
        SEO_CONFIGS: 'twm/seo-configs',
    },
    BANNER: {
        CONFIG: 'twm/{subdomain}/banners',
    },
    COUNTRY: {
        ACTIVE: 'twm/{subdomain}/countries/active',
        ALL: 'countries',
        RECOMMEND: 'twm/{subdomain}/countries/recommend',
    },
    PROVINCE: {
        ACTIVE: 'twm/{subdomain}/provinces/active',
        ALL: 'provinces',
    },
    MONTH: {
        ACTIVE: 'twm/{subdomain}/months/active',
    },
    PRODUCT: {
        LISTING: 'twm/{subdomain}/products/list',
        PAGINATED_LISTING: 'twm/{subdomain}/products/list/paginated',
        DETAIL: 'twm/{subdomain}/products/{key}',
        PDF_DETAIL_FILE: 'twm/{subdomain}/products/{key}/pdf_detail_file',
        HIGHLIGHTED_SHOWCASES: 'twm/{subdomain}/products/showcases/highlighted/listing',
        CREATE_ORDER: 'twm/{subdomain}/products/{pool_key}/orders',
    },
    LEAD: {
        CREATE: 'twm/{subdomain}/leads',
    },
    REGION: {
        GET_PROVINCE_RECCOMEND: 'twm/{subdomain}/provinces/recommend',
    },
    TEMPLATE: {
        CONFIG: 'twm/{subdomain}/template',
    },
    PORTFOLIO: {
        DETAIL: 'twm/{subdomain}/portfolios/{portfolio_id}',
        PAGINATED_LISTING: 'twm/{subdomain}/portfolios/listing/paginated',
    },
    BLOG: {
        DETAIL: 'twm/{subdomain}/blogs/{blog_id}',
        PAGINATED_LISTING: 'twm/{subdomain}/blogs/listing/paginated',
    },
    CAMPAIGN: {
        PAGINATED_LISTING: 'twm/{subdomain}/campaigns/paginated',
        DETAIL: 'twm/{subdomain}/campaigns/{campaign_id}',
        CREATE_ORDER: 'twm/{subdomain}/campaigns/{campaign_id}/orders',
        GET_ORDER_DETAIL: 'twm/{subdomain}/campaigns/orders/by-key/{key}',
        GET_CHAOPHRAYA_CRUISE_UPDATE: 'twm/{subdomain}/chaophraya_cruises/shops',
    },
    ORDER: {
        GET_ORDER_PRODUCT_CATEGORY_BY_KEY: '/twm/{subdomain}/orders/by-key/{key}',
        GET_ORDER_PRODUCT_DETAIL: '/twm/{subdomain}/products/orders/by-key/{key}',
    },
};
