import { SeoConfigs } from './../../models/seo-configs.model';
import { Action, createReducer, on, State } from '@ngrx/store';
import * as AgencyActions from './agency.actions';
import { AgencyInfo, IAboutUsResponse } from '../../models/agency.model';
import { ICustomContentDetail } from 'src/app/models/custom-content.model';

export interface AgencyState {
    agencyInfo: AgencyInfo;
    errorMessage: string | any;
    aboutUsInfo: IAboutUsResponse;
    aboutUsErrorMessage: any;
    customContentDetail: ICustomContentDetail;
    customContentDetailErrorMessage: any;
    blogListing: any;
    blogListingError: any;
    blogDetail: any;
    blogDetailError: any;
    seoConfigs: SeoConfigs;
    seoConfigsError: string;
}

export const initialState: AgencyState = {
    agencyInfo: null,
    errorMessage: null,
    aboutUsInfo: null,
    aboutUsErrorMessage: null,
    customContentDetail: null,
    customContentDetailErrorMessage: null,
    blogListing: null,
    blogListingError: null,
    blogDetail: null,
    blogDetailError: null,
    seoConfigs: null,
    seoConfigsError: null,
};

export const agencyReducer = createReducer(
    initialState,

    on(AgencyActions.setSeoConfig, (state, action) => ({ ...state, seoConfigs: action.data })),
    on(AgencyActions.setAgencyInfo, (state, action) => ({ ...state, agencyInfo: action.data })),

    on(AgencyActions.loadAboutUsInfo, (state, action) => ({ ...state })),
    on(AgencyActions.loadAboutUsInfoSuccess, (state, action) => ({ ...state, aboutUsInfo: action.data })),
    on(AgencyActions.loadAboutUsInfoFailure, (state, action) => ({ ...state, aboutUsErrorMessage: action.error })),

    on(AgencyActions.loadCustomContentDetail, (state, action) => ({
        ...state,
        customContentDetail: null,
        customContentDetailErrorMessage: null,
    })),
    on(AgencyActions.loadCustomContentDetailSuccess, (state, action) => ({
        ...state,
        customContentDetail: action.data,
        customContentDetailErrorMessage: null,
    })),
    on(AgencyActions.loadCustomContentDetailFailure, (state, action) => ({
        ...state,
        customContentDetailErrorMessage: action.error,
    })),
    on(AgencyActions.loadCustomContentDetailReset, (state, action) => ({
        ...state,
        customContentDetail: null,
        customContentDetailErrorMessage: null,
    })),

    on(AgencyActions.loadBlogListing, (state, action) => ({ ...state })),
    on(AgencyActions.loadBlogListingSuccess, (state, action) => ({ ...state, blogListing: action.blogListing })),
    on(AgencyActions.loadBlogListingFailure, (state, action) => ({ ...state, blogListingError: action.error })),

    on(AgencyActions.loadBlogDetail, (state, action) => ({ ...state })),
    on(AgencyActions.loadBlogDetailSuccess, (state, action) => ({ ...state, blogDetail: action.blogDetail })),
    on(AgencyActions.loadBlogDetailFailure, (state, action) => ({ ...state, blogDetailError: action.error }))
);

export function reducer(state: AgencyState | undefined, action: Action) {
    return agencyReducer(state, action);
}
