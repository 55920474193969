import { CountryService } from '../../services/country.service';
import { ICountry, ICountryContinents, ICountryContinent } from './../../models/country.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of, Observable, pipe } from 'rxjs';

import * as CountryAction from './country.actions';
import { ICountryRecommend } from 'src/app/models/country-recommend.model';

@Injectable()
export class CountryEffects {
    constructor(private actions$: Actions, private countryService: CountryService, private router: Router) {}

    loadActiveCountries = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(CountryAction.loadActiveCountries),
                concatMap((action) => {
                    return this.countryService.getAllActive(action.subDomain).pipe(
                        map((response) => {
                            if (response.status !== 'success') {
                                return CountryAction.loadActiveCountriesFailure(null);
                            }
                            const data = response.data;
                            const countryContinents: ICountryContinents = {
                                continents: data.continents.map((continent) => {
                                    const cn: ICountryContinent = {
                                        id: continent.id,
                                        nameEn: continent.name_en,
                                        nameTh: continent.name_th,
                                        countries: continent.countries.map((country) => {
                                            const c: ICountry = {
                                                id: country.id,
                                                nameEn: country.name_en,
                                                nameTh: country.name_th,
                                                code: country.country_code,
                                                iconUrl: country.icon_url,
                                                slug: country.slug,
                                            };
                                            return c;
                                        }),
                                    };
                                    return cn;
                                }),
                            };

                            return CountryAction.loadActiveCountriesSuccess({ data: countryContinents });
                        }),
                        catchError((error) => {
                            return of(CountryAction.loadActiveCountriesFailure(error));
                        })
                    );
                })
            )
    );

    loadAllCountry = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(CountryAction.loadAllCountry),
                switchMap((action) => {
                    return this.countryService.getAll().pipe(
                        map((resp) => {
                            const allCountry: ICountry[] = resp.data.map((data) => {
                                return {
                                    id: data.id,
                                    nameTh: data.name_th,
                                    nameEn: data.name_en,
                                    code: data.country_code,
                                    iconUrl: data.icon_url,
                                    slug: data.slug,
                                };
                            });

                            return CountryAction.loadAllCountrySuccess({ countries: allCountry });
                        }),
                        catchError((error) => of(CountryAction.loadAllCountryFailure(error)))
                    );
                })
            )
    );

    loadRecommendCountry = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(CountryAction.loadCountryRecommend),
                switchMap((action) => {
                    return this.countryService.getRecommend(action.subDomain).pipe(
                        map((resp) => {
                            const countryRecommend: ICountryRecommend[] = resp.data.map((data) => {
                                return {
                                    countryId: data.country.id,
                                    countryNameTH: data.country.name_th,
                                    countryNameEN: data.country.name_en,
                                    imageUrl: data.image_url,
                                    ordinal: data.ordinal,
                                };
                            });

                            return CountryAction.loadCountryRecommendSuccess({ countriesRecommend: countryRecommend });
                        }),
                        catchError((error) => of(CountryAction.loadCountryRecommendFailure(error)))
                    );
                })
            )
    );
}
