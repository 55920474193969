import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import * as moment from 'moment';

import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class MonthService extends BaseHttpService {
    getActiveMonthRange(subDomain: string, categorySubProductIds: number[] = null): Observable<any> {
        const filters = { category_sub_product_ids: categorySubProductIds };
        const url =
            environment.apiMallUrl +
            ENDPOINTS.MONTH.ACTIVE.replace('{subdomain}', subDomain) +
            '?filters=' +
            JSON.stringify(filters);
        return of({
            data: {
                start_month: moment().startOf('month').format('YYYY-MM-DD'),
                end_month: moment().add(11, 'M').startOf('month').format('YYYY-MM-DD'),
            },
            message: 'Get month range.',
            status: 'success',
        });
        // return this.get(url);
    }
}
