<div class="submenu__frame d-flex p-3">
    <ng-container *ngFor="let continent of (countryContinents$ | async)?.continents">
        <div class="submenu__col list_unstyled" [class.single-row]="!continent.id">
            <div class="submenu__head">ทัวร์{{ continent.nameTh }}</div>
            <ul class="submenu__list flag row no-gutters list-unstyled">
                <ng-container *ngFor="let country of continent?.countries">
                    <li *ngIf="country.id !== 1" [ngClass]="continent.id ? 'col-6' : 'col-12'">
                        <a
                            *ngIf="continent.nameEn !== 'Europe'"
                            [routerLink]="'/' + country.slug + '-tour'"
                            (click)="linkClick.next()"
                            class="d-flex justify-content align-items-center"
                        >
                            <img
                                class="d-block mr-2"
                                [src]="country.iconUrl"
                                width="35"
                                height="24"
                                [alt]="country.nameEn"
                            />
                            <span> ทัวร์{{ country.nameTh }}</span>
                        </a>
                        <a
                            *ngIf="continent.nameEn === 'Europe'"
                            [routerLink]="'/europe-tour/' + country.slug + '-tour'"
                            (click)="linkClick.next()"
                            class="d-flex justify-content align-items-center"
                        >
                            <img
                                class="d-block mr-2"
                                [src]="country.iconUrl"
                                width="35"
                                height="24"
                                [alt]="country.nameEn"
                            />
                            <span> ทัวร์{{ country.nameTh }}</span>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </ng-container>
</div>
