import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

interface IHttpServiceOptions {
    forPlatform: 'browser' | 'server';
}

@Injectable({
    providedIn: 'root',
})
export class BaseHttpService {
    protected defaultOptions: IHttpServiceOptions = {
        forPlatform: 'browser',
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) {}

    protected get<T>(url: string, options?: Partial<IHttpServiceOptions>): Observable<T> {
        if (this.canSendRequest(options)) {
            return this.http.get<T>(url);
        } else {
            return EMPTY;
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected post<T>(url: string, body: any, options?: Partial<IHttpServiceOptions>): Observable<T> {
        if (this.canSendRequest(options)) {
            return this.http.post<T>(url, body);
        } else {
            return EMPTY;
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected put<T>(url: string, body: any, options?: Partial<IHttpServiceOptions>): Observable<T> {
        if (this.canSendRequest(options)) {
            return this.http.put<T>(url, body);
        } else {
            return EMPTY;
        }
    }

    protected delete<T>(url: string, options?: Partial<IHttpServiceOptions>): Observable<T> {
        if (this.canSendRequest(options)) {
            return this.http.delete<T>(url);
        } else {
            return EMPTY;
        }
    }

    private canSendRequest(options?: Partial<IHttpServiceOptions>) {
        const actualOptions = this.parseOptions(options);
        if (actualOptions.forPlatform === 'browser' && isPlatformBrowser(this.platformId)) {
            return true;
        } else if (actualOptions.forPlatform === 'server') {
            return true;
        } else {
            return false;
        }
    }

    private parseOptions(options?: Partial<IHttpServiceOptions>): IHttpServiceOptions {
        return { ...this.defaultOptions, ...options };
    }
}
