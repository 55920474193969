import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBanner from './banner-store.module';
import * as state from './banner.reducer';

import { IBanner } from 'src/app/models/banner.model';

export const selectBannerState = createFeatureSelector<state.BannerState>(fromBanner.FEATURE_NAME);

export const selectBannerConfig = createSelector(selectBannerState, (state: state.BannerState) => {
    const banner: IBanner[] = [];
    for (let i = 0; i < state.bannerConfig.length; i++) {
        if (state.bannerConfig[i].imageUrl != '') {
            banner.push(state.bannerConfig[i]);
        }
    }
    return banner;
});
