<ng-container *ngIf="themeConfig$ | async as themeConfig">
    <!--  -->
    <div
        class="header__top-mobile d-block d-lg-none"
        [ngStyle]="{
            'background-color': themeConfig.mainColor,
            color: themeConfig.agencyNameColor
        }"
    >
        <div class="d-flex align-items-center">
            <a
                href="#"
                data-toggle="modal"
                data-target="#menuleft"
                class="menus mx-3"
                (click)="toggleMobileMenu = !toggleMobileMenu"
            >
                <img src="../assets/images/ico-menu.svg" width="28" height="24"
            /></a>
            <a *ngIf="isNotHome" (click)="goBack()" class="menus mx-3"
                ><img src="../assets/images/ico-back.svg" width="28" height="24"
            /></a>

            <div class="user__profile-mobile">
                <div *ngIf="agencyInfo" class="user__image">
                    <ng-container *ngIf="agencyInfo.logoDisplayMobileUrl; else noLogoDisplayMobileTemplete">
                        <img [src]="agencyInfo.logoDisplayMobileUrl" style="object-fit: cover" />
                    </ng-container>
                    <ng-template #noLogoDisplayMobileTemplete>
                        <img [src]="agencyInfo.logoDisplayUrl" style="object-fit: cover" />
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <!-- mobile menu -->
    <div class="header__mobile--menu d-flex" *ngIf="toggleMobileMenu">
        <div
            class="backdrop d-lg-none"
            [class.d-none]="!toggleMobileMenu"
            (click)="toggleMobileMenu = false; toggleMobileSubMenu = false"
        ></div>
        <div
            class="mobile__menu--left"
            [ngStyle]="{
                'background-color': themeConfig.secondaryColor,
                color: themeConfig.agencyNameColor
            }"
        >
            <div
                class="d-flex mobile__title--menu px-2 py-3"
                [ngStyle]="{
                    'background-color': themeConfig.mainColor
                }"
            >
                <a href="javascript: void(0)" (click)="toggleMobileMenu = !toggleMobileMenu">
                    <img src="/assets/images/ico-close-w.svg" width="14" height="14" />
                </a>
                <div class="ml-2">
                    <ng-container *ngIf="agencyInfo">
                        <div class="mobile__agency--name">{{ agencyInfo.displayName }}</div>
                        <span class="mobile__agency--license">เลขที่ใบอนุญาต {{ agencyInfo?.licenseNumber }}</span>
                    </ng-container>
                </div>
            </div>

            <div class="d-flex mobile__main--menu p-1">
                <ul class="list-group list-group-flush w-100">
                    <li class="list-group-item">
                        <a [routerLink]="['/']">หน้าแรก</a>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.TOUR_TYPE_OUTBOUND.slug)" class="list-group-item">
                        <a [routerLink]="['/outbound-tour']">{{ MENU.TOUR_TYPE_OUTBOUND.nameTH }}</a>
                        <div class="mobile__sub--menu">
                            <div class="d-flex" [class.active]="activeSubmenu === 3" (click)="onMobileSubmenuClick(3)">
                                <a class="mr-auto" href="javascript: void(0)">ทัวร์เอเชีย</a>
                                <img class="mt-2" src="/assets/images/ico-arrow-right.svg" width="5" height="8" />
                            </div>
                            <div class="d-flex" [class.active]="activeSubmenu === 5" (click)="onMobileSubmenuClick(5)">
                                <a class="mr-auto" href="javascript: void(0)">ทัวร์ยุโรป</a>
                                <img class="mt-2" src="/assets/images/ico-arrow-right.svg" width="5" height="8" />
                            </div>
                            <div
                                class="d-flex"
                                [class.active]="activeSubmenu === null"
                                (click)="onMobileSubmenuClick(null)"
                            >
                                <a class="mr-auto" href="javascript: void(0)">ทัวร์ทวีปอื่นๆ</a>
                                <img class="mt-2" src="/assets/images/ico-arrow-right.svg" width="5" height="8" />
                            </div>
                        </div>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.TOUR_TYPE_DOMESTIC_PACKAGE.slug)" class="list-group-item">
                        <a [routerLink]="['/thailand-tour']">{{ MENU.TOUR_TYPE_DOMESTIC_PACKAGE.nameTH }}</a>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.TOUR_TYPE_OUTBOUND_PACKAGE.slug)" class="list-group-item">
                        <a [routerLink]="['/outbound-tour/package']">{{ MENU.TOUR_TYPE_OUTBOUND_PACKAGE.nameTH }}</a>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.CAMPAIGN_TYPE_TRAVEL_PACKAGE.slug)" class="list-group-item">
                        <a [routerLink]="['/travel-package']">{{ MENU.CAMPAIGN_TYPE_TRAVEL_PACKAGE.nameTH }}</a>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.CAMPAIGN_TYPE_CHAOPHRAYA_CRUISE.slug)" class="list-group-item">
                        <a [routerLink]="['/chaophraya-cruise']">{{ MENU.CAMPAIGN_TYPE_CHAOPHRAYA_CRUISE.nameTH }}</a>
                    </li>
                    <li class="list-group-item" style="cursor: pointer" *ngIf="campaignCategory?.isAllowed">
                        <a [class.active]="activeSubmenu === 11" (click)="onMobileSubmenuClick(11)">เวาเชอร์สินค้า</a>
                        <img class="mt-2 float-right" src="/assets/images/ico-arrow-right.svg" width="5" height="8" />
                    </li>
                    <ng-container *ngFor="let customContent of agencyInfo.customContents">
                        <li class="list-group-item" *ngIf="customContent.isActive">
                            <a [routerLink]="['/', customContent.menuNameTh]">
                                {{ customContent.menuNameTh }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div>
            <div class="d-flex mobile__content--menu p-1">
                <ul class="list-group list-group-flush w-100">
                    <li *ngIf="showBlog" class="list-group-item">
                        <a routerLink="/blog">บทความ</a>
                    </li>
                    <li *ngIf="showPortfolio" class="list-group-item">
                        <a routerLink="/gallery">ผลงานที่ผ่านมา</a>
                    </li>
                    <li class="list-group-item">
                        <a routerLink="/about-us">เกี่ยวกับเรา</a>
                    </li>
                </ul>
            </div>
            <div class="d-flex mobile__contact--menu p-1">
                <span class="m-2">
                    <a href="javascript: void(0)"
                        ><img src="/assets/images/ico-facebook-w.svg" width="24" height="24"
                    /></a>
                </span>
                <span class="m-2">
                    <a href="javascript: void(0)"><img src="/assets/images/ico-line-w.svg" width="24" height="24" /></a>
                </span>
                <span class="m-2">
                    <a href="javascript: void(0)"><img src="/assets/images/ico-mail-w.svg" width="24" height="24" /></a>
                </span>
                <span class="m-2">
                    <a href="javascript: void(0)"><img src="/assets/images/ico-call-w.svg" width="24" height="24" /></a>
                </span>
            </div>
        </div>
        <div class="mobile__menu--right" *ngIf="toggleMobileSubMenu">
            <!-- Outbound -->
            <ng-container *ngFor="let continent of (countryContinents$ | async)?.continents">
                <div class="mobile__sub--content m-4" [class.active]="activeSubmenu === continent.id">
                    <h6>ทัวร์{{ continent.nameTh }}</h6>
                    <ul class="list-group list-group-flush w-100">
                        <ng-container *ngFor="let country of continent?.countries">
                            <li *ngIf="country.id !== 1" class="list-group-item">
                                <a (click)="toggleMobileMenu = false; toggleMobileSubMenu = false" [routerLink]="'/' + country.slug + '-tour'"
                                    >ทัวร์{{ country.nameTh }}</a
                                >
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </ng-container>

            <!-- Domestic -->
            <div class="mobile__sub--content m-4" [class.active]="activeSubmenu === 1">
                <ul class="list-group list-group-flush w-100">
                    <ng-container *ngFor="let province of provinces">
                        <li class="list-group-item">
                            <a (click)="toggleMobileMenu = false; toggleMobileSubMenu = false" [routerLink]="'/' + province.slug + '-tour'"
                                >ทัวร์{{ province.nameTh }}</a
                            >
                        </li>
                    </ng-container>
                </ul>
            </div>

            <!-- Voucher -->
            <div class="mobile__sub--content m-4" [class.active]="activeSubmenu === 11">
                <ul class="list-group list-group-flush w-100">
                    <ng-container *ngFor="let subCat of campaignCategory.productSubCategories">
                        <li class="list-group-item" *ngIf="subCat.isAllowed">
                            <a (click)="toggleMobileMenu = false; toggleMobileSubMenu = false" [routerLink]="['/', subCat.slug]">{{
                                subCat.nameTh
                            }}</a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>

    <!-- desktop menu -->
    <div
        class="header__top d-none d-lg-block"
        [ngStyle]="{
            'background-color': (displayBanner$ | async) ? 'transparent' : themeConfig.secondaryColor,
            color: themeConfig.agencyNameColor
        }"
    >
        <div class="container d-flex align-items-center justify-content-between">
            <!-- user profile -->
            <a [routerLink]="['/']" class="user__profile position-absolute">
                <div *ngIf="agencyInfo" class="user__image mr-3">
                    <img
                        id="logo"
                        [src]="agencyInfo.logoDisplayUrl"
                        style="object-fit: cover"
                        width="100%"
                        height="100%"
                    />
                </div>
            </a>
            <div *ngIf="agencyInfo" class="company__name">{{ agencyInfo.displayName }}</div>
            <ul class="header__menu header__menu--left list-unstyled d-inline-flex mb-0">
                <li class="nav-item">
                    <a [routerLink]="['/']" class="nav-link text-center">หน้าแรก</a>
                </li>
                <li *ngIf="showBlog" class="nav-item">
                    <a routerLink="/blog" class="nav-link text-center">บทความ</a>
                </li>
                <li *ngIf="showPortfolio" class="nav-item">
                    <a routerLink="/gallery" class="nav-link text-center">ผลงานที่ผ่านมา</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/about-us" class="nav-link text-center">เกี่ยวกับเรา</a>
                </li>
            </ul>
            <ul class="header__menu header__menu--right list-unstyled d-inline-flex mb-0">
                <li class="pr-md-3 text-cert">
                    <span *ngIf="agencyInfo" class="small mr-1">เลขที่ใบอนุญาต</span>
                    {{ agencyInfo?.licenseNumber }}
                </li>
                <li class="d-inline-flex">
                    <a
                        [attr.href]="agencyInfo?.facebookUrl || null"
                        [attr.target]="agencyInfo?.facebookUrl ? '_blank' : null"
                        class="mr-2"
                        ><img src="../assets/images/ico-facebook-w.svg" width="25" height="25"
                    /></a>
                    <div class="line_id-dropdown dropdown position-relative">
                        <a href="#" class="line_id dropdown-toggle" data-toggle="dropdown">
                            <img src="../assets/images/ico-line-w.svg" width="25" height="25" />
                        </a>
                        <div class="line_id-content dropdown-menu line-qr">
                            <div class="txt_id-title">ไอดีไลน์</div>
                            <div class="txt_id-popover">{{ agencyInfo?.lineId }}</div>
                            <img [src]="agencyInfo?.lineQrUrl" width="130" height="130" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div
        #header_bottom
        class="header__bottom d-none d-lg-block"
        [ngStyle]="{
            'background-color': themeConfig.mainColor,
            color: themeConfig.agencyNameColor
        }"
    >
        <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="container">
                <ul class="navbar-nav mr-auto header__menu">
                    <li *ngIf="logoNotVisible" class="nav-item active">
                        <a [routerLink]="['/']" class="nav-link">หน้าแรก</a>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.TOUR_TYPE_OUTBOUND.slug)" class="nav-item dropdown">
                        <a [routerLink]="['/outbound-tour']" class="nav-link nav-link dropdown-toggle">
                            {{ MENU.TOUR_TYPE_OUTBOUND.nameTH }}
                        </a>
                        <div class="dropdown-menu" [ngClass]="{ 'd-none': hideSubMenu }">
                            <app-countries-submenu (linkClick)="onSubMenuClick()"></app-countries-submenu>
                        </div>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.TOUR_TYPE_DOMESTIC_PACKAGE.slug)" class="nav-item dropdown">
                        <a [routerLink]="['/thailand-tour']" class="nav-link nav-link dropdown-toggle">
                            {{ MENU.TOUR_TYPE_DOMESTIC_PACKAGE.nameTH }}
                        </a>
                        <div class="dropdown-menu" [ngClass]="{ 'd-none': hideSubMenu }">
                            <app-provinces-submenu (linkClick)="onSubMenuClick()"></app-provinces-submenu>
                        </div>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.TOUR_TYPE_OUTBOUND_PACKAGE.slug)" class="nav-item">
                        <a [routerLink]="['/outbound-tour/package']" class="nav-link">
                            {{ MENU.TOUR_TYPE_OUTBOUND_PACKAGE.nameTH }}
                        </a>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.CAMPAIGN_TYPE_TRAVEL_PACKAGE.slug)" class="nav-item">
                        <a [routerLink]="['/travel-package']" class="nav-link">
                            {{ MENU.CAMPAIGN_TYPE_TRAVEL_PACKAGE.nameTH }}
                        </a>
                    </li>
                    <li *ngIf="getIsShownMenuBySlug(MENU.CAMPAIGN_TYPE_CHAOPHRAYA_CRUISE.slug)" class="nav-item">
                        <a [routerLink]="['/chaophraya-cruise']" class="nav-link">
                            {{ MENU.CAMPAIGN_TYPE_CHAOPHRAYA_CRUISE.nameTH }}
                        </a>
                    </li>
                    <li *ngIf="campaignCategory?.isAllowed" class="nav-item dropdown">
                        <a [routerLink]="['/voucher']" class="nav-link nav-link dropdown-toggle"> เวาเชอร์สินค้า </a>
                        <div class="dropdown-menu dropdown-menu-center p-0">
                            <app-vouchers-submenu></app-vouchers-submenu>
                        </div>
                    </li>
                    <!-- CUSTOM CONTENT MENU -->
                    <ng-container *ngFor="let customContent of agencyInfo?.customContents">
                        <li *ngIf="customContent.isActive" class="nav-item">
                            <a class="nav-link" [routerLink]="['/', customContent.menuNameTh]">
                                {{ customContent.menuNameTh }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
                <!--  -->
                <div #logo class="powered-by">
                    <img
                        src="/assets/images/logo-powered-by-twm.png"
                        width="auto"
                        height="40"
                        alt="Tourwow Mall Logo: {{ appVersion }}"
                    />
                </div>
            </div>
        </nav>
    </div>

    <!-- Carousel Banner -->
    <ng-container *ngIf="displayBanner$ | async as displayBanner">
        <div
            *ngIf="displayBanner"
            id="home_image"
            class="carousel slide d-none d-lg-block"
            data-ride="carousel"
            #desktop_carousel
            [ngStyle]="{ 'background-color': themeConfig.secondaryColor }"
        >
            <ol class="carousel-indicators">
                <ng-container *ngFor="let banner of bannerConfig$ | async; let i = index">
                    <li data-target="#home_image" [attr.data-slide-to]="i" [ngClass]="{ active: i === 0 }"></li>
                </ng-container>
            </ol>
            <div class="carousel-inner">
                <ng-container *ngFor="let banner of bannerConfig$ | async; let i = index">
                    <div *ngIf="banner.imageUrl" class="carousel-item" [ngClass]="{ active: i === 0 }">
                        <a [attr.href]="banner.linkUrl || null">
                            <img class="d-block w-100" [src]="banner.imageUrl" alt="First slide" />
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>

        <div
            *ngIf="displayBanner"
            id="home_image2"
            class="position-relative d-lg-none mobile-carousel"
            data-ride="carousel"
        >
            <ol class="carousel-indicators">
                <ng-container *ngFor="let banner of bannerConfig$ | async; let i = index">
                    <li data-target="#home_image" [attr.data-slide-to]="i" [ngClass]="{ active: i === 0 }"></li>
                </ng-container>
            </ol>
            <div class="carousel-inner">
                <ng-container *ngFor="let banner of bannerConfig$ | async; let i = index">
                    <div *ngIf="banner.imageUrl" class="carousel-item" [ngClass]="{ active: i === 0 }">
                        <a [attr.href]="banner.linkUrl || null">
                            <img
                                class="d-block w-100"
                                [src]="banner.imageMobileUrl || banner.imageUrl"
                                alt="First slide"
                            />
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-container>
