<!-- Campaign Detail - Banner -->
<div class="campaign-detail-banner campaign-detail-banner-style">
    <!-- Campaign Detail - Banner (Main : Carousel Slide) -->
    <div id="carouselExampleCaptions" class="carousel slide carousel-style" data-ride="carousel" data-interval="false">
        <!-- <ol class="carousel-indicators" style="display: none">
            <li
                data-target="#carouselExampleCaptions"
                *ngFor="let item of bannerMainList; let index = index"
                [attr.data-slide-to]="index"
            ></li>
        </ol> -->

        <div class="carousel-inner carousel-inner-style">
            <div
                class="carousel-item carousel-item-style"
                *ngFor="let item of bannerMainList; let index = index; let isFirst = first"
                [class.active]="bannerMainIndex === index"
                [ngClass]="{
                    'slide-right': bannerMainIndex > previousIndex && bannerMainIndex === index,
                    'slide-left': bannerMainIndex < previousIndex && bannerMainIndex === index
                }"
            >
                <img [src]="item.downloadUrl" class="d-block w-100 h-100 carousel-item-img-style" />
            </div>
        </div>
    </div>

    <!-- Campaign Detail - Banner (Black Screen Opacity) -->
    <div class="black-screen-opacity"></div>

    <!-- Campaign Detail - Banner (Black Screen BG) -->
    <div class="black-screen-bg"></div>

    <!-- Campaign Detail - Banner (Slide : Left) -->
    <div
        id="carouselBannerMainLeft"
        class="carousel slide slide-left-layout"
        data-ride="carousel"
        data-interval="false"
    >
        <div class="carousel-inner carousel-inner-style">
            <div
                class="carousel-item carousel-item-style"
                *ngFor="let item of bannerMainList; let index = index; let isFirst = first"
                [class.active]="bannerMainLeftIndex === index"
                [ngClass]="{
                    'slide-right': bannerMainIndex > previousIndex && bannerMainLeftIndex === index,
                    'slide-left': bannerMainIndex < previousIndex && bannerMainLeftIndex === index
                }"
            >
                <img [src]="item.downloadUrl" class="d-block w-100 h-100 img-banner-left-style" />
            </div>
        </div>
    </div>

    <!-- Campaign Detail - Banner (Slide : Right) -->
    <div
        id="carouselBannerMainRight"
        class="carousel slide slide-right-layout"
        data-ride="carousel"
        data-interval="false"
    >
        <div class="carousel-inner carousel-inner-style">
            <div
                class="carousel-item carousel-item-style"
                *ngFor="let item of bannerMainList; let index = index; let isFirst = first"
                [class.active]="bannerMainRightIndex === index"
                [ngClass]="{
                    'slide-right': bannerMainIndex > previousIndex && bannerMainRightIndex === index,
                    'slide-left': bannerMainIndex < previousIndex && bannerMainRightIndex === index
                }"
            >
                <img [src]="item.downloadUrl" class="d-block w-100 h-100 img-banner-right-style" />
            </div>
        </div>
    </div>
</div>

<!-- Campaign Detail - Banner (Thumbnail) -->
<div class="campaign-detail-bannerthumbnail row row-thumbnail-style">
    <div class="col-thumbnail-style" *ngFor="let itemBannerMain of bannerMainList; let i = index">
        <div
            class="campaign-detail-thumbnail col-thumbnail-inner-style"
            [class.active]="bannerMainIndex === i"
            (click)="onSelectThumbnailBannerMain(i)"
        >
            <img class="img-banner-thumbnail-style" src="{{ itemBannerMain.url }}" draggable="false" />
        </div>
    </div>
</div>
