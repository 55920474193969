import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { ICampaignOrderCreationData } from '../models/product/campaign/campaign-order-creation-data.model';
import { CampaignListingQuery } from '../models/request/queries/campaign-listing-query';
import { BaseHttpService } from './base/base-http.service';

@Injectable({
    providedIn: 'root',
})
export class CampaignService extends BaseHttpService {
    getCampaignListPaginated(subDomain: string, query: CampaignListingQuery): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.CAMPAIGN.PAGINATED_LISTING.replace('{subdomain}', subDomain) +
            '?' +
            query.getQueryString();

        return this.get(url);
    }

    getCampaignDetail(subDomain: string, campaignId: number): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.CAMPAIGN.DETAIL.replace('{subdomain}', subDomain).replace('{campaign_id}', campaignId.toString());
        return this.get(url);
    }

    getOrderDetail(subDomain: string, orderKey: string): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.CAMPAIGN.GET_ORDER_DETAIL.replace('{subdomain}', subDomain).replace('{key}', orderKey);
        return this.get(url);
    }

    createCampaignOrder(subDomain: string, campaignId: number, data: ICampaignOrderCreationData): Observable<any> {
        const url =
            environment.apiMallUrl +
            ENDPOINTS.CAMPAIGN.CREATE_ORDER.replace('{subdomain}', subDomain).replace(
                '{campaign_id}',
                campaignId.toString()
            );
        return this.post(url, data);
    }

    getChaophrayaCruiseUpdate(subDomain: string): Observable<any> {
        const url =
            environment.apiMallUrl + ENDPOINTS.CAMPAIGN.GET_CHAOPHRAYA_CRUISE_UPDATE.replace('{subdomain}', subDomain);
        return this.get(url);
    }
}
