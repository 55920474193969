export class ProductSearchResult {
    public productPoolId: number = null;
    public name: string = null;
    public bannerUrl: string = null;
    public bannerThumbnailUrl: string = null;
    public bannerPreviewUrl: string = null;
    public destinations: IDestination[] = [];
    public durationDay: number = null;
    public durationNight: number = null;
    public hilightDescription: string = null;
    public travelPeriodStartAtFirst: string = null;
    public travelPeriodStartAtLast: string = null;
    public price: number = null;
    public comparePrice: number = null;
    public categoryTransport: ICategoryTransport = null;
    public goTransport: IGoTransport = null;
    public periodsCount: number = 0;

    public productId: number = null;
    public ownerChannel: string = null;
    public categorySubProductId: number = null;

    public deserialize(data: any): ProductSearchResult {
        this.productPoolId = data.product_pool_id || null;
        this.name = data.name || null;
        this.bannerPreviewUrl = data.banner_thumbnail_url || null;
        this.bannerThumbnailUrl = data.banner_thumbnail_url || null;
        this.bannerUrl = data.banner_url || null;
        this.destinations = (data.destinations as any[]).map((destination) => {
            return {
                nameTh: destination.name_th,
                nameEn: destination.name_en,
            };
        });
        this.durationDay = data.duration_day || null;
        this.durationNight = data.duration_night || null;
        this.hilightDescription = data.hilight_description || null;
        this.travelPeriodStartAtFirst = data.travel_period_start_at_first || null;
        this.travelPeriodStartAtLast = data.travel_period_start_at_last || null;
        this.price = data.price || null;
        this.comparePrice = data.compare_price || null;

        this.categoryTransport =
            data.category_transport === null
                ? null
                : {
                      id: data.category_transport.id || null,
                      slug: data.category_transport.slug || null,
                      name: data.category_transport.name || null,
                  };

        this.goTransport =
            data.go_transport === null
                ? null
                : {
                      id: data.go_transport.id || null,
                      code: data.go_transport.code || null,
                      nameEn: data.go_transport.name_en || null,
                      imagePath: data.go_transport.airline_thumbnail_url || null,
                  };

        this.periodsCount = data.periods_count || 0;

        this.productId = data.id || null;
        this.categorySubProductId = data.category_sub_products_id || null;
        this.ownerChannel = data.owner_channel || null;

        return this;
    }

    public getDestinationString(): string {
        return this.destinations.map((destination) => destination.nameTh).join(' ');
    }
}

export interface IDestination {
    nameTh: string;
    nameEn: string;
}

export interface ICategoryTransport {
    id: number;
    slug: string;
    name: string;
}

export interface IGoTransport {
    id: number;
    code: string;
    nameEn: string;
    imagePath: string;
}
