import { ICountry, ICountryContinents } from './../../models/country.model';
import { createAction, props } from '@ngrx/store';
import { ICountryRecommend } from 'src/app/models/country-recommend.model';

export const loadActiveCountries = createAction(
    '[Country] Load active countries config.',
    props<{ subDomain: string }>()
);
export const loadActiveCountriesSuccess = createAction(
    '[Country] Load active countries success.',
    props<{ data: ICountryContinents }>()
);
export const loadActiveCountriesFailure = createAction(
    '[Country] Load active countries failure.',
    props<{ error: any }>()
);

export const loadAllCountry = createAction('[Country] Load All Country.');
export const loadAllCountrySuccess = createAction(
    '[Country] Load All Country Success.',
    props<{ countries: ICountry[] }>()
);
export const loadAllCountryFailure = createAction('[Country] Load All Country Failure.', props<{ error: any }>());

export const loadCountryRecommend = createAction('[Country] Load Country Recommend.', props<{ subDomain: string }>());
export const loadCountryRecommendSuccess = createAction(
    '[Country] Load Country Recommend Success.',
    props<{ countriesRecommend: ICountryRecommend[] }>()
);
export const loadCountryRecommendFailure = createAction(
    '[Country] Load Country Recommend Failure.',
    props<{ error: any }>()
);
