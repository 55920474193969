import { RoomType } from '../../room-type.model';
import { Customer, Payment } from '../campaign/order-detail.model';

export class OrderProgramDetail {
    public id: number;
    public orderCode: string;
    public name: string;
    public durationDay: number;
    public durationNight: number;
    public periodStart: Date;
    public periodEnd: Date;
    public productTicketQuantity: number;
    public totalPrice: number = 0;

    public customer: Customer;
    public payment: Payment;
    public tickets: {
        roomType: RoomType;
        quantity: number;
        pricePerRoom: number;
        totalPrice: number;
    }[];

    public deserialize(data: any): this {
        this.id = data.id;
        this.orderCode = data.order_code;
        this.name = data.product.name;
        this.durationDay = +data.product.duration_day;
        this.durationNight = +data.product.duration_night;
        this.periodStart = new Date(data.product.period.start_at);
        this.periodEnd = new Date(data.product.period.end_at);
        this.productTicketQuantity = +data.product_ticket_quantity;

        this.customer = new Customer().deserialize(data.customer);
        this.payment = new Payment().deserialize(data.payment);

        this.tickets = data.product_tickets.map((ticket) => {
            this.totalPrice = this.totalPrice + ticket.total_price;
            return {
                roomType: new RoomType().deserialize(ticket.room_type),
                quantity: +ticket.quantity,
                pricePerRoom: +ticket.price_per_room,
                totalPrice: +ticket.total_price,
            };
        });

        return this;
    }
}
