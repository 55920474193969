import { Action, createReducer, on, State } from '@ngrx/store';
import * as LeadActions from './lead.actions';
import { IResultForCreateLead } from 'src/app/services/lead.service';

export interface LeadState {
    leadCreationResult: IResultForCreateLead;
}

export const initialState: LeadState = {
    leadCreationResult: null,
};

export const leadReducer = createReducer(
    initialState,

    on(LeadActions.leadCreation, (state) => {
        return {
            ...state,
            leadCreationResult: null,
        };
    }),
    on(LeadActions.leadCreationSuccess, (state, action) => {
        return {
            ...state,
            leadCreationResult: action.result,
        };
    }),
    on(LeadActions.leadCreationFailure, (state, action) => {
        return {
            ...state,
            leadCreationResult: action.result,
        };
    }),
    on(LeadActions.resetLeadCreationResult, (state) => {
        return {
            ...state,
            leadCreationResult: null,
        };
    })
);

export function reducer(state: LeadState | undefined, action: Action) {
    return leadReducer(state, action);
}
