import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './portfolio.reducer';
import { PortfolioEffects } from './portfolio.effects';

export const FEATURE_NAME = 'portfolio';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(FEATURE_NAME, reducer),
        EffectsModule.forFeature([PortfolioEffects]),
    ],
    providers: [PortfolioEffects],
})
export class PortfolioStoreModule {}
