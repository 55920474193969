import { ProductSearchResult } from './product-search-result.model';
import { IPeriod } from './period.model';

export interface IProductOwner {
    id: number;
    nameEN: string;
    nameTH: string;
}

export interface IProductDescription {
    id: number;
    description: string;
    ordinal: number;
    type: string;
    imageUrl?: string;
    imageContentUrl?: string;
    imageThumbnailUrl?: string;
    imageLocationName?: string;
}

export interface IProduct {
    id: number;
    productResult: ProductSearchResult;
    tourcode: string;
    name: string;
    durationDay: number;
    durationNight: number;
    productOwner: IProductOwner;
    descriptions: IProductDescription[];
    periods: IPeriod[];
    tourCondition: string;
    hasPdfDetailFile: boolean;
}

export interface IHighlightedShowcase {
    categorySubProductsId: string;
    productsId: number;
    ownerChannel: 'ws' | 'ag';
    ownerId: number;
    productName: string;
    isFromAgcyProduct: boolean;
    showcaseBannerUrl: string;
}

export interface IPdfDetailFile {
    url: string;
}
