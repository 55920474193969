<header class="header" [ngClass]="{'openNav': openNav}">
    <span class="header-wrap"></span>
    <div class="container">
        <button href="#" class="hamburger open-panel nav-toggle" (click)="openNavMenu()"><span class="textreplacement">menu</span></button>
        <h1 class="logo">
            <a href="index.html" title="Gift a wow">
                <img src="/assets/images/gift-a-wow-theme/logo-gift-a-wow.svg" alt="Lofo Gift a wow" />
                <span class="textreplacement">Gift a wow</span>
            </a>
        </h1>
        <nav role="navigation" class="menu">
            <span class="menu-title">Gift a wow</span>
            <div class="menu-container">
                <ul class="menu-dropdown">
                    <li><a href="#" title="หน้าแรก">หน้าแรก</a></li>
                    <li class="menu-hasdropdown" [ngClass]="{'active': subNavMenuFestival}" (click)="openNavSubMenu('festival')">
                        <a href="#">เทศกาล <i class="icon icon--arr-line-right"></i></a>
                        <div class="sub-menu-dropdown">
                            <span>เทศกาล</span>
                            <ul>
                                <li><a href="" title="วันคริสต์มาส">วันคริสต์มาส</a></li>
                                <li><a href="" title="วันตรุษจีน">วันตรุษจีน</a></li>
                                <li><a href="" title="วันไหว้ครู">วันไหว้ครู</a></li>
                                <li><a href="" title="วันฮาโลวีน">วันฮาโลวีน</a></li>
                                <li><a href="" title="วันปีใหม่">วันปีใหม่</a></li>
                                <li><a href="" title="วันวาเลนไทน์">วันวาเลนไทน์</a></li>
                                <li><a href="" title="วันไหว้พระจันทร์">วันไหว้พระจันทร์</a></li>
                                <li><a href="" title="วันพ่อ">วันพ่อ</a></li>
                                <li><a href="" title="วันเด็ก">วันเด็ก</a></li>
                                <li><a href="" title="วันสงกรานต์">วันสงกรานต์</a></li>
                                <li><a href="" title="วันแม่">วันแม่</a></li>
                                <li><a href="" title="วันไหว้พระจันทร์">วันไหว้พระจันทร์</a></li>
                                <li><a href="" title="วันไหว้พระจันทร์">วันไหว้พระจันทร์</a></li>
                            </ul>
                            <div class="header-banner">
                                <img src="/assets/images/gift-a-wow-theme/banner-head/1.png" alt="">
                            </div>
                        </div>
                    </li>
                    <li class="menu-hasdropdown" [ngClass]="{'active': subNavMenuEvent}" (click)="openNavSubMenu('event')">
                        <a href="#">วันพิเศษ <i class="icon icon--arr-line-right"></i></a>
                        <div class="sub-menu-dropdown">
                            <span>วันพิเศษ</span>
                            <ul>
                                <li><a href="" title="วันเกิด">วันเกิด</a></li>
                                <li><a href="" title="วันครบรอบแต่งงาน">วันครบรอบแต่งงาน</a></li>
                                <li><a href="" title="วันรับปริญญา">วันรับปริญญา</a></li>
                                <li><a href="" title="งานแต่ง">งานแต่ง</a></li>
                                <li><a href="" title="เข้ารับตำแหน่งใหม่">เข้ารับตำแหน่งใหม่</a></li>
                                <li><a href="" title="ขึ้นบ้านใหม่">ขึ้นบ้านใหม่</a></li>
                                <li><a href="" title="วันเกษียณ">วันเกษียณ</a></li>
                                <li><a href="" title="วันเยี่ยมคลอด">วันเยี่ยมคลอด</a></li>
                                <li><a href="" title="เปิดกิจการใหม่">เปิดกิจการใหม่</a></li>
                                <li><a href="" title="เยี่ยมผู้ป่วย">เยี่ยมผู้ป่วย</a></li>
                                <li><a href="" title="งานมงคล">งานมงคล</a></li>
                            </ul>
                            <div class="header-banner">
                                <img src="/assets/images/gift-a-wow-theme/banner-head/2.png" alt="">
                            </div>
                        </div>
                    </li>
                    <li class="menu-hasdropdown" [ngClass]="{'active': subNavMenuGift}" (click)="openNavSubMenu('gift')">
                        <a href="#">หมวดของขวัญ <i class="icon icon--arr-line-right"></i></a>
                        <div class="sub-menu-dropdown">
                            <div class="is-desktop cate-gift-head">



                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/1.png" title="กระเช้า" />
                                        </figure>
                                        <strong>กระเช้า</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/2.png" title="เค้ก" />
                                        </figure>
                                        <strong>เค้ก</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/3.png" title="ขนม" />
                                        </figure>
                                        <strong>ขนม</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/4.png" title="ตุ๊กตา" />
                                        </figure>
                                        <strong>ตุ๊กตา</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/5.png" title="ลูกโป่ง" />
                                        </figure>
                                        <strong>ลูกโป่ง</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/6.png" title="ดอกไม้" />
                                        </figure>
                                        <strong>ดอกไม้</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/7.png" title="ต้นไม้" />
                                        </figure>
                                        <strong>ต้นไม้</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/8.png" title="แกดเจ็ต" />
                                        </figure>
                                        <strong>แกดเจ็ต</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/9.png" title="เครื่องประดับ" />
                                        </figure>
                                        <strong>เครื่องประดับ</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/10.png" title="เสื้อผ้า" />
                                        </figure>
                                        <strong>เสื้อผ้า</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/11.png" title="การ์ด" />
                                        </figure>
                                        <strong>การ์ด</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/12.png" title="เครื่องดื่ม" />
                                        </figure>
                                        <strong>เครื่องดื่ม</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/13.png" title="DIY" />
                                        </figure>
                                        <strong>DIY</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/14.png" title="เครื่องใช้ไฟฟ้า" />
                                        </figure>
                                        <strong>เครื่องใช้ไฟฟ้า</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/15.png" title="เครื่องดนตรี" />
                                        </figure>
                                        <strong>เครื่องดนตรี</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/16.png" title="Beauty" />
                                        </figure>
                                        <strong>Beauty</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/17.png" title="เครื่องเขียน" />
                                        </figure>
                                        <strong>เครื่องเขียน</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/18.png" title="สัตว์เลี้ยง" />
                                        </figure>
                                        <strong>สัตว์เลี้ยง</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/19.png" title="ของใช้ในบ้าน" />
                                        </figure>
                                        <strong>ของใช้ในบ้าน</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/20.png" title="ของใช้" />
                                        </figure>
                                        <strong>ของใช้</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/21.png" title="อุปกรณ์ตกแต่งบ้าน" />
                                        </figure>
                                        <strong>อุปกรณ์ตกแต่งบ้าน</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/22.png" title="ของมงคล" />
                                        </figure>
                                        <strong>ของมงคล</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/23.png" title="เครื่องนอน" />
                                        </figure>
                                        <strong>เครื่องนอน</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/24.png" title="ของใช้แม่และเด็ก" />
                                        </figure>
                                        <strong>ของใช้แม่และเด็ก</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/25.png" title="ของ Premium" />
                                        </figure>
                                        <strong>ของ Premium</strong>
                                    </a>
                                </div>
                                <div class="cate-gift">
                                    <a href="#">
                                        <figure>
                                            <img src="/assets/images/gift-a-wow-theme/cate/26.png" title="ของบริจาค/ของทำบุญ" />
                                        </figure>
                                        <strong>ของบริจาค/ของทำบุญ</strong>
                                    </a>
                                </div>
                            </div>
                            <div class="is-mobile">
                                <span>หมวดของขวัญ</span>
                                <ul>
                                    <li><a href="" title="กระเช้า">กระเช้า</a></li>
                                    <li><a href="" title="เค้ก">เค้ก</a></li>
                                    <li><a href="" title="ขนม">ขนม</a></li>
                                    <li><a href="" title="ตุ๊กตา">ตุ๊กตา</a></li>
                                    <li><a href="" title="ลูกโป่ง">ลูกโป่ง</a></li>
                                    <li><a href="" title="ดอกไม้">ดอกไม้</a></li>
                                    <li><a href="" title="ต้นไม้">ต้นไม้</a></li>
                                    <li><a href="" title="แกดเจ๊ต">แกดเจ๊ต</a></li>
                                    <li><a href="" title="เครื่องประดับ">เครื่องประดับ</a></li>
                                    <li><a href="" title="เครื่องแต่งกาย">เครื่องแต่งกาย</a></li>
                                    <li><a href="" title="การ์ด">การ์ด</a></li>
                                    <li><a href="" title="เครื่องดื่ม">เครื่องดื่ม</a></li>
                                    <li><a href="" title="DIY">DIY</a></li>
                                    <li><a href="" title=""></a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li><a href="#" title="บทความ">บทความ</a></li>
                    <li><a href="#" title="เกี่ยวกับเรา">เกี่ยวกับเรา</a></li>
                </ul>
                <div class="header-bottom">
                    <a href="#" title="ข้อตกลงการใข้งาน">ข้อตกลงการใข้งาน</a>
                    <a href="#" title="นโยบายคุ้มครองข้อมูลส่วนบุคคล">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>
                    <div class="social">
                        <a href="#" title="facebook"><i class="icon icon--facebook"></i></a>
                        <a href="#" title="line"><i class="icon icon--line"></i></a>
                        <a href="#" title="e-mail"><i class="icon icon--mail"></i></a>
                        <a href="#" title="telephone"><i class="icon icon--phone"></i></a>
                    </div>
                </div>
            </div>
        </nav>
        <a href="#" title="" class="love"><i class="icon icon--heart-line"></i><span>10</span></a>
    </div>
</header>