import { BlogService } from './../../services/blog.service';
import { IAboutUsResponse } from './../../models/agency.model';
import { ICustomContentDetail } from 'src/app/models/custom-content.model';
import { AgencyService } from '../../services/agency.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap } from 'rxjs/operators';

import * as AgencyAction from './agency.actions';
import { of } from 'rxjs';

@Injectable()
export class AgencyEffects {
    constructor(
        private actions$: Actions,
        private agencyService: AgencyService,
        private router: Router,
        private blogService: BlogService
    ) {}

    loadBanner = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(AgencyAction.loadAboutUsInfo),
                concatMap((action) => {
                    return this.agencyService.getAboutUsDetails(action.subDomain).pipe(
                        map((response) => {
                            if (response.status !== 'success') {
                                return AgencyAction.loadAboutUsInfoFailure(null);
                            }
                            const data = response.data;
                            const aboutUsResponse: IAboutUsResponse = {
                                agencyId: data.id,
                                galleriesId: data.galleries_id,
                                aboutUs: data.about_us,
                            };

                            return AgencyAction.loadAboutUsInfoSuccess({ data: aboutUsResponse });
                        }),
                        catchError((error) => {
                            return of(AgencyAction.loadAboutUsInfoFailure(error));
                        })
                    );
                })
            )
    );

    loadCustomContent = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(AgencyAction.loadCustomContentDetail),
                switchMap((action) => {
                    return this.agencyService.getCustomContentDetail(action.subDomain, action.customContentId).pipe(
                        map((response) => {
                            if (response.status !== 'success') {
                                return AgencyAction.loadCustomContentDetailFailure({ error: response.error });
                            }
                            const data = response.data;
                            const customContentDetail: ICustomContentDetail = {
                                id: data.id,
                                menuNameTh: data.menu_name_th,
                                menuNameEn: data.menu_name_en,
                                menuOrdinal: data.menu_ordinal,
                                isActive: data.is_active,
                                content: data.content,
                            };

                            return AgencyAction.loadCustomContentDetailSuccess({ data: customContentDetail });
                        }),
                        catchError((error) => {
                            return of(AgencyAction.loadCustomContentDetailFailure(error));
                        })
                    );
                })
            )
    );

    loadBlogListing = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(AgencyAction.loadBlogListing),
                switchMap((action) => {
                    return this.blogService.getPaginatedListing(action.subDomain, action.criteria).pipe(
                        map((response: any) => {
                            if (response.status !== 'success') {
                                return AgencyAction.loadBlogListingFailure({ error: response.error });
                            }
                            const data = response.data;

                            return AgencyAction.loadBlogListingSuccess({ blogListing: data });
                        }),
                        catchError((error) => {
                            return of(AgencyAction.loadBlogListingFailure(error));
                        })
                    );
                })
            )
    );

    loadBlogDetail = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(AgencyAction.loadBlogDetail),
                switchMap((action) => {
                    return this.blogService.getDetail(action.subDomain, action.id).pipe(
                        map((response: any) => {
                            if (response.status !== 'success') {
                                return AgencyAction.loadBlogDetailFailure({ error: response.error });
                            }
                            const data = response.data;

                            return AgencyAction.loadBlogDetailSuccess({ blogDetail: data });
                        }),
                        catchError((error) => {
                            return of(AgencyAction.loadBlogDetailFailure(error));
                        })
                    );
                })
            )
    );
}
