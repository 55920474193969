import { IProvince, IProvinceRegions } from './../../models/province.model';
import { Action, createReducer, on, State } from '@ngrx/store';
import * as ProvinceActions from './province.actions';
import { IProvinceRecommend } from 'src/app/models/province-recommend.model';

export interface ProvinceState {
    provinces: IProvinceRegions;
    provincesRecommend: IProvinceRecommend[];
    allProvince: IProvince[];
    errorMessage: string | null;
}

export const initialState: ProvinceState = {
    provinces: null,
    provincesRecommend: [],
    allProvince: [],
    errorMessage: null,
};

export const provinceReducer = createReducer(
    initialState,

    on(ProvinceActions.loadActiveProvinces, (state, action) => state),
    on(ProvinceActions.loadActiveProvincesSuccess, (state, action) => ({ ...state, provinces: action.data })),
    on(ProvinceActions.loadActiveProvincesFailure, (state, action) => ({ ...state, error: action })),

    /** provice recommend reducers */
    on(ProvinceActions.loadProvincesRecommend, (state) => state),
    on(ProvinceActions.loadProvincesRecommendSuccess, (state, action) => ({
        ...state,
        provincesRecommend: action.provincesRecommend,
    })),
    on(ProvinceActions.loadProvincesRecommendFailure, (state, action) => ({ ...state, error: action.error })),

    on(ProvinceActions.loadAllProvince, (state) => state),
    on(ProvinceActions.loadAllProvinceSuccess, (state, action) => ({ ...state, allProvince: action.provinces })),
    on(ProvinceActions.loadAllProvinceFailure, (state, action) => ({ ...state, error: action.error }))
);

export function reducer(state: ProvinceState | undefined, action: Action) {
    return provinceReducer(state, action);
}
