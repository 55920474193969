export class Menu {
    public slug: string;
    public isShown: boolean;

    public deserialize(data: any): this {
        this.slug = data.slug;
        this.isShown = data.is_shown;

        return this;
    }
}
